import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { TAuthTabs } from '.';
import { IMaskPhoneInput } from './CheckInSystem';
import { IAppState } from '../../../store/rootDuck';
import { useFormikEnterConfirmCodeEmail } from '../hooks/useFormikEnterConfirmCodeEmail';
import { useConfirmCodeEmail } from '../hooks/useConfirmCodeEmail';
import { ButtonTheme } from '../../../components/ui/Buttons/ButtonWithLoader';

interface IProps {
  changeAuthTab: (newTab: TAuthTabs, props?: any) => void;
  email: string;
}

export const EnterConfirmCodeEmail: React.FC<IProps> = ({ changeAuthTab, email }) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const loginSuccess = useSelector(({ auth }: IAppState) => auth.loginSuccess, shallowEqual);

  const { loadingEmailCode, makeLoginEmailCode } = useConfirmCodeEmail();

  const {
    handleBlur,
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
  } = useFormikEnterConfirmCodeEmail(email, makeLoginEmailCode);

  useEffect(() => {
    loginSuccess && navigate('projects/list');
  }, [loginSuccess]);

  return (
    <div className='kt-login__body'>
      <div className='kt-login__form'>
        <div className='kt-login__title'>
          <h3>{intl.formatMessage({ id: 'ENTER_CONFIRM_CODE' })}</h3>
        </div>

        <form noValidate autoComplete='off' className='kt-form' onSubmit={handleSubmit}>
          <div className='form-group'>
            <div className='form-group'>
              <IMaskPhoneInput
                mask='00 00 00'
                onBlur={handleBlur}
                value={values.code}
                onChange={(e: any) => {
                  setFieldValue('code', e.target.value || '');
                }}
                type='tel'
                label={intl.formatMessage({
                  id: 'AUTH.INPUT.CODE',
                })}
                margin='normal'
                className='kt-width-full'
                name='code'
                helperText={touched.code && errors.code}
                error={Boolean(touched.code && errors.code)}
                InputLabelProps={{
                  shrink: !!values.code,
                }}
                InputProps={{
                  inputMode: 'numeric',
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <div className='kt-login__actions' style={{ marginRight: 30 }}>
              <ButtonWithLoader
                onPress={() => changeAuthTab('CheckInSystem')}
                variant='outlined'
                theme={ButtonTheme.OUTLINED}
                type='button'
              >
                <FormattedMessage id='AUTH.GENERAL.BACK_BUTTON' />
              </ButtonWithLoader>
            </div>

            <div className='kt-login__actions'>
              <ButtonWithLoader
                onPress={handleSubmit}
                disabled={loadingEmailCode}
                loading={loadingEmailCode}
              >
                <FormattedMessage id='AUTH.LOGIN.BUTTON' />
              </ButtonWithLoader>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
