import React from 'react';
import { makeStyles, Radio, TableCell, TableRow } from '@material-ui/core';

import { ITariff } from '../../../../../../interfaces/tariff';

import { TariffEditFields } from '../../../../../../store/ducks/tariff.duck';
import { useFormatMessage } from '../../../../../../hooks';
import EditableRow from './EditableRow';

const useStyles = makeStyles(theme => ({
  titleCell: {
    color: '#1e1e2d',
    width: '300px',
  },
  providerProCell: {
    width: '200px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
}));

const TableRows: React.FC<{
  tariffs: ITariff[];
  editable: boolean;
  loading: boolean;
  success: boolean;
  selectTariff: {
    tariff: ITariff | null;
    days: number;
  };
  setSelectTariff: (value: { tariff: ITariff | null; days: number }) => void;
  editTariff: (
    id: number,
    field: TariffEditFields,
    value: string | number | null,
    name?: string
  ) => void;
}> = ({ loading, success, editTariff, editable, tariffs, selectTariff, setSelectTariff }) => {
  const fm = useFormatMessage();
  const classes = useStyles();

  return (
    <>
      <TableRow>
        <TableCell className={classes.titleCell} align='center'>
          <b>{fm('USERS.NUM')}</b>
        </TableCell>
        {tariffs.map((i, index) => (
          <TableCell
            key={i.id}
            align='center'
            className={index !== 0 ? classes.providerProCell : ''}
          >
            <EditableRow
              row={`${i.users_num}`}
              loading={loading}
              success={success}
              editable={editable}
              color={index !== 0 ? '#fff' : '#000'}
              currency=''
              submit={newValue => {
                editTariff(i.id, 'usersNum', newValue || 0);
              }}
            />
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell className={classes.titleCell} align='center'>
          <b>{fm('TARIFF.COST')}</b>
        </TableCell>
        {tariffs.map((i, index) => (
          <TableCell
            key={i.id}
            align='center'
            className={index !== 0 ? classes.providerProCell : ''}
          />
        ))}
      </TableRow>
      <TableRow>
        <TableCell className={classes.titleCell} align='center'>
          {fm('TARIFF.COST.THIRTY.DAYS')}
        </TableCell>
        {tariffs.map((i, index) => (
          <TableCell
            key={i.id}
            align='center'
            className={index !== 0 ? classes.providerProCell : ''}
          >
            <EditableRow
              row={`${i.price30days}`}
              loading={loading}
              success={success}
              editable={editable}
              color={index !== 0 ? '#fff' : '#000'}
              setCheck={
                index !== 0 ? () => setSelectTariff({ tariff: i, days: 30 }) : undefined
              }
              isCheck={
                index !== 0
                  ? selectTariff.tariff?.id === i.id && selectTariff.days === 30
                  : false
              }
              currency='czk'
              submit={newValue => {
                editTariff(i.id, 'price30days', newValue || 0);
              }}
            />
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell className={classes.titleCell} align='center'>
          {fm('TARIFF.COST.HUNDREDTWENTY.DAYS')}
        </TableCell>
        {tariffs.map((i, index) => (
          <TableCell
            key={i.id}
            align='center'
            className={index !== 0 ? classes.providerProCell : ''}
          >
            <EditableRow
              row={`${i.price120days}`}
              loading={loading}
              success={success}
              editable={editable}
              color={index !== 0 ? '#fff' : '#000'}
              setCheck={
                index !== 0 ? () => setSelectTariff({ tariff: i, days: 120 }) : undefined
              }
              isCheck={
                index !== 0
                  ? selectTariff.tariff?.id === i.id && selectTariff.days === 120
                  : false
              }
              currency='czk'
              submit={newValue => {
                editTariff(i.id, 'price120days', newValue || 0);
              }}
            />
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell className={classes.titleCell} align='center'>
          {fm('TARIFF.COST.YEAR')}
        </TableCell>
        {tariffs.map((i, index) => (
          <TableCell
            key={i.id}
            align='center'
            className={index !== 0 ? classes.providerProCell : ''}
          >
            <EditableRow
              row={`${i.price365days}`}
              loading={loading}
              success={success}
              editable={editable}
              color={index !== 0 ? '#fff' : '#000'}
              setCheck={
                index !== 0 ? () => setSelectTariff({ tariff: i, days: 365 }) : undefined
              }
              isCheck={
                index !== 0
                  ? selectTariff.tariff?.id === i.id && selectTariff.days === 365
                  : false
              }
              currency='czk'
              submit={newValue => {
                editTariff(i.id, 'price365days', newValue || 0);
              }}
            />
          </TableCell>
        ))}
      </TableRow>
    </>
  );
};

export default TableRows;
