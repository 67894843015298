import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Card, CardContent, IconButton, Tooltip, Box } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import moment from 'moment';
import classNames from 'classnames';
import EditIcon from './EditIcon';
import homeStyles from '../../../../constants/homeStyles';
import { IProject } from '../../../../interfaces/projects';
import { useFormatMessage } from '../../../../hooks';
import { useStylesProjectsPage } from '../hooks/useStyles';

interface IProps {
  item: IProject;
  isAdmin: boolean;
  getPro: () => void;
}
const ItemProject = ({ item, isAdmin, getPro }: IProps) => {
  const classes = useStylesProjectsPage();
  const homeClasses = homeStyles();
  const navigate = useNavigate();
  const fm = useFormatMessage();

  return (
    <div className={classes.itemContent}>
      <Card className={classes.card} elevation={3}>
        <CardContent style={{ paddingBottom: 10 }} className={homeClasses.tableContainer}>
          <div className={classes.title_container}>
            <div className={classes.wrapper}>
              <div className={classes.title_section}>
                <Typography className={classes.title}>
                  {item.name}
                </Typography>

                <Typography className={classes.subTitle}>
                  {item.domain || ''}

                  {(isAdmin || (item.active && item.domain)) && (
                    <Tooltip title={fm('PROJECTS.BTN')}>
                      <IconButton
                        color='primary'
                        aria-label='previous'
                        component='a'
                        href={item.domain ? `https://${item.domain}` : undefined}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <LinkIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Typography>

                <div className={classes.statusContent}>
                  <Box
                    className={classNames(
                      classes.statusCheck,
                      item.active ? classes.blue : classes.red
                    )}
                  />

                  <Typography className={classes.titleStatus}>
                    {fm('PROJECTS.ITEM.STATUS')} -{' '}
                    {item.active ? fm('PROJECTS.ITEM.ACTIVE') : fm('PROJECTS.ITEM.INACTIVE')}
                  </Typography>
                </div>
              </div>

              <div className={classes.tariffContent}>
                <div className={classes.tariffContainer}>
                  <Typography className={classes.title}>
                    {fm(
                      item.current_tariff?.tariff?.id === 7 ? 'TARIFF.BASE' : 'TARIFF.PRIME'
                    )}
                  </Typography>

                  <Typography className={classes.tariff}>
                    {fm('PROJECTS.ITEM.TARIFF')}
                  </Typography>
                </div>

                <Typography className={classes.subTitle}>
                    {item.current_tariff?.stop_datetime ? fm('PROJECTS.ITEM.DATE.TARIFF') : fm('PROJECTS.ITEM.STOPDATE')}{' '}
                    {item.current_tariff?.stop_datetime && moment(item.current_tariff?.stop_datetime).format('DD.MM.YYYY')}
                </Typography>

                {item.current_tariff?.tariff?.id === 7 ? (
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => getPro()}
                    className={classes.getBtn}
                  >
                    {fm('PROJECTS.ITEM.BTN')}
                  </Button>
                ) : (
                  <div style={{ height: 16 }} />
                )}
              </div>
            </div>

            <div className={classes.iconContent}>
              <Tooltip title={fm('edit')}>
                <IconButton
                  color='primary'
                  aria-label='previous'
                  onClick={() => navigate(`/projects/edit/${item.id}`)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>

              <Typography
                className={classes.editBtn}
              >
                {fm('edit')}
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ItemProject;
