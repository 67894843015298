import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import objectPath from 'object-path';

import HMenuItem from './HMenuItem';
import * as builder from '../../../ducks/builder';
import KTMenu from '../../../_assets/js/menu';
import KTOffcanvas from '../../../_assets/js/offcanvas';
import { actions as productCatalogActions } from '../../../../app/store/ducks/product-catalog.duck';
import { IAppState } from '../../../../app/store/rootDuck';
import { IProductFilter } from '../../../../app/interfaces/product';
import { toAbsoluteUrl } from '../../../utils/utils';

const offcanvasOptions = {
  overlay: true,
  baseClass: 'kt-header-menu-wrapper',
  closeBy: 'kt_header_menu_mobile_close_btn',
  toggleBy: {
    target: 'kt_header_mobile_toggler',
    state: 'kt-header-mobile__toolbar-toggler--active',
  },
};

const HMenu: React.FC = () => {
  const offCanvasCommonRef: any = useRef();
  const ktMenuCommonRef: any = useRef();

  const {
    menuConfig,
    ktMenuClasses,
    rootArrowEnabled,
    ulClasses,
    disabledAsideSelfDisplay,
  } = useSelector((store: IAppState) => ({
    menuConfig: store.builder.menuConfig,
    ktMenuClasses: builder.selectors.getClasses(store, {
      path: 'header_menu',
      toString: true,
    }),
    rootArrowEnabled: builder.selectors.getConfig(store, 'header.menu.self.root-arrow'),
    ulClasses: builder.selectors.getClasses(store, {
      path: 'header_menu_nav',
      toString: true,
    }),
    disabledAsideSelfDisplay:
      objectPath.get(store.builder.layoutConfig, 'aside.self.display') === false,
  }));

  const items = useMemo(() => menuConfig?.aside?.items, [menuConfig]);

  const location = useLocation();

  const currentUrl = useMemo(() => location.pathname.split(/[?#]/)[0], [location]);

  useEffect(() => {
    // Canvas
    initOffCanvas();
    // Menu
    initKTMenu();
  }, []);

  const initOffCanvas = useCallback(() => {
    KTOffcanvas(offCanvasCommonRef.current, offcanvasOptions);
  }, []);

  const initKTMenu = () => {
    let menuOptions: any = {
      submenu: {
        desktop: 'dropdown',
        tablet: 'accordion',
        mobile: 'accordion',
      },
      accordion: {
        slideSpeed: 200, // accordion toggle slide speed in milliseconds
        expandAll: false, // allow having multiple expanded accordions in the menu
      },
      dropdown: {
        timeout: 50,
      },
    };

    let menuDesktopMode = 'accordion';
    if (ktMenuCommonRef.current?.getAttribute('data-ktmenu-dropdown') === '1') {
      menuDesktopMode = 'dropdown';
    }

    if (typeof objectPath.get(menuOptions, 'submenu.desktop') === 'object') {
      menuOptions.submenu.desktop = {
        default: menuDesktopMode,
      };
    }

    new KTMenu(ktMenuCommonRef.current, menuOptions);
  };

  return (
    <>
      <button className='kt-header-menu-wrapper-close' id='kt_header_menu_mobile_close_btn'>
        <i className='la la-close' />
      </button>
      <div
        className='kt-header-menu-wrapper'
        id='kt_header_menu_wrapper'
        ref={offCanvasCommonRef}
      >
        <div className='kt-header-mobile__toolbar'>
          {/* <button
            ref={this.toggleButtonRef}
            className="kt-header-mobile__topbar-toggler"
            id="kt_header_mobile_topbar_toggler"
          >
            <i className="flaticon-more" />
          </button> */}
        </div>

        {disabledAsideSelfDisplay && <LogoComponent />}
        <div
          id='kt_header_menu'
          className={`kt-header-menu kt-header-menu-mobile ${ktMenuClasses}`}
          ref={ktMenuCommonRef}
        >
          <ul className={`kt-menu__nav ${ulClasses}`}>
            {items?.map((item: any, index: number) => {
              return (
                <React.Fragment key={`hmenuList${index}`}>
                  {item.title && (
                    <HMenuItem
                      item={item}
                      currentUrl={currentUrl}
                      rootArrowEnabled={rootArrowEnabled}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default HMenu;

const LogoComponent = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { page, per_page: perPage, filter, isSearching } = useSelector(
    ({ productsCatalog }: IAppState) => productsCatalog
  );
  const { userRole } = useSelector(({ auth }: IAppState) => ({
    userRole: auth.user?.roles?.[0],
  }));
  const isDashboard = userRole === 'ROLE_VENDOR';
  const { fetchRequest, setSearch } = productCatalogActions;

  const toInitial = useCallback(
    (page: number, perPage: number, filter: IProductFilter, search?: string) => {
      navigate('projects/list');
      if (isSearching) {
        dispatch(setSearch(!!search));
        dispatch(fetchRequest({ page, perPage, filter, search }));
      }
    },
    [isSearching]
  );

  return (
    <div className='kt-header-logo'>
      <Link
        to='/main/catalog'
        onClick={e => {
          e.preventDefault();
          toInitial(page, perPage, filter, undefined);
        }}
      >
        <img
          alt='logo'
          src={toAbsoluteUrl('/media/logos/logosAwes2Pro.png')}
          height={40}
        />
      </Link>
    </div>
  );
};
