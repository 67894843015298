import React, { useState, useLayoutEffect } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import { useFormatMessage } from '../../hooks';
import { IInvoice } from '../../interfaces/projects';

interface IProps {
  searchInvoices: IInvoice[];
  invoiceSelect?: IInvoice | null;
  inputClassName?: any;
  loadingSearch: boolean;
  handleBlur?: (e: any) => {} | void;
  fetchSearchInvoices: (id: number) => void;
  setInvoiceSelect: (value: IInvoice | null) => void;
}

const AutocompleteIdInvoice: React.FC<IProps> = ({
  searchInvoices,
  invoiceSelect,
  inputClassName,
  handleBlur,
  loadingSearch,
  fetchSearchInvoices,
  setInvoiceSelect,
}) => {
  const [value, setValue] = useState<string>('');
  const fm = useFormatMessage();

  useLayoutEffect(() => {
    if (value && value.length > 0) {
      fetchSearchInvoices(+value);
    }
  }, [value]);

  return (
    <Autocomplete
      noOptionsText={fm('COMMON.LIST.EMPTY')}
      options={searchInvoices}
      loading={loadingSearch}
      loadingText={fm('COMMON.LOADING')}
      style={{ margin: 16, width: 200 }}
      getOptionLabel={option => option.id.toString()}
      onChange={(e: any, val: any) => {
        if (val) {
          setInvoiceSelect(val);
        } else {
          setValue('');
          setInvoiceSelect(null);
        }
      }}
      filterOptions={o => o}
      value={invoiceSelect}
      onInputChange={(_e: any, _val: any, reason: any) => {
        if (reason === 'clear') setInvoiceSelect(null);
      }}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            type='number'
            margin='normal'
            variant='outlined'
            label={fm('SEARCH.BY.ID')}
            className={inputClassName}
            onBlur={handleBlur}
            onChange={e => {
              setValue(e.target.value);
            }}
            style={{ margin: 0 }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loadingSearch && <CircularProgress color='inherit' size={20} />}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default AutocompleteIdInvoice;
