import React, { useEffect, useState } from 'react';
import { Button, Card, CardActions, CardContent, Checkbox, Divider } from '@material-ui/core';
import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';
// import dayjs from 'dayjs';
import { connect, ConnectedProps } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { PlanI } from '../../interfaces';
import TariffSinglePayForm from '../TariffSinglePayForm';
import TariffSubscriptionPayForm from '../TariffSubscriptionPayForm';
import { actions as tariffActions } from '../../../../../store/ducks/tariff.duck';
import { IAppState } from '../../../../../store/rootDuck';
import useCrudSnackbar from '../../../../../hooks/useCrudSnackbar';
import Preloader from '../../../../../components/ui/Preloader/Preloader';
import { useStylesTariffChange } from '../../hooks/stylesTariffChange';

export enum PayStatus {
  ChooseTariff = 'ChooseTariff',
  Subscription = 'Subscription',
  SinglePay = 'SinglePay',
}

const TariffChange: React.FC<
  TPropsFromRedux & {
    plans: PlanI[];
    close: () => void;
    afterSuccess: () => void;
  }
> = ({
  me,
  plans,

  payInit,
  initLoading,
  initSuccess,
  initError,
  initClear,
  clientSecretKey,

  paySubscriptionClear,
  createSubscription,
  subscriptionLoading,
  subscriptionSuccess,
  subscriptionError,

  afterSuccess,
  close,
}) => {
  // const [startDate, setStartDate] = useState(dayjs(new Date()).format('YYYY-MM-DD'));
  const [payStatus, setPayStatus] = useState(PayStatus.ChooseTariff);
  const [payData, setPayData] = useState({ amount: 0, tariff_id: 0, days: 0 });
  const [recurringPayment, setRecurringPayment] = useState(true);

  const classes = useStylesTariffChange();
  const intl = useIntl();

  const chooseTariff = (data: { amount: number; tariff_id: number; days: number }) => {
    setPayData(data);
    if (recurringPayment) {
      setPayStatus(PayStatus.Subscription);
    } else {
      setPayStatus(PayStatus.SinglePay);
    }
  };

  const promise = loadStripe(
    'pk_test_51IQ5jWDrEZsQCPj5mMrzxmdoWDVUWnhtom3EMGQ11U93Uq3s4QUrJttyLz9xhebjtwuK3L1d9DUl6m3wUbS6gmnI00uGrZqCfC',
    {
      locale: 'en-AU',
    }
  );

  useEffect(() => {
    if (initError) {
      close();
    }
  }, [initError]);

  useCrudSnackbar({
    success: false,
    error: initError,
    successMessage: '',
    errorMessage: `Error: ${initError}`,
    clear: initClear,
  });

  useCrudSnackbar({
    success: subscriptionSuccess,
    error: subscriptionError,
    successMessage: 'Success',
    errorMessage: `Error: ${subscriptionError}`,
    clear: paySubscriptionClear,
    afterSuccess: () => {
      afterSuccess();
    },
  });

  if (subscriptionLoading)
    return (
      <div className={classes.container}>
        <Preloader />
      </div>
    );

  return (
    // @ts-ignore
    <Elements stripe={promise}>
      <div className={classes.container}>
        <Col>
          {payStatus === PayStatus.SinglePay && (
            <Row>
              <TariffSinglePayForm
                payInit={() =>
                  payInit({ tariff_id: payData.tariff_id, amount: payData.amount })
                }
                clientSecret={clientSecretKey}
                loading={initLoading}
                success={initSuccess}
                afterSuccess={afterSuccess}
              />
            </Row>
          )}

          {payStatus === PayStatus.Subscription && (
            <>
              <TariffSubscriptionPayForm
                name={me?.login}
                createSubscription={(payment_method_id: number) =>
                  createSubscription({
                    tariff_id: payData.tariff_id,
                    days: payData.days,
                    payment_method_id,
                  })
                }
              />
            </>
          )}

          {payStatus === PayStatus.ChooseTariff && (
            <>
              {/* {!recurringPayment && ( */}
              {/* <Row className={classes.startDateRow}> */}
              {/*   <TextField */}
              {/*     id="date" */}
              {/*     label="Start date" */}
              {/*     type="date" */}
              {/*     defaultValue={startDate} */}
              {/*     className={classes.textField} */}
              {/*     InputLabelProps={{ */}
              {/*       shrink: true, */}
              {/*     }} */}
              {/*     onChange={(e) => { */}
              {/*       if (e) { */}
              {/*         setStartDate(dayjs(e.target.value).format('YYYY-MM-DD')); */}
              {/*       } */}
              {/*     }} */}
              {/*   /> */}
              {/* </Row> */}
              {/* )} */}

              <Row className={classes.checkboxRow}>
                <Checkbox
                  color='primary'
                  checked={recurringPayment}
                  onChange={e => {
                    setRecurringPayment(e.target.checked);
                  }}
                  name='recurringPayment'
                  inputProps={{ 'aria-label': 'Recurring payment' }}
                />
                <label className='mb-0' htmlFor='recurringPayment'>
                  Recurring payment
                </label>
              </Row>

              <Row>
                <div className={classes.cardContain}>
                  {plans.map((plan, index) => (
                    <Card className={classes.card} key={index}>
                      <CardContent>
                        <div className={classes.title}>{plan.name}</div>
                        <div>{plan.days} Days</div>
                        <div className={classes.title} style={{ marginTop: 20 }}>
                          £{plan.price}
                        </div>
                      </CardContent>
                      <Divider />
                      <CardActions>
                        <div style={{ width: '100%' }}>
                          <Button
                            onClick={() => {
                              chooseTariff({
                                tariff_id: plan.tariff_id,
                                amount: plan.price,
                                days: plan.days,
                              });
                            }}
                            className={classes.button}
                            variant='contained'
                            color='primary'
                          >
                            {intl.formatMessage({ id: 'TARIFFS.CHOOSE' })}
                          </Button>
                        </div>
                      </CardActions>
                    </Card>
                  ))}
                </div>
              </Row>
            </>
          )}
        </Col>
      </div>
    </Elements>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    initLoading: state.tariff.payInitLoading,
    initSuccess: state.tariff.payInitSuccess,
    initError: state.tariff.payInitError,
    clientSecretKey: state.tariff.payClientSecret,

    subscriptionLoading: state.tariff.paySubscriptionLoading,
    subscriptionSuccess: state.tariff.paySubscriptionSuccess,
    subscriptionError: state.tariff.paySubscriptionError,
  }),
  {
    payInit: tariffActions.payInitRequest,
    createSubscription: tariffActions.paySubscriptionRequest,
    initClear: tariffActions.payInitClear,
    paySubscriptionClear: tariffActions.paySubscriptionClear,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(TariffChange);
