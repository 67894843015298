import { useMemo, useState } from 'react';
import { ICountry } from '../interfaces';

export const useHandleCountrySelect = (countries: any[] | null, initCountryId: number = 1) => {
  const [countryId, setCountryId] = useState(initCountryId);

  const countryData = useMemo(() => {
    if (!countries) return null;
    return countries.find(el => el.id === countryId);
  }, [countries, countryId]);

  return { countryId, setCountryId, countryData };
};

export const defaultCountry: ICountry = {
  code: '420',
  en_name: 'Czech Republic',
  flag: null,
  id: 76,
  mask: '(***)***-***',
  name: 'Чехия',
  ru_name: 'Чехия',
  sort: 0,
};
