import React from 'react';
import { makeStyles } from '@material-ui/styles';
import ButtonMaterial, { ButtonProps } from '@material-ui/core/Button';

interface IProps extends ButtonProps {
  onClick?: (event: any) => {} | void;
}

const useStyles = makeStyles({
  addBtn: {
    marginBottom: 10,
    '&:hover': {
      color: 'white',
    },
  },
});

const noop = () => {};

const Button: React.FC<IProps> = ({ children, onClick = noop, ...props }) => {
  const classes = useStyles();

  return (
    <ButtonMaterial
      variant="contained"
      color="primary"
      onClick={onClick}
      className={classes.addBtn}
      {...props}
    >
      {children}
    </ButtonMaterial>
  );
};

export default Button;
