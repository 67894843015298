import React, { useMemo } from 'react';
import { Card, CardContent, Grid, IconButton } from '@material-ui/core';
import {
  DeleteOutline,
  StarBorder,
  ViewCarousel,
  ViewCarouselOutlined,
} from '@material-ui/icons';

import { API_DOMAIN } from '../../../../constants';
import { useStyles } from '../hooks/useStyles';
import { IPhoto } from '../../../../interfaces/photo';
import { IUser } from '../../../../interfaces/user';

interface IProps {
  images: IPhoto[] | undefined;
  setMainImage?: (id: IPhoto['id']) => void;
  deleteImage?: (id: IPhoto['id']) => void;
  me?: IUser | undefined;
  setBannerImage?: (id: IPhoto['id']) => void;
}

export const ImagesGallery: React.FC<IProps> = ({
  me,
  setBannerImage,
  images,
  setMainImage,
  deleteImage,
}) => {
  const classes = useStyles();

  const imgsWithoutMain = useMemo(() => {
    if (!images) return;
    return images.filter(el => !el.main);
  }, [images]);

  if (!imgsWithoutMain) return <></>;

  return (
    <div style={{ marginBottom: 30 }}>
      <CardContent style={{ padding: 0 }}>
        <Grid container direction='row' justify='center' alignItems='center'>
          {imgsWithoutMain.map(img => (
            <Card className={classes.galleryImgContainer} key={img.id}>
              <img
                src={`${API_DOMAIN}/${img.big || img.path}`}
                alt=''
                style={{ height: '100%', width: '100%' }}
              />
              <Grid
                container
                direction='row'
                justify='space-between'
                alignItems='center'
                className={classes.galleryImgTitleBar}
              >
                {setMainImage && (
                  <IconButton style={{ color: 'white' }} onClick={() => setMainImage(img.id)}>
                    <StarBorder />
                  </IconButton>
                )}
                {me && me?.is_admin && setBannerImage && (
                  <IconButton
                    style={{ color: 'white' }}
                    onClick={() => setBannerImage(img.id)}
                  >
                    {!img.banner ? <ViewCarouselOutlined /> : <ViewCarousel />}
                  </IconButton>
                )}
                {deleteImage && (
                  <IconButton style={{ color: 'white' }} onClick={() => deleteImage(img.id)}>
                    <DeleteOutline />
                  </IconButton>
                )}
              </Grid>
            </Card>
          ))}
        </Grid>
      </CardContent>
    </div>
  );
};
