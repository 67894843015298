import React, { useLayoutEffect, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { ITariff } from '../../../../../interfaces/tariff';

import { TariffEditFields } from '../../../../../store/ducks/tariff.duck';
import TopTableCell from '../../../../../components/other/Table/TopTableCell';
import { useFormatMessage } from '../../../../../hooks';
import TableRows from './components/TableRows';
import DialogTariff from './components/DialogTariff';

const useStyles = makeStyles(theme => ({
  table: {
    flex: 1,
    backgroundColor: '#fff',
    overflowX: 'auto',
  },
  blackCell: {
    backgroundColor: 'rgba(150, 150, 150, 0.4)',
  },
  grayCell: {
    backgroundColor: 'rgba(150, 150, 150, 0.2)',
  },
  titleCell: {
    color: '#1e1e2d',
    width: '300px',
  },
  freeCell: {
    backgroundColor: '#666666',
    color: '#fff',
  },
  proCell: {
    backgroundColor: theme.palette.success.dark,
    color: '#fff',
  },
  tariffCell: {
    width: '200px',
  },
  button: {
    width: '100%',
  },
  freeButtonCell: {
    width: '200px',
    backgroundColor: 'rgba(150, 150, 150, 0.2)',
  },
  buyerProCell: {
    width: '200px',
    backgroundColor: theme.palette.secondary.main,
  },
  providerProCell: {
    width: '200px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  btnPay: {
    backgroundColor: '#ffff',
    color: theme.palette.primary.main,
    width: '100%',
    height: 32,
    fontWeight: 600,
    '&:hover': {
      backgroundColor: 'white',
    },
  },

  cellPayPro: {
    backgroundColor: theme.palette.primary.main,
    borderBottomRightRadius: 7,
  },

  btnPayFree: {
    background: 'linear-gradient(180deg, rgb(125,31,157) 52%, rgb(71,13,99) 100%)',
    color: '#ffff',
    padding: '0 30px',
    height: 32,
    fontWeight: 600,
  },
}));

const TariffTable: React.FC<{
  // type: TariffsType;
  tariffs: ITariff[];
  editable: boolean;
  loading: boolean;
  success: boolean;
  isVendor?: boolean;
  editTariff: (
    id: number,
    field: TariffEditFields,
    value: string | number | null,
    name?: string
  ) => void;
  payTariff?: (tariff: ITariff, days: number) => void;
  // submit: (id: number, field: TariffEditFields, value: string | number | null) => void;
  // setModal: (value: boolean) => void;
  canPay?: boolean;
  // disablePay: boolean;
  closeDialog?: boolean;
  setCloseDialog?: (value: boolean) => void;
}> = ({
  // type,
  loading,
  success,
  // submit,
  editTariff,
  editable,
  // setModal,
  canPay,
  // disablePay,
  tariffs,
  payTariff,
  closeDialog,
  isVendor,
  setCloseDialog,
}) => {
  const fm = useFormatMessage();
  const classes = useStyles();
  const [showTariffModal, setShowTariffModal] = useState<ITariff | null>(null);
  const [selectTariff, setSelectTariff] = useState<{ tariff: ITariff | null; days: number }>({
    tariff: null,
    days: 0,
  });

  useLayoutEffect(() => {
    if (!selectTariff.tariff && tariffs[1]) {
      setSelectTariff({ tariff: tariffs[1], days: 30 });
    }
  }, [tariffs]);

  useLayoutEffect(() => {
    if (closeDialog) {
      setShowTariffModal(null);
      setCloseDialog && setCloseDialog(false);
    }
  }, [closeDialog]);

  return (
    <>
      <Card className={classes.table}>
        <CardContent>
          <Table>
            <TableHead>
              <TableRow>
                <TopTableCell style={{ borderTopLeftRadius: 7 }}> </TopTableCell>
                <TopTableCell align='center' width={250}>
                  {fm('TARIFF.BASE')}
                </TopTableCell>
                <TopTableCell
                  style={{ borderTopRightRadius: 7 }}
                  colSpan={3}
                  align='center'
                  width={250}
                >
                  {fm('TARIFF.PRIME')}
                </TopTableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRows
                tariffs={tariffs}
                editable={editable}
                loading={loading}
                success={success}
                editTariff={editTariff}
                setSelectTariff={setSelectTariff}
                selectTariff={selectTariff}
              />
              {/* {tableConfig.map((block, index) => (
                <Block
                  type={type}
                  block={block}
                  editable={editable}
                  key={index.toString()}
                  loading={loading}
                  success={success}
                  submit={submit}
                  editTariff={editTariff}
                  freeTariff={freeTariff}
                  proTariff={proTariff}
                />
              ))} */}

              {canPay && (
                <TableRow>
                  <TableCell className={classes.titleCell} align='center' />
                  <TableCell align='center'>
                    {!isVendor && tariffs[0] && (
                      <Button
                        className={classes.btnPayFree}
                        onClick={() => {
                          payTariff && payTariff(tariffs[0], 0);
                        }}
                      >
                        {fm('TARIFFS.CHOOSE')}
                      </Button>
                    )}
                  </TableCell>
                  <TableCell className={classes.cellPayPro} align='center' colSpan={3}>
                    <Button
                      className={classes.btnPay}
                      onClick={() => {
                        selectTariff.tariff &&
                          payTariff &&
                          payTariff(selectTariff.tariff, selectTariff.days);
                      }}
                    >
                      {fm(isVendor ? 'ORDER.PRO' : 'TARIFFS.CHOOSE')}
                    </Button>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <DialogTariff
        handlePay={(item, days) => payTariff && payTariff(item, days)}
        showDialog={showTariffModal}
        hideModal={() => setShowTariffModal(null)}
        isVendor={isVendor}
      />
    </>
  );
};

export default TariffTable;
