import { TRole } from '../pages/auth/interfaces';
import { menuConfigItems } from '../constants/menuConfigItems';

const {
  // profile,
  // vendorProducts,
  // products,
  // orders,
  // users,
  usersAdmin,
  legalAdmin,
  // legal,
  settings,
  // catalog,
  // blog,
  // guestBlog,
  // tariffs,
  // tariffsAdmin,
  // productDashboard,
  // companies,
  // vendorUsers,
  // vendorCompany,
  // catalogForUser,
  // chats,
  // tenders,
  // buyersTenders,
  // vendorsTenders,
  // addTender,
  // allTenders,
  // buyersDocuments,
  // buyersOrders,
  // usersManager,
  // shifts,
  // statistic,
  // vendorRequests,
  // buyerRequests,
  projectsAdmin,
  invoices,
} = menuConfigItems;

// const getDefaultMenu = () => ({
//   header: {
//     self: {},
//     items: [],
//   },
//   aside: {
//     self: {},
//     items: [vendorsTenders, catalogForUser, profile, blog],
//   },
// });

const getManagerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    // items: [catalogForUser, productDashboard, shifts, profile, usersManager, blog, settings, legal],
    items: [],
  },
});

const getAdminMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [usersAdmin, projectsAdmin, invoices, settings, legalAdmin],
  },
});

const getVendorMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [projectsAdmin, invoices],
  },
});

const getBuyerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    // items: [buyersOrders, buyersTenders, catalogForUser, guestBlog, buyerRequests],
    items: [],
  },
});

const getGuestMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    // items: [catalog, guestBlog],
    items: [],
  },
});

const getContentManagerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    // items: [catalog, orders, users],
    items: [],
  },
});

const getMenuConfig = (userRoles: TRole[] = ['ROLE_BUYER'], isAuthorized?: boolean) => {
  if (isAuthorized) {
    if (userRoles === undefined) {
      return getGuestMenu();
    }
    if (userRoles[0] === 'ROLE_ADMIN') {
      return getAdminMenu();
    }
    if (userRoles[0] === 'ROLE_MANAGER') {
      return getManagerMenu();
    }
    if (userRoles[0] === 'ROLE_VENDOR') {
      return getVendorMenu();
    }
    if (userRoles[0] === 'ROLE_BUYER') {
      return getBuyerMenu();
    }
    if (userRoles[0] === 'ROLE_CONTENT_MANAGER') {
      return getContentManagerMenu();
    }
  } else {
    return getGuestMenu();
  }
};

export default getMenuConfig;
