import { Button } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDefineUserRole } from '../../../hooks';

const ButtonAddProduct: React.FC = () => {
  const intl = useIntl();
  const { me } = useSelector(({ profile }: any) => profile);
  const navigate = useNavigate();

  const isRoleAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isRoleVendor = useDefineUserRole(me, 'ROLE_VENDOR');
  const isRoleManager = useDefineUserRole(me, 'ROLE_MANAGER');

  const goToCreateProduct = useCallback(() => {
    if (isRoleAdmin || isRoleManager) {
      navigate(`/products/create`);
    } else {
      navigate(`/products/create/company/${me?.company?.id}/${me?.company?.name}`);
    }
  }, [navigate, isRoleAdmin, isRoleManager]);

  return (
    <>
      {(isRoleAdmin || isRoleManager || isRoleVendor) && (
        <Button color='primary' variant='contained' onClick={goToCreateProduct}>
          {intl.formatMessage({ id: 'CATEGORY.BUTTON.ADD.PRODUCT' })}
        </Button>
      )}
    </>
  );
};

export default ButtonAddProduct;
