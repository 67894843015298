import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {getSearchUsers} from "../../../../../crud/users.crud";
import {TStaffSearch} from "../../../../../components/ui/UsersFilter/types";
import {IUser} from "../../../../../interfaces/user";
import {useFormatMessage} from "../../../../../hooks";
import {getResponseMessage} from "../../../../../utils/utils";

export const useSearchUsers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [loadingPhone, setLoadingPhone] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState<IUser[]>([]);
  const dispatch = useDispatch();
  const fm = useFormatMessage();

  const fetchUsers = useCallback(
    async (params: Partial<TStaffSearch>) => {
      params.phone && params.phone !=='' ? setLoadingPhone(true) : setLoading(true);
      getSearchUsers(params)
        .then(res => {
          setTotal(res.data.total);
          setPage(res.data.page);
          setPerPage(res.data.per_page);
          setUsers(res.data.data);
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          params.phone && params.phone !=='' ? setLoadingPhone(false) : setLoading(false);
        });
    },
    [dispatch]
  );

  return { fetchUsers, loading,loadingPhone, page, perPage, total, users,setUsers };
};
