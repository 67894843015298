import { createStyles, makeStyles } from '@material-ui/core';

export const useStylesInvoicesPage = makeStyles(theme =>
  createStyles({
    container: {
      display: 'flex',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      backgroundColor: theme.palette.background.paper,
      flexDirection: 'column',
      // [theme.breakpoints.up('md')]: {
      //   flexDirection: 'row',
      // },
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    tableContainer: {
      flex: 1,
    },
    tariffText: {
      margin: 0,
      padding: 0,
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
    containerFilters: {
      display: 'flex',
      flexDirection: 'row',
    },
  })
);

export const useStylesInvoicesModal = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 30,
      padding: '10px 20px',
    },
    closeWrapper: {
      display: 'flex',
      width: '100%',
      paddingBottom: 0,
      justifyContent: 'space-between',
    },
  })
);
