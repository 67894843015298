import { format, parse } from 'date-fns';
import { ru, enUS, cs } from 'date-fns/locale';

export const getDateStop = (current_user_tariff?: any, locale = 'ru') => {
  if (current_user_tariff?.stop_datetime) {
    // eslint-disable-next-line no-nested-ternary
    const newLocale = locale === 'en' ? enUS : locale === 'cz' ? cs : ru;
    return format(
      parse(
        format(new Date(current_user_tariff.stop_datetime), 'dd.MM.yyyy'),
        'dd.MM.yyyy',
        new Date(),
        { locale: newLocale }
      ),
      'd MMMM y',
      { locale: newLocale }
    );
  }
  return 0;
};
