import { useCallback, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';

import { useFormatMessage } from '../../../hooks';
import { getResponseMessage } from '../../../utils/utils';

export const useAxiosLoginEmail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const fm = useFormatMessage();
  const [loadingEmail, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const locale = useSelector(({ i18n }: any) => i18n.lang);

  const makeLoginEmail = useCallback(
    (email: string) => {
      setSuccess(false);
      setLoading(true);
      axios
        .post('/api/users/code_request', {}, { params: { email, locale } })
        .then(() => {
          setSuccess(true);
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [locale]
  );

  return { makeLoginEmail, loadingEmail, success };
};
