import React from 'react';
import { Dialog } from '@material-ui/core';
import { TextField, Typography } from '@mui/material';
import moment from 'moment';

import { toAbsoluteUrl } from '../../../../../_base';
import { useFormatMessage } from '../../../../hooks';
import { IInvoice, IInvoicePayment } from '../../../../interfaces/projects';
import { useStylesInvoicesModal } from '../hooks/useStyles';

interface IProps {
  open: boolean;
  handleClose: (update?: boolean) => void;
  invoice?: IInvoice | null;
  settingInvoice: IInvoicePayment | null;
}

const InvoiceModal: React.FC<IProps> = ({ open, handleClose, invoice, settingInvoice }) => {
  const classes = useStylesInvoicesModal();
  const fm = useFormatMessage();

  if (!invoice) return <></>;

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose(false);
      }}
      style={{ margin: 0, padding: 0 }}
      fullWidth
      maxWidth='md'
    >
      <div className={classes.container}>
        <div className={classes.closeWrapper}>
          <div>
            <Typography component='p' style={{ fontSize: 14, fontWeight: 'bold' }}>
              {`${fm('NEW.INVOICE')}: ID ${invoice?.id} ${
                invoice?.created_at ? moment(invoice?.created_at).format('DD.MM.YYYY') : ''
              }`}
            </Typography>
            <Typography component='p' style={{ fontSize: 14 }}>
              {`${fm(invoice.tariff.id === 7 ? 'TARIFF.BASE' : 'TARIFF.PRIME')} (${
                invoice.tariff.users_num
              } ${fm('USERS').toLowerCase()}) | ${invoice.tariff_days || 0} ${fm(
                'DAYS'
              ).toLowerCase()} | CZK ${invoice.price || 0}`}
            </Typography>
          </div>
          <img
            alt='close'
            height={20}
            width={20}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              handleClose(false);
            }}
            src={toAbsoluteUrl('/media/icons/x.svg')}
          />
        </div>
        <TextField
          type='text'
          label={fm('COMPANY.FORM.LEGAL_PERSON')}
          margin='normal'
          value={settingInvoice?.company_name || ''}
          variant='outlined'
          inputProps={{ readOnly: true }}
        />
        <TextField
          type='text'
          label={fm('LEGAL.ENTITY.ADDRESS')}
          margin='normal'
          value={settingInvoice?.payment_details || ''}
          variant='outlined'
          inputProps={{ readOnly: true }}
        />
        <TextField
          type='text'
          label={fm('ICO')}
          margin='normal'
          value={settingInvoice?.ico || ''}
          variant='outlined'
          inputProps={{ readOnly: true }}
        />
        <TextField
          type='text'
          label={fm('VAT')}
          margin='normal'
          value={settingInvoice?.vat || ''}
          variant='outlined'
          inputProps={{ readOnly: true }}
        />
        <TextField
          type='text'
          label={fm('IBAN')}
          margin='normal'
          value={settingInvoice?.iban || ''}
          variant='outlined'
          inputProps={{ readOnly: true }}
        />
        <TextField
          type='text'
          label={fm('SWIFT')}
          margin='normal'
          value={settingInvoice?.swift || ''}
          variant='outlined'
          inputProps={{ readOnly: true }}
        />
        <TextField
          type='text'
          label={fm('ACCOUNT.NUMBER')}
          margin='normal'
          value={settingInvoice?.account_number || ''}
          variant='outlined'
          inputProps={{ readOnly: true }}
        />
        <TextField
          type='text'
          label={fm('PAYMENT.DETAILS.DESCRIPTION')}
          margin='normal'
          value={
            `${fm('VARIABLE.SYMBOL')} ${invoice.id} ${fm('FROM').toLowerCase()} ${
              invoice?.created_at ? moment(invoice?.created_at).format('DD.MM.YYYY') : ''
            }. ${fm('PAYMENT.TITLE')} ${invoice.company?.name || ''}, ${fm(
              'USER.TABLE.TARIFF'
            )} ${fm(invoice.tariff.id === 7 ? 'TARIFF.BASE' : 'TARIFF.PRIME')}, ${
              invoice.tariff_days || 0
            } ${fm('DAYS')}` || ''
          }
          variant='outlined'
          inputProps={{ readOnly: true }}
        />
      </div>
    </Dialog>
  );
};

export default InvoiceModal;
