import axios from 'axios';
import { IRequestData, IRequestResponse } from '../interfaces/request';

export const createRequest = (data: FormData, params: IRequestData) => 
  axios.post(`/api/request/add`, data, {
    params: {
      text: params.text, 
      title: params.title, 
      category_id: params.category_id
    }
});

export const editRequest = (id: number, dataResponse: IRequestResponse) =>
  axios.put(`/api/request/${id}`,{}, {
    params: {
      title: dataResponse.title, 
      text: dataResponse.text, 
      category_id: dataResponse.category_id
    }
});

export const editStatus = (id: number, status: string) =>
  axios.put(`/api/request_status/${id}`, {}, {
    params: {
      status
    }
});

export const getRequests = (
  page: number,
  per_page: number,
  statuses?: string,
  categories?: string,
) =>
  axios.get(`/api/requests`, {
    params: { 
      per_page, 
      page, 
      statuses, 
      categories
    },
});

export const getRequestById = (id: number) => (
  axios.get(`/api/request/${id}`)
)

export const deleteRequest = (id: number) => axios.delete(`/api/request/${id}`);
