import axios from 'axios';
import {
  PRODUCT_TYPES_URL,
  PRODUCT_TYPE_URL,
  getProductTypeByIdUrl,
  getParametersForType,
  editParameterForType,
  addParameterForType,
  delProdyctTypeByIdUrl,
  deleteParameterForType,
  setParamsPositionsUrl,
} from '../constants';
import { IProductType, ITypeParameter } from '../interfaces/productType';

export const createType = (data: IProductType) => axios.post(PRODUCT_TYPE_URL, data);

export const editType = (id: number, data: IProductType) =>
  axios.put(getProductTypeByIdUrl(id), data);

export const getProductType = (id: number) => axios.get(getProductTypeByIdUrl(id));

export const getProductTypes = () => axios.get(PRODUCT_TYPES_URL);

export const getParametersById = (id: number, filters?: boolean) =>
  axios.get(getParametersForType(id), { params: { filters } });

export const addParameterById = (id: number, data: ITypeParameter) =>
  axios.post(addParameterForType(id), data);

export const editParameterById = (id: number, data: ITypeParameter) =>
  axios.put(editParameterForType(id), data);

export const deleteProductType = (id: number) => axios.delete(delProdyctTypeByIdUrl(id));

export const deleteParameterById = (id: number) => axios.delete(deleteParameterForType(id));

export const setParamsPositions = (positions: string) =>
  axios.post(setParamsPositionsUrl(), {}, { params: { positions } });

export const getGroups = () => axios.get('/api/shop/parameter_groups');

export const createGroups = (name: string) =>
  axios.post('/api/shop/parameter_group', { name });

export const editGroup = (id: number, name: string) =>
  axios.put(`/api/shop/parameter_group/${id}`, { name });

export const deleteGroup = (id: number) => axios.delete(`/api/shop/parameter_group/${id}`);

export const changeParamGroup = (id: number, add?: number, remove?: number) =>
  axios.put(`/api/shop/parameters_in_group/${id}`, {}, { params: { add, remove } });

export const setGroupPositions = (group_positions: string) =>
  axios.post(setParamsPositionsUrl(), {}, { params: { group_positions } });

export const getAllParams = () => axios.get('/api/shop/product_type/0/parameters');

export const getGroup = (group: number) =>
  axios.get(`/api/shop/product_type/0/parameters?group=${group}`);
