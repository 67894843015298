import React from 'react';
import { useIntl } from 'react-intl';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  makeStyles,
  createStyles,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LinkIcon from '@material-ui/icons/Link';

import { SHeadTableCell } from '../../styledComponents/Tables';
import TablePaginator from '../TablePaginator/TablePaginator';
import Preloader from '../../other/Preloader/Preloader';

import { IUser } from '../../../interfaces/user';
import { IPaginationData } from '../../../interfaces/server';
import { getFio } from '../../../pages/home/users/users/utils';
import NoRowsOverlay from './NoRowsOverlay';
import { formatPhone } from '../../../utils/utils';

interface IProps {
  users: IUser[];
  loading: boolean;
  onEdit: (id: number) => any;
  onConnectOff?: (id: number) => any;
  onConnect?: (id: number, login: string, item: IUser) => any;
  connectedUsers?: number[];
  paginationData: IPaginationData;
  fetch?: (data: { page: number; perPage: number }) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    empty: {
      height: 200,
    },
    table: {
      marginTop: 20,
    },
    emptyOverlay: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  })
);

const UsersTable: React.FC<IProps> = ({
  users,
  loading,
  paginationData: { page, perPage, total },
  onConnectOff,
  onConnect,
  connectedUsers,
  onEdit,
  fetch,
}) => {
  const intl = useIntl();
  const classes = useStyles();

  if (loading) return <Preloader />;

  return (
    <Table aria-label='stores' className={classes.table}>
      <TableHead>
        <TableRow>
          <SHeadTableCell>{intl.formatMessage({ id: 'USER.TABLE.ID' })}</SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'USER.TABLE.FIO' })}</SHeadTableCell>
          <SHeadTableCell>
            {intl.formatMessage({ id: 'COMPANY.FORM.LEGAL_PERSON' })}
          </SHeadTableCell>
          <SHeadTableCell>{intl.formatMessage({ id: 'USER.TABLE.EMAIL' })}</SHeadTableCell>
          <SHeadTableCell>
            {intl.formatMessage({ id: 'PRODUCT.VIEW.PHONE_NUMBER' })}
          </SHeadTableCell>
          <SHeadTableCell align='right'>
            {intl.formatMessage({ id: 'USER.TABLE.ACTION' })}
          </SHeadTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading && (
          <TableRow>
            <TableCell className={classes.empty} colSpan={6} component='th' scope='row'>
              <Preloader />
            </TableCell>
          </TableRow>
        )}

        {!loading && !users.length && (
          <TableRow>
            <TableCell className={classes.empty} colSpan={6} component='th' scope='row'>
              <div className={classes.emptyOverlay}>
                <NoRowsOverlay label={intl.formatMessage({ id: 'MANAGER.EMPTY' })} />
              </div>
            </TableCell>
          </TableRow>
        )}

        {users.map(item => (
          <TableRow key={item.id}>
            <TableCell component='th' scope='row'>
              {item.id}
            </TableCell>
            <TableCell>{getFio(item)}</TableCell>
            <TableCell>{item.company_name || ''}</TableCell>
            <TableCell>{item.email}</TableCell>
            <TableCell style={{ whiteSpace: 'nowrap' }}>
              {item.phone_numbers !== ''
                ? formatPhone(
                    `${item.phone_numbers ? item.country?.code || '' : ''}${
                      item.phone_numbers || ''
                    }`,
                    // item.country
                  )
                : '-'}
            </TableCell>
            <TableCell>
              <IconButton
                color='primary'
                aria-label='previous'
                onClick={() => onEdit(item.id)}
              >
                <EditIcon />
              </IconButton>

              {onConnectOff && !onConnect && (
                <IconButton onClick={() => onConnectOff(item.id)}>
                  <LinkOffIcon color='error' />
                </IconButton>
              )}

              {onConnect && (
                <IconButton
                  onClick={
                    onConnectOff && connectedUsers && connectedUsers.includes(item.id)
                      ? () => onConnectOff(item.id)
                      : () => onConnect(item.id, item.login, item)
                  }
                >
                  {connectedUsers && connectedUsers.includes(item.id) ? (
                    <LinkOffIcon color='error' />
                  ) : (
                    <LinkIcon color='primary' />
                  )}
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      {!!fetch && (
        <TableFooter>
          <TableRow>
            <TablePaginator
              page={page}
              perPage={perPage}
              total={total}
              realPerPage={users.length}
              fetchRows={fetch}
              label={intl.formatMessage({ id: 'USERS.TABLE.PER_PAGE' })}
            />
          </TableRow>
        </TableFooter>
      )}
    </Table>
  );
};

export default UsersTable;
