import React, { CSSProperties } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';

interface IAlertDialog extends DialogProps {
  handleAgree: any;
  handleClose: any;
  cancelText?: string;
  okText?: string;
  message?: string;
  title?: string;
  styleActions?: CSSProperties;
  keys: string[];
}

const ModalEnter: React.FC<IAlertDialog> = ({
  open,
  handleClose,
  cancelText = 'Cancel',
  okText = 'OK',
  handleAgree,
  message = '',
  title = '',
  styleActions,
  keys,
}) => {
  const [value, setValue] = React.useState('');
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>{message}</DialogContentText>
        <TextField
          value={value}
          onChange={e => setValue(e.target.value)}
          variant='outlined'
          fullWidth
        />
      </DialogContent>
      <DialogActions style={styleActions}>
        <Button
          onClick={handleAgree}
          color='primary'
          autoFocus
          disabled={!keys.find(i => i.toLowerCase().trim() === value.toLowerCase().trim())}
        >
          {okText}
        </Button>
        <Button onClick={handleClose} color='primary' autoFocus>
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalEnter;
