import axios from 'axios';
import moment from 'moment';
import { IOrder } from '../interfaces/order';
import { createCart, getCart } from '../crud/cart.crud';
import { IUser, TUserCountry } from '../interfaces/user';

export const getResponseMessage = (e: any) => e?.response?.data?.message || 'Network error';

// example: 10000 => '10 000'
export const formatAsThousands = (data: string | number) =>
  String(data).replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, `$1${'\u00A0'}`);

export const makeAxiosGetRequest = (
  url: string,
  setData: React.Dispatch<any>,
  setLoading: React.Dispatch<any>,
  setErr: React.Dispatch<any>
) => {
  setLoading(true);
  axios
    .get(url)
    .then(res => setData(res.data.data))
    .catch(err => {
      if (err) setErr(err);
    })
    .finally(() => {
      setLoading(false);
      setErr(null);
    });
};

export const makeAxiosPostRequest = (
  url: string,
  data: any | undefined,
  setData: React.Dispatch<any>,
  setLoading: React.Dispatch<any>,
  setErr: React.Dispatch<any>,
  options?: any
) => {
  if (!options) options = {};
  setLoading(true);
  axios
    .post(url, data, options)
    .then(res => setData(res))
    .catch(err => {
      if (err) setErr(String(err));
    })
    .finally(() => {
      setLoading(false);
      setTimeout(() => {
        setErr(null);
      }, 100);
    });
};

export const makeAxiosDeleteRequest = (
  url: string,
  setData: React.Dispatch<any>,
  setLoading: React.Dispatch<any>,
  setErr: React.Dispatch<any>
) => {
  setLoading(true);
  axios
    .delete(url)
    .then(res => setData(res))
    .catch(err => {
      if (err) setErr(err);
    })
    .finally(() => {
      setLoading(false);
      setErr(null);
    });
};

export const thousands = (inData: string) => {
  return inData.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, `$1${'\u00A0'}`);
};

export const countLettersInString = (str: string, letter: string) => {
  let n = 0;
  for (const l of str) {
    if (l === letter) n += 1;
  }
  return n;
};

export const isEmail = (str: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(str).toLowerCase());
};

export const appendQuerys = (url: string, querys: string[]) => {
  let newUrl = `${url}`;
  for (let i = 0; i < querys.length; i += 1) {
    newUrl += `${i === 0 ? '?' : '&'}${querys[i]}`;
  }
  return newUrl;
};

export const maskPhone = (phone: string = '', country_code = '') => {
  if (!phone) {
    return '';
  }

  if (phone[0] !== '+') {
    phone = '+' + country_code + +phone;
  }

  const val = phone.replace(/\D/g, '');

  return `+${val[0]} ${val.slice(1, 4)} ${val.slice(4, 7)} ${val.slice(7, 9)} ${val.slice(9)}`;
};

export const cleanPhone = (phone: string, country = '') => {
  return phone
    .replace(country, '')
    .replace(/ /g, '')
    .replace(/[{()}]/g, '')
    .trim();
};

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  // eslint-disable-next-line no-restricted-properties
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getTimePastFromNow = (date: number) => {
  let text = '-';
  const nowDate = moment();
  const lastPostDate = moment(date);
  const diffInMinutes = nowDate.diff(lastPostDate, 'minutes');
  if (diffInMinutes <= 0) {
    const diffInSeconds = nowDate.diff(lastPostDate, 'seconds');
    if (diffInSeconds < 5) {
      text = 'Только что';
    } else {
      text = diffInSeconds + ' cекунд';
    }
  } else if (diffInMinutes < 60) {
    text = diffInMinutes + ' минут';
  } else {
    const diffInHours = nowDate.diff(lastPostDate, 'hours');
    if (diffInHours < 24) {
      text = diffInHours + ' часов';
    } else {
      const diffInDays = nowDate.diff(lastPostDate, 'days');
      if (diffInDays < 7) {
        text = diffInDays + ' д';
      } else {
        const diffInWeeks = nowDate.diff(lastPostDate, 'weeks');
        if (diffInDays < 365) {
          text = diffInWeeks + ' н';
        } else {
          const diffInYears = nowDate.diff(lastPostDate, 'years');
          text = diffInYears + ' г';
        }
      }
    }
  }
  return text;
};

export const generateUUID = () => {
  let id = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
  id = id.replace(/[xy]/g, c => {
    const r = Math.floor(Math.random() * 16);
    let v;
    if (c === 'x') {
      v = r;
    } else {
      // eslint-disable-next-line no-bitwise
      v = (r & 0x3) | 0x8;
    }
    return v.toString(16);
  });
  return id;
};

export const getFormatedTime = (date: string | Date) => {
  // remove seconds from time
  const timeArr = new Date(date)
    .toLocaleTimeString()
    .split(':')
    .map((i, idx) => (idx === 2 ? i.slice(2) : i));
  const time = `${timeArr[0]}:${timeArr[1]}${timeArr[2]}`;
  return time;
};

export const formatDate = (date: string | Date) => {
  return `${moment(date).format('D MMM YYYY')} ${getFormatedTime(date)}`;
};

export const isImage = (imageName: string) => imageName.match(/.(jpg|jpeg|png|gif)$/i);

export const formatPhone = (phone: string, country?: TUserCountry) => {
  if (!phone) return '';
  if (country && country.mask && phone !== '') {
    const mask = country.mask.replace(/[()-]/g, ' ');
    let formattedPhone = '';
    let phoneIndex = 0;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < mask.length; i++) {
      if (phoneIndex >= phone.length) {
        break;
      }
      if (mask[i] === '*') {
        formattedPhone += phone[phoneIndex];
        // eslint-disable-next-line no-plusplus
        phoneIndex++;
      } else {
        formattedPhone += mask[i];
      }
    }
    return `+${country.code} ${formattedPhone}`;
  }
  return phone && phone === ''
    ? '-'
    : `+${phone.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1 $2 $3 $4')}`;
};
export const createLabel = (value: number) => {
  const cases = [2, 0, 1, 1, 1, 2];
  return `${
    ['день', 'дня', 'дней'][
      value % 100 > 4 && value % 100 < 20 ? 2 : cases[value % 10 < 5 ? value % 10 : 5]
    ]
  }`;
};

export const daysShipping = (order: IOrder) => {
  if (!order.max_days && !order.min_days) {
    return '-';
  }
  if (order.min_days !== order.max_days && order.max_days && order.min_days) {
    return `от ${order.min_days} до ${order.max_days} дней`;
  }
  if (order.max_days === order.min_days || order.max_days || order.min_days) {
    if (order.max_days) {
      return `${order.max_days} ${createLabel(order.max_days)}`;
    }
    if (order.min_days) {
      return `${order.min_days} ${createLabel(order.min_days)}`;
    }
  }
  return '-';
};

export const checkHash = async () => {
  const hash = localStorage.getItem('hash');

  if (hash) {
    try {
      await getCart(hash);
      return hash;
    } catch (error) {
      const { data } = await createCart();
      localStorage.setItem('hash', data.data.cart_hash);
      return data.data.cart_hash;
    }
  } else {
    const { data } = await createCart();
    localStorage.setItem('hash', data.data.cart_hash);
    return data.data.cart_hash;
  }
  return hash;
};

export const getFioSearch = (user: IUser) => {
  let ret = '';
  if (user.last_name) {
    ret += `${user.last_name}`;
  }
  // if (user.first_name) {
  //   ret += ` ${user.first_name}`;
  // }
  return ret;
};
