import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Typography,
  Card,
  Table,
  CardContent,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TableFooter,
  Tooltip,
} from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from '@material-ui/icons/Link';
import { Col, Row } from 'react-bootstrap';
import { useIntl } from 'react-intl';

import { SHeadTableCell } from '../../../components/styledComponents/Tables';
import Preloader from '../../../components/other/Preloader/Preloader';
import TablePaginator from '../../../components/tableComponents/TablePaginator/TablePaginator';
import { actions as tariffActions } from '../../../store/ducks/tariff.duck';
import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import homeStyles from '../../../constants/homeStyles';
import { useStylesProjectsPage } from './hooks/useStyles';
import { useDefineUserRole, useFormatMessage } from '../../../hooks';
import { useGetProjects } from './hooks/useGetProjects';
import { IAppState } from '../../../store/rootDuck';
import { getDateStop } from '../tariffs/utils/getStopDate';
import StatusIndicator from '../../../components/styledComponents/StatusIndicator';
import { getFio } from '../users/users/utils';
import ModalEnter from '../../../components/other/Modals/ModalEnter';
import { useDeleteProject } from './hooks/useDeleteProject';
import { IInvoice, IProject } from '../../../interfaces/projects';
import TariffModal from '../tariffs/components/UserCurrentTariff/TariffsModal';
import ItemProject from './components/itemProject';
import AutocompleteStaff from '../../../components/AutocompleteStaff';
import { useSearchProjects } from './hooks/useSearchProjects';
import InvoiceModal from '../invoices/components/InvoiceModal';
import { useGetInvoice } from '../settings/invoice/hooks/useGetInvoice';

const ProjectsPage: React.FC = () => {
  const classes = useStylesProjectsPage();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const homeClasses = homeStyles();
  const fm = useFormatMessage();
  const intl = useIntl();
  const [deleteId, setDeleteId] = React.useState<number | null>(null);
  const [editProjectTariff, setEditProjectTariff] = useState<IProject | null>(null);
  const [invoiceModal, setInvoiceModal] = useState<IInvoice | null>(null);
  const { fetchGetInvoice, invoice } = useGetInvoice();
  const { fetchProjects, loading, page, perPage, total, projects } = useGetProjects();
  const {
    fetchSearchProjects,
    loading: searchLoading,
    projects: searchProjects,
    setProjects,
  } = useSearchProjects();
  const [projectSelect, setProjectSelect] = useState<IProject | null>(null);

  const { fetchDeleteProject, loading: deleteLoading, success } = useDeleteProject();

  const currentProjects = useMemo(() => (projectSelect ? [projectSelect] : projects), [
    projects,
    projectSelect,
  ]);

  const { me } = useSelector((store: IAppState) => ({
    me: store.profile.me,
  }));

  const { tariffs } = useSelector((store: IAppState) => ({
    tariffs: store.tariff.tariffs,
  }));

  const isVendor = useDefineUserRole(me, 'ROLE_VENDOR');

  const successEditCompanyTariff = useCallback(() => {
    setEditProjectTariff(null);
    fetchProjects(1, 20, state?.user?.id || (isVendor ? me?.id : undefined));
  }, [page, perPage, state, isVendor, me]);

  setLayoutSubheader({
    title: isVendor
      ? fm('PROJECTS.TITLE')
      : `${fm('PROJECTS')}${state?.user ? ` - ${getFio(state?.user, true)}` : ''}`,
    breadcrumb: [],
    back: !!(state?.user?.id && me?.is_admin),
  });

  setLayoutFooter({ show: true });

  useLayoutEffect(() => {
    fetchGetInvoice();
  }, []);

  useLayoutEffect(() => {
    fetchProjects(1, 20, state?.user?.id || (isVendor ? me?.id : undefined));
    dispatch(tariffActions.fetchRequest());
  }, [state, isVendor]);

  useLayoutEffect(() => {
    success && fetchProjects(1, 20, state?.user?.id || (isVendor ? me?.id : undefined));
  }, [success]);

  if (me && !me.is_admin && !isVendor) return <></>;

  if (!me || loading) return <Preloader />;

  return (
    <>
      <Row>
        <Col style={{ background: 'white', marginRight: 10, padding: 0, marginLeft: 10 }}>
          <div className={classes.addBtn}>
            <div className={classes.filterColTop}>
              <AutocompleteStaff
                options={searchProjects || []}
                loading={searchLoading}
                inputValue={projectSelect}
                label={fm('USER.SEARCH.PROJECT.NAME')}
                getOptionLabel={option => option.name || `${option.description || ''} `}
                inputClassName={{ width: '100%' }}
                searchType='name'
                searchStaff={fetchSearchProjects}
                clearUsers={() => {
                  setProjectSelect(null);
                  setProjects(null);
                }}
                setSelectedValue={value => {
                  value && setProjectSelect(value);
                  // setPhone('');
                }}
              />
            </div>
            <Button
              color='primary'
              className={classes.gradientBtn}
              variant='contained'
              onClick={() => navigate('/projects/new', { state: { user: state?.user } })}
            >
              {fm('PROJECTS.BUTTON.ADD')}
            </Button>
          </div>

          <div className={classes.container}>
            {!currentProjects.length ? (
              <Card className={classes.emptyContainer}>
                <Typography className={classes.title} component='h5' variant='h5'>
                  {fm('PROJECTS.ITEM.EMPTY')}
                </Typography>
              </Card>
            ) : (
              <>
                {!isVendor ? (
                  <Card className={classes.tableContainer}>
                    <CardContent className={homeClasses.tableContainer}>
                      <Table aria-label='simple table'>
                        <TableHead>
                          <TableRow>
                            <SHeadTableCell>{fm('USER.TABLE.ID')}</SHeadTableCell>
                            <SHeadTableCell>{fm('BODY.TABLE.NAME')}</SHeadTableCell>
                            <SHeadTableCell>{fm('DOMAIN')}</SHeadTableCell>
                            <SHeadTableCell>{fm('USER.TABLE.TARIFF')}</SHeadTableCell>
                            <SHeadTableCell>{fm('USER.TABLE.STOPDATETARIFF')}</SHeadTableCell>
                            <SHeadTableCell>{fm('CATEGORIES.TABLE.ACTIVE')}</SHeadTableCell>
                            {!isVendor && !state?.user && (
                              <>
                                <SHeadTableCell>{fm('USER.EDIT.FIO')}</SHeadTableCell>
                                <SHeadTableCell>{fm('LEGAL.ENTITY')}</SHeadTableCell>
                              </>
                            )}
                            <SHeadTableCell align='right'>
                              {fm('USER.TABLE.ACTION')}
                            </SHeadTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {currentProjects.map(item => (
                            <TableRow key={item.id}>
                              <TableCell component='th' scope='row'>
                                {item.id || ''}
                              </TableCell>
                              <TableCell>{item.name || ''}</TableCell>
                              <TableCell>
                                <a
                                  rel='noreferrer'
                                  target='_blank'
                                  href={item.domain ? `https://${item.domain}` : undefined}
                                >
                                  {item.domain || ''}
                                </a>
                              </TableCell>
                              <TableCell>
                                {!me.is_admin ? (
                                  <>
                                    {item.current_tariff?.tariff
                                      ? `${fm(
                                          item.current_tariff?.tariff.id === 7
                                            ? 'TARIFF.BASE'
                                            : 'TARIFF.PRIME'
                                        )} (${item.current_tariff.tariff.users_num} ${fm(
                                          'USERS'
                                        ).toLocaleLowerCase()})`
                                      : ''}
                                  </>
                                ) : (
                                  <p
                                    className={classes.tariffText}
                                    onClick={() => setEditProjectTariff(item)}
                                  >
                                    {item.current_tariff?.tariff
                                      ? `${fm(
                                          item.current_tariff?.tariff.id === 7
                                            ? 'TARIFF.BASE'
                                            : 'TARIFF.PRIME'
                                        )} (${item.current_tariff.tariff.users_num} ${fm(
                                          'USERS'
                                        ).toLocaleLowerCase()})`
                                      : ''}
                                  </p>
                                )}
                              </TableCell>
                              <TableCell>
                                {item.current_tariff?.stop_datetime
                                  ? getDateStop(item.current_tariff, intl.locale)
                                  : ''}
                              </TableCell>
                              <TableCell>
                                <StatusIndicator isActive={item.active} />
                              </TableCell>
                              {!isVendor && !state?.user && (
                                <>
                                  <TableCell>
                                    {item.author ? getFio(item.author) : ''}
                                  </TableCell>
                                  <TableCell>{item.author?.company_name || ''}</TableCell>
                                </>
                              )}
                              <TableCell align='right'>
                                <Tooltip title={fm('SEARCH.GOTO')}>
                                  <IconButton
                                    color='primary'
                                    aria-label='previous'
                                    onClick={() =>
                                      item.domain &&
                                      window.open(`https://${item.domain}`, '_blank')
                                    }
                                    disabled={deleteLoading || !item.domain}
                                  >
                                    <LinkIcon />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title={fm('edit')}>
                                  <IconButton
                                    color='primary'
                                    aria-label='previous'
                                    onClick={() => navigate(`/projects/edit/${item.id}/${2}`)}
                                    disabled={deleteLoading}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                {me.is_admin && (
                                  <Tooltip title={fm('COMMON.BUTTON.DELETE')}>
                                    <IconButton
                                      color='secondary'
                                      aria-label='previous'
                                      onClick={() => setDeleteId(item.id)}
                                      disabled={deleteLoading}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                        {!projectSelect && (
                          <TableFooter>
                            <TableRow>
                              <TablePaginator
                                page={page}
                                realPerPage={projects.length}
                                perPage={perPage}
                                total={total}
                                fetchRows={(data: any) =>
                                  fetchProjects(
                                    data.page,
                                    data.perPage,
                                    state?.user?.id || (isVendor ? me?.id : undefined)
                                  )
                                }
                                label={fm('PROJECTS.TABLE.PER_PAGE')}
                              />
                            </TableRow>
                          </TableFooter>
                        )}
                      </Table>
                    </CardContent>
                  </Card>
                ) : (
                  <div className={classes.itemContent}>
                    {projects.map(item => (
                      <ItemProject
                        key={item.id}
                        item={item}
                        isAdmin={me.is_admin}
                        getPro={() => setEditProjectTariff(item)}
                      />
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </Col>
      </Row>

      <TariffModal
        open={Boolean(editProjectTariff)}
        handleClose={() => setEditProjectTariff(null)}
        project={editProjectTariff}
        successFunc={successEditCompanyTariff}
        tariffs={tariffs}
        me={me}
        successInvoice={item => setInvoiceModal(item)}
      />
      <ModalEnter
        open={!!deleteId}
        handleAgree={() => {
          if (deleteId) {
            fetchDeleteProject(deleteId);
            setDeleteId(null);
          }
        }}
        handleClose={() => setDeleteId(null)}
        keys={['да', 'Yes', 'Ano']}
        cancelText={fm('COMMON.BUTTON.CANCEL')}
        okText={fm('COMMON.BUTTON.DELETE')}
        title={fm('PROJECT.DELETE.TEXT')}
      />

      <InvoiceModal
        invoice={invoiceModal}
        handleClose={() => {
          setInvoiceModal(null);
          navigate('/invoices/list');
        }}
        open={!!invoiceModal}
        settingInvoice={invoice}
      />
    </>
  );
};

export default ProjectsPage;
