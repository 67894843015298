import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { Card, Tab, Tabs } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { a11yProps, TabPanel } from '../../../../components/other/Tab/TabPanel';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { ProfileForm } from '../components';
import { IAppState } from '../../../../store/rootDuck';
import { actions as profileActions } from '../../../../store/ducks/profile.duck';
import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';
import homeStyles from '../../../../constants/homeStyles';
import { UploadImages } from '../../../../components/ui/UploadImages';
import { handleUploadAvatar } from './hooks/handleUploadAvatar';
import { useShowErrors } from '../../../../hooks';
import { useUserAttributes } from '../../users/users/hooks/getUserAttributes';
import { useDeleteMePhoto } from './hooks/useDeleteMePhoto';
import Bonuses from '../components/Bonuses';
import { UserRoles } from '../../../../interfaces/user';

const ProfileEditPage: React.FC<TPropsFromRedux> = ({ me, loadingMe, fetchMe }) => {
  const [valueTabs, setValueTabs] = useState(0);
  // const [uploadAvatar, loadingAvatar, errAvatar] = handleUploadAvatar();
  const { userAttr, userAttrLoading } = useUserAttributes();
  const { deletePhoto, successPhotoDelete } = useDeleteMePhoto();

  const homeClasses = homeStyles();
  const intl = useIntl();

  // useShowErrors([errAvatar]);

  setLayoutSubheader({
    title: intl.formatMessage({ id: 'SUBMENU.PROFILE' }),
    breadcrumb: [],
  });
  setLayoutFooter({ show: true });

  useEffect(() => {
    successPhotoDelete && fetchMe();
  }, [successPhotoDelete]);

  useEffect(() => {
    fetchMe();
  }, []);

  const handleTabsChange = (_: any, newValue: number) => {
    setValueTabs(newValue);
  };

  if (!me || loadingMe || userAttrLoading) return <Preloader />;

  return (
    <Row>
      <Col>
        <Card className={homeClasses.container}>
          <Tabs
            value={valueTabs}
            onChange={handleTabsChange}
            variant='scrollable'
            indicatorColor='primary'
            textColor='primary'
            aria-label='tabs'
          >
            <Tab
              label={intl.formatMessage({ id: 'SETTING.PROFILE.EDIT' })}
              {...a11yProps(0)}
            />
            {/* {!me.is_admin && !me?.roles.includes(UserRoles.ROLE_VENDOR) && (
              <>
                <Tab
                  label={intl.formatMessage({id: 'SETTING.PROFILE.PHOTO'})}
                  {...a11yProps(1)}
                />
                <Tab
                  label={intl.formatMessage({id: 'SETTING.PROFILE.BONUSES'})}
                  {...a11yProps(2)}
                />
              </>
            )} */}
          </Tabs>
          <TabPanel value={valueTabs} index={0}>
            <ProfileForm me={me} userAttr={userAttr} />
          </TabPanel>
          {/* <TabPanel value={valueTabs} index={1}>
            <UploadImages
              images={
                me.avatar
                  ? [
                      {
                        id: 1,
                        path: typeof me.avatar === 'string' ? me.avatar : me.avatar.path,
                        main: true,
                        name: 'avatar',
                        extension: '.jpg',
                        mime_type: typeof me.avatar === 'string' ? me.avatar : me.avatar.path,
                        small: '',
                        path_blob: '',
                        banner: false,
                      },
                    ]
                  : undefined
              }
              uploadImages={uploadAvatar}
              withGallery
              withCrop
              cropAspect={1}
              deleteImage={deletePhoto}
            />
          </TabPanel> */}
          <TabPanel value={valueTabs} index={2}>
            <Bonuses me={me} />
          </TabPanel>
          {/* <TabPanel value={valueTabs} index={2}>
            <Companies me={me} />
          </TabPanel> */}
        </Card>
      </Col>
    </Row>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loadingMe: state.profile.loading,
  }),
  {
    fetchMe: profileActions.fetchRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProfileEditPage);
