import React from 'react';
import { Route, Routes } from 'react-router-dom';
import TariffList from './TariffList';

const TariffsPage = () => (
  <Routes>
    <Route path='list' element={<TariffList />} />
  </Routes>
);

export default TariffsPage;
