import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import objectPath from 'object-path';

import * as builder from '../../ducks/builder';
import KTToggle from '../../_assets/js/toggle';
import UserProfile from '../../partials/layout/UserProfile';
import HeaderMobileNavigation from '../../../app/components/ui/HeaderMobileNavigation';
import { UserRoles } from '../../../app/interfaces/user';
import { IAppState } from '../../../app/store/rootDuck';

const HeaderMobile = () => {
  const toggleButtonRef = React.useRef();

  const { me } = useSelector((store: IAppState) => ({
    me: store.profile.me,
  }));

  const {
    headerMobileCssClasses,
    headerMobileAttributes,
    headerMenuSelfDisplay,
  } = useSelector((store: IAppState) => ({
    headerMobileCssClasses: builder.selectors.getClasses(store, {
      path: 'header_mobile',
      toString: true,
    }),
    headerMobileAttributes: builder.selectors.getAttributes(store, {
      path: 'aside_menu',
    }),
    headerMenuSelfDisplay:
      objectPath.get(store.builder.layoutConfig, 'header.menu.self.display') === true,
  }));

  useEffect(() => {
    KTToggle(toggleButtonRef.current, {
      target: 'body',
      targetState: 'kt-header__topbar--mobile-on',
      togglerState: 'kt-header-mobile__toolbar-topbar-toggler--active',
    });
  }, []);

  return (
    <div
      id='kt_header_mobile'
      className={`kt-header-mobile ${headerMobileCssClasses}`}
      {...headerMobileAttributes}
    >
      <div
        className='kt-header-mobile__toolbar'
        style={{
          flex: 0.4,
          display: me?.roles.includes(UserRoles.ROLE_BUYER) || !me ? 'none' : 'flex',
        }}
      >
        {headerMenuSelfDisplay && (
          <button className='kt-header-mobile__toggler' id='kt_header_mobile_toggler'>
            <span />
          </button>
        )}
      </div>
      <div className='kt-header-mobile__toolbar' style={{ justifyContent: 'flex-end' }}>
        {me?.roles.includes(UserRoles.ROLE_BUYER) ? (
          <HeaderMobileNavigation />
        ) : (
          !me?.roles && <HeaderMobileNavigation />
        )}
        <UserProfile showAvatar={true} />
      </div>
    </div>
  );
};

export default HeaderMobile;
