import React, { useState, useEffect } from 'react';
import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';
import { IUser } from '../../interfaces/user';
import { useFormatMessage } from '../../hooks';
import { formatPhone } from '../../utils/utils';

// const FIRST_FETCH_SYMBOL = 3;

interface IProps {
  id?: string;
  options: IUser[];
  inputValue?: IUser;
  label: string;
  inputClassName?: any;
  loading: boolean;
  handleBlur?: (e: any) => {} | void;
  searchStaff: (data: { fio?: string; phone?: string; id?: number }) => void;
  clearUsers: () => void;
  setSelectedValue: (location: any) => {} | void;
  isId?: boolean;
}

const AutocompleteStaffPhone: React.FC<IProps> = ({
  id = '',
  options,
  inputValue,
  label,
  inputClassName,
  handleBlur,
  loading,
  searchStaff,
  clearUsers,
  setSelectedValue,
  isId,
}) => {
  const [location, setLocation] = useState<string>('');
  const fm = useFormatMessage();

  useEffect(() => {
    // if (!location || location.length === FIRST_FETCH_SYMBOL) {
    if (location && !location.endsWith(' ') && location.length > (isId ? 0 : 2)) {
      isId && location
        ? searchStaff({ id: Number(location) })
        : searchStaff({ phone: location });
    }
  }, [searchStaff, location]);

  return (
    <MaterialAutocomplete
      id={`autocomplete${id}`}
      noOptionsText={isId ? fm('USER.SEARCH.STAFF.ID') : fm('USER.SEARCH.STAFF.PHONE')}
      options={options}
      loading={loading}
      // getOptionLabel={option => option.phone}
      getOptionLabel={option =>
        isId && option.id ? option.id.toString() : formatPhone(option.phone, option.country)
      }
      onChange={(e: any, val: any) => {
        if (val) {
          setSelectedValue(val);
        } else {
          setLocation('');
          clearUsers();
        }
      }}
      filterOptions={o => o}
      value={inputValue}
      onInputChange={(_e: any, _val: any, reason: any) => {
        if (reason === 'clear') setSelectedValue({ phone: '' });
      }}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            type={isId ? 'number' : 'text'}
            margin='normal'
            name='location'
            variant='outlined'
            label={label}
            className={inputClassName}
            onBlur={handleBlur}
            style={{ margin: 0 }}
            onChange={e => {
              setLocation(e.target.value);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color='inherit' size={20} />}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default AutocompleteStaffPhone;
