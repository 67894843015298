import React, { useEffect, useMemo, useState } from 'react';
// @ts-ignore
import { CKEditor } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import Editor from 'ckeditor5-startmobile-custom-build';
import 'ckeditor5-startmobile-custom-build/build/translations/ru';
import { useIntl } from 'react-intl';
import { useStyles } from './styles';
import defaultConfig from './config';

type Props = {
  html: string;
  setEditorStateCallback: any;
  toolbarItems?: string[];
};

export const CkEditorCustom: React.FC<Props> = ({
  html,
  setEditorStateCallback,
  toolbarItems = [],
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const [localEditorState, setLocalEditorState] = useState<any>();

  const config = useMemo(
    () => ({
      ...defaultConfig,
      language: { ui: intl.locale },
      toolbar: { items: [...defaultConfig.toolbar.items, ...toolbarItems] },
    }),
    [intl]
  );

  useEffect(() => {
    if (localEditorState) localEditorState.setData(html);
  }, [localEditorState, html]);

  return (
    <div className={styles.container}>
      <CKEditor
        editor={Editor}
        config={config}
        data={html}
        onReady={(editor: any) => {
          setLocalEditorState(editor);
          setEditorStateCallback(editor);
        }}
        // onChange={(event: any, editor: any) => {
        // const data = editor.getData();
        // setGetHtmlCallback(editor);
        // }}
        // onBlur={(event: any, editor: any) => {
        //   console.log('Blur.', editor, event);
        // }}
        // onFocus={(event: any, editor: any) => {
        //   console.log('Focus.', editor, event);
        // }}
      />
    </div>
  );
};
