import React, { useLayoutEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Card, TextField } from '@material-ui/core';
import { useFormik } from 'formik';

import Preloader from '../../../../components/other/Preloader/Preloader';
import { IAppState } from '../../../../store/rootDuck';
import { setLayoutSubheader } from '../../../../utils/layout';
import homeStyles from '../../../../constants/homeStyles';
import { useGetInvoice } from './hooks/useGetInvoice';
import { IInvoicePayment } from '../../../../interfaces/projects';
import { useEditInvoice } from './hooks/useEditInvoice';
import { useFormatMessage } from '../../../../hooks';
import { ButtonWithLoader } from '../../../../components/other/Buttons';

const getInitialValues = (invoice: IInvoicePayment | null) => ({
  company_name: invoice?.company_name || '',
  ico: invoice?.ico || '',
  // tin: invoice?.tin || '',
  vat: invoice?.vat || '',
  // bic: invoice?.bic || '',
  account_number: invoice?.account_number || '',
  payment_details: invoice?.payment_details || '',
  // payment_purpose: invoice?.payment_purpose || '',
  iban: invoice?.iban || '',
  swift: invoice?.swift || '',
});

const InvoicePage: React.FC = () => {
  const homeClasses = homeStyles();
  const fm = useFormatMessage();

  const { fetchGetInvoice, invoice } = useGetInvoice();
  const { fetchEditInvoice, loading: editLoading } = useEditInvoice();

  const { me } = useSelector((store: IAppState) => ({
    me: store.profile.me,
  }));  

  setLayoutSubheader({
    title: fm('PAYMENT.DETAILS.TITLE'),
  });

  useLayoutEffect(() => {
    fetchGetInvoice();
  }, []);

  const { values, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: getInitialValues(invoice),
    enableReinitialize: true,
    onSubmit: submitValues => {
      const data = new FormData();
      // data.append('_method', 'put');
      data.append('company_name', submitValues.company_name);
      data.append('ico', submitValues.ico);
      // data.append('tin', submitValues.tin);
      data.append('vat', submitValues.vat);
      // data.append('bic', submitValues.bic);
      data.append('iban', submitValues.iban);
      data.append('swift', submitValues.swift);
      data.append('account_number', submitValues.account_number);
      data.append('payment_details', submitValues.payment_details);
      // data.append('payment_purpose', submitValues.payment_purpose);
      fetchEditInvoice(data);
    },
  });

  if (me && !me.is_admin) return <></>;

  if (!me || !invoice) return <Preloader />;

  return (
    <Row>
      <Col>
        <Card className={homeClasses.container}>
          <div className={homeClasses.form}>
            <TextField
              type='text'
              label={fm('COMPANY.FORM.LEGAL_PERSON')}
              margin='normal'
              name='company_name'
              value={values.company_name || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              type='text'
              label={fm('LEGAL.ENTITY.ADDRESS')}
              margin='normal'
              name='payment_details'
              value={values.payment_details || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              type='text'
              label={fm('ICO')}
              margin='normal'
              name='ico'
              value={values.ico || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {/* <TextField
              type='text'
              label={fm('TIN')}
              margin='normal'
              name='tin'
              value={values.tin || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
            /> */}
            <TextField
              type='text'
              label={fm('VAT')}
              margin='normal'
              name='vat'
              value={values.vat || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
            />
             {/* <TextField
              type='text'
              label={fm('BIC')}
              margin='normal'
              name='bic'
              value={values.bic || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
            /> */}
            <TextField
              type='text'
              label={fm('IBAN')}
              margin='normal'
              name='iban'
              value={values.iban || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              type='text'
              label={fm('SWIFT')}
              margin='normal'
              name='swift'
              value={values.swift || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              type='text'
              label={fm('ACCOUNT.NUMBER')}
              margin='normal'
              name='account_number'
              value={values.account_number || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {/* <TextField
              type='text'
              label={fm('PAYMENT.DETAILS.DESCRIPTION')}
              margin='normal'
              name='payment_purpose'
              value={values.payment_purpose || ''}
              variant='outlined'
              onBlur={handleBlur}
              onChange={handleChange}
            /> */}
            <div className={homeClasses.actions}>
              <ButtonWithLoader
                disabled={editLoading}
                loading={editLoading}
                onPress={handleSubmit}
              >
                {fm('COMMON.BUTTON.SAVE')}
              </ButtonWithLoader>
            </div>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default InvoicePage;
