import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    title: {
      marginBottom: 20,
      textTransform: 'capitalize',
    },
    filterColTop: {
      width: '100%',
      marginRight: 30,
      [theme.breakpoints.up('md')]: {
        width: '100%',
        minWidth: 326,
        maxWidth: 326,
      },
    },
    empty: {
      marginBottom: 20,
      marginTop: 20,
    },
    addBtn: {},
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    green: {
      color: '#fff',
      backgroundColor: '#566DC6FF',
    },
    emptyCompany: {
      width: 40,
      height: 40,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    tariffText: {
      margin: 0,
      padding: 0,
      color: '#470D63FF',
      cursor: 'pointer',
    },
    preloaderContainer: {
      marginTop: 100,
      flex: 1,
    },
    container: {
      display: 'flex',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      flexDirection: 'column',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
      },
    },
    filterCol: {
      width: '100%',
      marginBottom: theme.spacing(3),
      [theme.breakpoints.up('md')]: {
        width: 326,
      },
    },
    filter: {
      marginBottom: 5,
      padding: '10px 30px 25px',
      overflow: 'visible',
    },
    tableContainer: {
      flex: 1,
    },
    companyNameAvatarWrapper: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
    },
  })
);

export const useStylesBonusesUser = makeStyles(theme =>
  createStyles({
    actions: {
      marginTop: theme.spacing(3),
    },
    lang: {
      display: 'flex',
      alignItems: 'center',
    },
    langImage: {
      width: '30px',
      height: '30px',
      borderRadius: '5px',
      overflow: 'hidden',
    },
    langTitle: {
      marginLeft: '5px',
    },
  })
);
