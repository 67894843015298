import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import { IAppState } from '../../../../../store/rootDuck';
import { toAbsoluteUrl } from '../../../../../../_base';
import Preloader from '../../../../../components/ui/Preloader/Preloader';
import { useStylesMyTariff } from '../../hooks/useStyles';
import { tariffOptions } from '../../utils/tariffOptions';
import { getDateStop } from '../../utils/getStopDate';
import TariffModal from './TariffsModal';

interface IProps {
  showBuyModal: (id: number, days: number) => void;
}

const MyTariff: React.FC<IProps> = ({ showBuyModal }) => {
  const classes = useStylesMyTariff();
  const { me, loading } = useSelector(
    ({ profile: { me, loading } }: IAppState) => ({ me, loading }),
    shallowEqual
  );
  const [showModal, setShowModal] = useState(false);
  const { loadingTariffs } = useSelector(
    ({ tariff: { loading } }: IAppState) => ({ loadingTariffs: loading }),
    shallowEqual
  );
  const tariffs = useSelector(({ tariff: { tariffs } }: IAppState) => tariffs, shallowEqual);
  const intl = useIntl();
  const dateStop = useMemo(() => getDateStop(me?.current_user_tariff), [me]);

  //   const getData = useCallback(() => {
  //     dispatch(profileActions.fetchRequest());
  //   }, []);

  const handleClose = useCallback(() => {
    setShowModal(false);
    // update && getData();
  }, []);

  const isPremium = useMemo(() => me?.current_user_tariff?.tariff.name === 'Премиум', [me]);

  //   useEffect(() => {
  //     getData();
  //   }, [getData]);

  if (loading || loadingTariffs || !me) return <Preloader />;

  return (
    <>
      <div className={classes.container}>
        <div className={classes.header}>
          <p className={classes.headerTitle}>
            {me.current_user_tariff?.tariff.name === 'Базовый'
              ? intl.formatMessage({ id: 'TARIFF.EXPIRED' })
              : me.current_user_tariff &&
                `${intl.formatMessage({ id: 'TARIFF.ACTIVE' })} ${dateStop}`}
            {!me.current_user_tariff && intl.formatMessage({ id: 'TARIFF.EXPIRED' })}
          </p>
        </div>
        <div className={classes.content}>
          <div className={classes.tariffInfo}>
            <img
              alt='checked'
              height={23}
              width={23}
              src={toAbsoluteUrl('/media/icons/web-bullet-active-checked.svg')}
            />
            <p className={classes.tariffName}>
              {me.current_user_tariff?.tariff.name === 'Базовый'
                ? intl.formatMessage({ id: 'TARIFF.BASE' })
                : me.current_user_tariff && intl.formatMessage({ id: 'TARIFF.PRIME' })}
              {!me.current_user_tariff && intl.formatMessage({ id: 'TARIFF.BASE' })}
            </p>
          </div>
          <div className={classes.tariffsContainer}>
            <p className={classes.tariffsTitle}>
              {intl.formatMessage({ id: 'TARIFF.ICLUDES' })}
            </p>
            <p className={classes.tariffSubTitle}>
              {intl.formatMessage({ id: 'TARIFF.FEATURES' })}
            </p>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {tariffOptions().map(tariff => (
                <div key={tariff.text} className={classes.optionContainer}>
                  {tariff.isPrime ? (
                    <p
                      className={classes.tariffOption}
                      style={{ color: isPremium ? '#5E6278FF' : '#A2A6B8FF' }}
                    >
                      {tariff.text}
                    </p>
                  ) : (
                    <p className={classes.tariffOption}>{tariff.text}</p>
                  )}
                  <img
                    alt='checked1'
                    className={classes.iconChecked}
                    src={
                      tariff.isPrime
                        ? toAbsoluteUrl(
                            isPremium
                              ? '/media/icons/web-check-circle.svg'
                              : '/media/icons/canceled-circle.svg'
                          )
                        : toAbsoluteUrl('/media/icons/web-check-circle.svg')
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.btnFooterWrapper}>
            <div className={classes.btnFooter} onClick={() => setShowModal(true)}>
              <p className={classes.btnTitle}>{intl.formatMessage({ id: 'TARIFF.CHANGE' })}</p>
            </div>
          </div>
        </div>
      </div>
      <TariffModal
        tariffs={tariffs}
        open={showModal}
        handleClose={handleClose}
        showBuyModal={showBuyModal}
        me={me}
      />
    </>
  );
};

export default MyTariff;
