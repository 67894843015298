import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { TAuthTabs } from '.';
import { useFormikEnterConfirmCode } from '../hooks/useFormikEnterConfirmCode';
import { useAxiosPostCallback } from '../../../hooks/useAxiosGet';
import { useShowErrors } from '../../../hooks/useShowErrors';
import { actions as authActions } from '../../../store/ducks/auth.duck';
import { IMaskPhoneInput } from './CheckInSystem';
import { IAppState } from '../../../store/rootDuck';

interface IProps {
  changeAuthTab: (newTab: TAuthTabs, props?: any) => void;
  countryData: any;
  phone: string;
}

export const EnterConfirmCode: React.FC<IProps> = ({ changeAuthTab, countryData, phone }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginSuccess = useSelector(({ auth }: IAppState) => auth.loginSuccess, shallowEqual);

  const { data: res, loading, err, makeRequest } = useAxiosPostCallback();

  const {
    handleBlur,
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
  } = useFormikEnterConfirmCode(countryData, phone, makeRequest);

  useEffect(() => {
    loginSuccess && navigate('settings/profile');
  }, [loginSuccess]);

  useEffect(() => {
    if (res) {
      dispatch(authActions.loginSuccess(res.data));
    }
  }, [res]);

  useShowErrors([err]);

  return (
    <div className='kt-login__body'>
      <div className='kt-login__form'>
        <div className='kt-login__title'>
          <h3>{intl.formatMessage({ id: 'ENTER_CONFIRM_CODE' })}</h3>
        </div>

        <form noValidate autoComplete='off' className='kt-form' onSubmit={handleSubmit}>
          <div className='form-group'>
            <div className='form-group'>
              <IMaskPhoneInput
                mask={Number}
                onBlur={handleBlur}
                value={values.code}
                onChange={(e: any) => {
                  setFieldValue('code', e.target.value || '');
                }}
                type='tel'
                label={intl.formatMessage({
                  id: 'AUTH.INPUT.CODE',
                })}
                margin='normal'
                className='kt-width-full'
                name='code'
                helperText={touched.code && errors.code}
                error={Boolean(touched.code && errors.code)}
                InputLabelProps={{
                  shrink: !!values.code,
                }}
                InputProps={{
                  inputMode: 'numeric',
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <div className='kt-login__actions' style={{ marginRight: 30 }}>
              <button
                onClick={() => changeAuthTab('CheckInSystem')}
                type='button'
                className='btn btn-secondary btn-elevate kt-login__btn-secondary'
              >
                {intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })}
              </button>
            </div>

            <div className='kt-login__actions'>
              <ButtonWithLoader onPress={handleSubmit} disabled={loading} loading={loading}>
                <FormattedMessage id='AUTH.LOGIN.BUTTON' />
              </ButtonWithLoader>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
