import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useFormatMessage } from '../../../../hooks';
import { deleteProject } from '../../../../crud/projects.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useDeleteProject = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const fm = useFormatMessage();

  const fetchDeleteProject = useCallback(async (id: number) => {
    setLoading(true);
    deleteProject(id)
      .then(() => {
        enqueueSnackbar(fm('PROJECT.DELETED'), { variant: 'success' });
        setSuccess(true);
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setSuccess(false);
        }, 100);
      });
  }, []);

  return { fetchDeleteProject, loading, success };
};
