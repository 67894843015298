import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { makeStyles, createStyles, TextField, Box } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

import ButtonWithLoader from '../../../../components/other/Buttons/ButtonWithLoader';

import { IAppState } from '../../../../store/rootDuck';
import { actions as companiesActions } from '../../../../store/ducks/companies.duck';
import { IUser } from '../../../../interfaces/user';

import homeStyles from '../../../../constants/homeStyles';
import Preloader from '../../../../components/other/Preloader/Preloader';

const useStyles = makeStyles(theme =>
  createStyles({
    actions: {
      marginTop: theme.spacing(3),
    },
    paramContainer: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: 16,
      marginBottom: theme.spacing(2),
    },
    companyContainer: {
      flexDirection: 'row',
      display: 'flex',
    },
    companyText: {
      flex: 1,
    },
  })
);

interface IProps {
  me: IUser;
}

const CompanyForm: React.FC<TPropsFromRedux & IProps> = ({
  page,
  perPage,
  fetchCompanies,
  loading,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const homeClasses = homeStyles();

  useEffect(() => {
    fetchCompanies({ page, perPage });
  }, []);

  if (loading) return <Preloader />;

  return (
    <>
      <Formik
        initialValues={{
          company_name: '',
        }}
        validationSchema={Yup.object().shape({
          company_name: Yup.string()
            .required(intl.formatMessage({ id: 'COMPANY.VALIDATOR.COMPANY' }))
            .nullable(),
        })}
        onSubmit={values => {
          // eslint-disable-next-line no-console
          console.log('---submit', values);
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
          <form className={homeClasses.form} onSubmit={handleSubmit}>
            <Box
              className={classes.paramContainer}
              border={1}
              borderColor='#eeeeee'
              borderRadius={5}
            >
              <div className={classes.title}>
                {`${intl.formatMessage({ id: 'SETTING.PROFILE.COMPANY.TITLE' })}`}
              </div>

              <TextField
                type='text'
                label={intl.formatMessage({ id: 'COMPANY.FORM.COMPANY' })}
                margin='normal'
                name='company_name'
                value={values.company_name}
                fullWidth
                variant='outlined'
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.company_name && errors.company_name}
                error={Boolean(touched.company_name && errors.company_name)}
              />
            </Box>

            <div className={classes.actions}>
              <ButtonWithLoader>
                {intl.formatMessage({ id: 'COMMON.BUTTON.SAVE' })}
              </ButtonWithLoader>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    page: state.companies.page,
    perPage: state.companies.per_page,
    total: state.companies.total,
    companies: state.companies.companies,
    loading: state.companies.loading,
  }),
  {
    fetchCompanies: companiesActions.fetchRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CompanyForm);
