import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { editCompanyTariff } from '../../../../crud/tariffs.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useEditCompanyTariff = () => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [loadingEditCompanyTariff, setLoading] = useState(false);
  const [successEditCompany, setSuccess] = useState(false);
  const editCompanyTariffFetch = useCallback(
    async (id: number, days: number, tariff_id: number) => {
      setLoading(true);
      editCompanyTariff(id, days, tariff_id)
        .then(() => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'COMPANY.TARIFF.CHANGED' })}`, {
            variant: 'success',
          });
          setSuccess(true);
        })
        .catch(e => {
          enqueueSnackbar(`${intl.formatMessage({ id: 'ERROR' })}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        })
        .finally(() => {
          setLoading(false);
          setTimeout(() => {
            setSuccess(false);
          }, 100);
        });
    },
    []
  );

  return {
    editCompanyTariffFetch,
    successEditCompany,
    loadingEditCompanyTariff,
  };
};
