import React, { useState, useEffect } from 'react';
import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import { TextField, CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import { useIntl } from 'react-intl';
import { ILocation } from '../../interfaces/locations';

const LIMIT_INTERVAL = 2000;
// const FIRST_FETCH_SYMBOL = 3;

const innerStyles = makeStyles({
  pulseRoot: {
    '& fieldset': {
      animation: '2000ms ease-in-out infinite both TextFieldBorderPulse',
    },
  },
});

interface IProps {
  id?: string;
  options: ILocation[];
  inputValue: ILocation;
  label: string;
  inputClassName: any;
  editable: boolean;
  loading: boolean;
  inputError: boolean;
  inputHelperText: any;
  disable: boolean;
  handleBlur?: (e: any) => {} | void;
  fetchLocations: (data: { location: string; locale: string }) => {};
  clearLocations: () => {};
  setSelectedLocation: (location: any) => {} | void;
  prompterRunning?: boolean;
  prompterStep?: number;
}

const Autocomplete: React.FC<IProps> = ({
  id = '',
  options,
  inputValue,
  label,
  inputClassName,
  handleBlur,
  editable,
  loading,
  inputError,
  inputHelperText,
  fetchLocations,
  clearLocations,
  setSelectedLocation,
  disable,
  prompterRunning,
  prompterStep,
}) => {
  const [editableLocation, setEditableLocation] = useState(editable);
  const [location, setLocation] = useState<string>('');
  const [fetchedLocation, setFetchedLocation] = useState('');
  const innerClasses = innerStyles();
  const intl = useIntl();

  useEffect(() => {
    clearLocations();
  }, [clearLocations]);

  useEffect(() => {
    // if (!location || location.length === FIRST_FETCH_SYMBOL) {
    if (!location) {
      fetchLocations({ location, locale: intl.locale });
      setFetchedLocation(location);
    }
  }, [fetchLocations, location, intl.locale]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (location !== fetchedLocation) {
        fetchLocations({ location, locale: intl.locale });
        setFetchedLocation(location);
      }
    }, LIMIT_INTERVAL);

    return () => interval && clearInterval(interval);
  }, [location, intl.locale, fetchedLocation, fetchLocations]);

  // useEffect(() => {
  //   setLocation(defaultValue.text);
  // }, [setLocation, defaultValue.text]);
  useEffect(() => {
    if (inputValue.address === '') setEditableLocation(true);
    if (inputValue.address !== '') setEditableLocation(false);
  }, [inputValue, setEditableLocation]);
  return (
    <MaterialAutocomplete
      id={`autocomplete${id}`}
      noOptionsText={intl.formatMessage({ id: 'STORE.INPUT.LOCATION_PLACEHOLDER' })}
      options={options}
      loading={loading}
      getOptionLabel={option => option.address}
      onChange={(e: any, val: any) => {
        if (val) {
          setSelectedLocation(val);
          setEditableLocation(false);
        } else {
          setLocation('');
        }
      }}
      filterOptions={o => o}
      disabled={!editableLocation}
      value={inputValue}
      onInputChange={(_e: any, _val: any, reason: any) => {
        if (reason === 'clear') setSelectedLocation({ address: '' });
      }}
      classes={
        prompterRunning && prompterStep === 0 && !location
          ? { root: innerClasses.pulseRoot }
          : {}
      }
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            type='text'
            margin='normal'
            name='location'
            variant='outlined'
            label={label}
            className={inputClassName}
            onBlur={handleBlur}
            onChange={e => {
              setLocation(e.target.value);
            }}
            helperText={inputHelperText}
            error={inputError}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <PersonPinCircleIcon fontSize='large' color='primary' />
                </>
              ),
              endAdornment: (
                <>
                  {(loading || location !== fetchedLocation) && (
                    <CircularProgress color='inherit' size={20} />
                  )}
                  {!editableLocation && !disable && (
                    <IconButton
                      onClick={() => {
                        setEditableLocation(!editableLocation);
                      }}
                      size='small'
                    >
                      <EditIcon fontSize='small' />
                    </IconButton>
                  )}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default Autocomplete;
