import { useCallback, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';

import { useFormatMessage } from '../../../hooks';
import { actions as authActions } from '../../../store/ducks/auth.duck';
import { getResponseMessage } from '../../../utils/utils';

export const useConfirmCodeEmail = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const fm = useFormatMessage();
  const [loadingEmailCode, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const makeLoginEmailCode = useCallback((email: string, code: string) => {
    setSuccess(false);
    setLoading(true);
    axios
      .post('/api/users/code_login', {}, { params: { email, code } })
      .then(res => {
        dispatch(authActions.loginSuccess(res.data));
      })
      .catch(e => {
        if (e?.response?.status === 403) {
          enqueueSnackbar(`${fm('ERROR')}: ${fm('INVALID_CODE')}`, {
            variant: 'error',
          });
        }else {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, {
            variant: 'error',
          });
        }

      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { makeLoginEmailCode, loadingEmailCode, success };
};
