import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import {
  makeStyles,
  Card,
  TextField,
  Button,
  Tabs,
  Tab,
  DialogProps,
  InputLabel,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

import { TabPanel, a11yProps } from '../../../components/other/Tab/TabPanel';
import ButtonWithLoader from '../../../components/other/Buttons/ButtonWithLoader';
import Preloader from '../../../components/other/Preloader/Preloader';
import { Modal } from '../../../components/other/Modals';
import AlertDialog from '../../../components/other/Dialog/AlertDialog';
import StoresTable from '../../../components/tableComponents/Table/StoresTable';
import UsersTable from '../../../components/tableComponents/Table/UsersTable';

import homeStyles from '../../../constants/homeStyles';
import { IAppState } from '../../../store/rootDuck';
import { actions as companiesActions } from '../../../store/ducks/companies.duck';
import { actions as profileActions } from '../../../store/ducks/profile.duck';
import { actions as storeActions } from '../../../store/ducks/store.duck';
import { actions as usersActions } from '../../../store/ducks/users.duck';

import { setLayoutFooter, setLayoutSubheader } from '../../../utils/layout';
import useCrudSnackbar from '../../../hooks/useCrudSnackbar';
import { ICompany } from '../../../interfaces/companies';
import CompaniesTable from '../../../components/tableComponents/Table/CompaniesTable';
import { IUser } from '../../../interfaces/user';
import { UserListType } from '../users/constants';
import { useUploadLogo } from './hooks/useUploadLogo';
import { useDeleteLogo } from './hooks/useDeleteLogo';
import { useDefineUserRole } from '../../../hooks';
import CkEditorCustom from '../../../components/formComponents/CkEditorCustom';
import { UploadImages } from '../../../components/ui/UploadImages';

const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(3),
  },
  actions: {
    marginTop: theme.spacing(3),
  },
  buttons: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  btnDel: {
    marginLeft: theme.spacing(2),
  },
  delButton: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
  },
  switch: {
    marginLeft: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  managers: {
    minHeight: 55,
    marginTop: 10,
    padding: theme.spacing(1.5),
  },
  managersTitle: {
    marginBottom: 10,
  },
  rootTable: {
    margin: '20px 0',
    minHeight: 200,
    flex: 1,
    padding: '0 10px',
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
      width: '100%',
    },
  },
  table: {
    [theme.breakpoints.down('md')]: {
      overflowX: 'auto',
      overflowY: 'hidden',
      width: '300px',
      whiteSpace: 'nowrap',
      padding: theme.spacing(2),
    },
  },
}));

interface IField<T> {
  title: string;
  field: T;
}

const getInitialValues = (company: ICompany | null) => ({
  name: company ? company.name : '',
  brand: company ? company.brand : '',
  inn: company ? company.inn : '',
  phone_number: company ? company.phone_number : '',
  site: company ? company.site : '',
});

const CompanyEditPage: React.FC<TPropsFromRedux> = ({
  loadingMe,
  me,
  fetchMe,
  company,
  fetch,
  loading,
  clearEdit,
  add,
  edit,
  delCompany,
  clearDelCompany,
  delCompanySuccess,
  delCompanyError,
  delCompanyLoading,
  editSuccess,
  byIdSuccess,
  editLoading,
  editError,
  delStore,
  clearDelStore,
  delStoreSuccess,
  delStoreError,
  searchCompany,
  companies,
  loadingCompanies,
  pageCompanies,
  perPageCompanies,
  totalCompanies,
  users,
  perPageUsers,
  pageUsers,
  loadingUsers,
  totalUsers,
  fetchUsers,
  filter,
  clearPagination,
  clearAddCompanyId,
  addCompanyId,
  addSuccess,
  stores,
  pageStores,
  perPageStores,
  totalStores,
  fetchStores,
  loadingStores,
  clearStores,
  clearFetchById,
  byIdError,
}) => {
  const { companyId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [valueTabs, setValueTabs] = useState(0);
  const [deleteStoreId, setDeleteStoreId] = useState<number | undefined>(-1);
  const [unconnectUserId, setUnconnectUserId] = useState<number | undefined>(-1);
  const [openUsersDialog, setOpenUsersDialog] = useState(false);

  const [isOpenDeleteStoreAlert, setOpenDeleteStoreAlert] = useState(false);
  const [isOpenUnconnectUserAlert, setIsOpenUnconnectUserAlert] = useState(false);

  const [openDialogCompanies, setOpenDialogCompanies] = useState(false);
  const [editorState, setEditorState] = useState<any>(null);
  const [editorStatePayment, setEditorStatePayments] = useState<any>(null);
  const [uploadLogo, loadingLogo, successLogo] = useUploadLogo(companyId);
  const [deleteLogo, loadingDeleteLogo, successDeleteLogo] = useDeleteLogo();
  const isManager = useDefineUserRole(me, 'ROLE_MANAGER');

  const classes = useStyles();
  const homeClasses = homeStyles();
  const navigate = useNavigate();
  const intl = useIntl();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues: getInitialValues(company),
    validationSchema: Yup.object().shape({}),
    onSubmit: submitValues => {
      let description = '';
      let paymentDetails = '';
      if (editorState) {
        description = editorState?.getData() || '';
      }
      if(editorStatePayment) {
        paymentDetails = editorStatePayment?.getData() || '';
      }
      if (companyId) {
        edit({ id: +companyId, data: { ...submitValues, description, payment_details: paymentDetails } });
      } else {
        add({ ...submitValues, description, payment_details: paymentDetails });
      }
    },
  });

  // eslint-disable-next-line no-nested-ternary
  const logoTabValue = useMemo(() => (isManager ? 2 : !me?.is_admin ? 1 : companyId ? 3 : 1), [
    me,
    companyId,
  ]);

  useCrudSnackbar({
    success: false,
    successMessage: '',
    error: byIdError,
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${byIdError}`,
    afterSuccessOrError: () => {
      navigate(-1);
      clearFetchById();
    },
  });

  // For edit actions
  useCrudSnackbar({
    success: editSuccess,
    error: editError,
    successMessage: companyId
      ? intl.formatMessage({ id: 'COMPANY.EDIT.SUCCESS' })
      : intl.formatMessage({ id: 'COMPANY.CREATE.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${editError}`,
    afterSuccessOrError: () => {
      setOpen(false);
    },
    afterSuccess: () => {
      if (!companyId && valueTabs === 0) navigate(-1);
      fetchMe();
    },
  });

  // For delete actions
  useCrudSnackbar({
    success: delCompanySuccess,
    error: delCompanyError,
    clear: clearDelCompany,
    successMessage: intl.formatMessage({ id: 'COMPANY.DELETE.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${delCompanyError}`,
    afterSuccessOrError: () => {
      clearDelCompany();
      setOpen(false);
    },
    afterSuccess: () => {
      navigate(-1);
    },
  });

  // For delete stories
  useCrudSnackbar({
    success: delStoreSuccess,
    error: delStoreError,
    clear: clearDelStore,
    successMessage: intl.formatMessage({ id: 'STORE.DELETE.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${delStoreError}`,
    afterSuccess: () => {
      if (companyId) {
        fetch(+companyId);
        fetchStores({ page: pageStores, perPage: perPageStores, companyId: +companyId });
      }
    },
    afterSuccessOrError: () => {
      clearDelStore();
    },
  });

  useEffect(() => {
    fetchMe();
    return () => {
      clearEdit();
      clearStores();
      clearFetchById();
    };
  }, []);

  useEffect(() => {
    if (addSuccess && addCompanyId) {
      if (valueTabs !== 0) {
        navigate(`/companies/edit/${addCompanyId}`, { replace: true });
      }
    } else if (successLogo || successDeleteLogo) {
      companyId && fetch(+companyId);
    }
  }, [successLogo, successDeleteLogo, addSuccess, addCompanyId]);

  useEffect(() => {
    if (addCompanyId && companyId) {
      clearAddCompanyId();
    }
  }, [addCompanyId, companyId]);

  useEffect(() => {
    editError && valueTabs !== 0 && setValueTabs(0);
  }, [editError]);

  const handleChangeTabPhoto = useCallback(
    (newValue: number) => {
      handleSubmit();
      setValueTabs(newValue);
    },
    [handleSubmit, setValueTabs]
  );

  useEffect(() => {
    if (
      me &&
      !me.is_admin &&
      editError &&
      editError === intl.formatMessage({ id: 'COMPANY.ERROR.UNIQUEINN' }) &&
      !companyId
    ) {
      searchCompany({ page: 1, perPage: 20, inn: values.inn });
      setOpenDialogCompanies(true);
    }
  }, [me, editError, companyId, addCompanyId, addSuccess]);

  useEffect(() => {
    if (companyId) {
      fetch(+companyId);
    }
  }, [fetch, companyId]);

  useEffect(() => {
    if (companyId) {
      fetchStores({ page: pageStores, perPage: perPageStores, companyId: +companyId });
    }
  }, [fetchStores, perPageStores, pageStores, me, companyId]);

  setLayoutSubheader({
    title: companyId
      ? `${intl.formatMessage({ id: 'COMPANY.EDIT.TITLE' })}`
      : `${intl.formatMessage({ id: 'COMPANY.ADD.TITLE' })}`,
    breadcrumb: [
      {
        title: intl.formatMessage({ id: 'SUBMENU.COMPANIES' }),
        root: true,
        page: 'companies/list',
        translate: 'SUBMENU.COMPANIES',
        click: () => clearPagination(),
      },
    ],
  });
  setLayoutFooter({ show: true });

  useEffect(() => {
    if (byIdSuccess && company) {
      setValues(getInitialValues(company));
    }
  }, [byIdSuccess, company, setValues]);

  useEffect(() => {
    if (loadingMe) return;

    if (me && !isManager && !me?.is_admin && !me?.company && companyId) {
      navigate('/companies/list');
    }
  }, [me, loadingMe]);

  useEffect(() => {
    if (byIdSuccess && company) {
      setValues(getInitialValues(company));
    }
  }, [byIdSuccess, company, setValues]);

  const handleDeleteDialog = useCallback((id: number) => {
    setDeleteStoreId(id);
    setOpenDeleteStoreAlert(true);
  }, []);

  const handleUnConnectUserDialog = useCallback((id: number) => {
    setIsOpenUnconnectUserAlert(true);
    setUnconnectUserId(id);
  }, []);

  const handleUnConnectUser = useCallback(() => {
    setIsOpenUnconnectUserAlert(false);

    companyId &&
      edit({
        id: +companyId,
        data: {
          managers_ids: company?.managers
            .filter(manager => manager.id !== unconnectUserId)
            .map(i => i.id),
        },
      });
    setUnconnectUserId(-1);
  }, [companyId, company, unconnectUserId]);

  const deleteStoreAction = useCallback(() => {
    setOpenDeleteStoreAlert(false);
    if (deleteStoreId) {
      delStore({ id: deleteStoreId, page: 1, perPage: 10 });
    }
  }, [deleteStoreId, delStore]);

  const handleOpenUsersDialog = useCallback(() => {
    fetchUsers({ per_page: perPageUsers, page: pageUsers, roles: 'ROLE_VENDOR', filter });
    setOpenUsersDialog(true);
  }, [perPageUsers, pageUsers, filter]);

  const handleConnectUser = useCallback(
    (userId?: number, companyProp?: ICompany) => {
      const managers = companyProp?.managers ? companyProp?.managers.map(i => i.id) : [];
      companyId &&
        edit({
          id: companyProp ? companyProp.id : +companyId,
          data: {
            managers_ids: [...managers, userId || 0],
          },
        });
      setOpenUsersDialog(false);
    },
    [company?.managers, companyId]
  );

  if (loading || loadingMe || loadingStores) return <Preloader />;

  const FIELDS: IField<keyof typeof values>[] = [
    { title: intl.formatMessage({ id: 'COMPANY.FORM.LEGAL_PERSON' }), field: 'name' },
    { title: intl.formatMessage({ id: 'COMPANY.FORM.BRAND' }), field: 'brand' },
    { title: intl.formatMessage({ id: 'COMPANY.FORM.INN' }), field: 'inn' },
    { title: intl.formatMessage({ id: 'COMPANY.FORM.PHONE' }), field: 'phone_number' },
    { title: intl.formatMessage({ id: 'COMPANY.FORM.SITE' }), field: 'site' },
  ];

  return (
    <>
      <AlertDialog
        open={isOpenDeleteStoreAlert}
        message={intl.formatMessage({ id: 'STORE.DELETE.TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          clearDelStore();
          setOpenDeleteStoreAlert(false);
        }}
        handleAgree={deleteStoreAction}
      />

      <AlertDialog
        open={isOpenUnconnectUserAlert}
        message={intl.formatMessage({ id: 'COMPANY.EDIT.UNMOUNT_USER_TEXT' })}
        okText={intl.formatMessage({ id: 'CATEGORIES.DELETE.OK' })}
        cancelText={intl.formatMessage({ id: 'CATEGORIES.DELETE.CANCEL' })}
        handleClose={() => {
          setIsOpenUnconnectUserAlert(false);
          setUnconnectUserId(-1);
        }}
        handleAgree={handleUnConnectUser}
      />
      <Row>
        <Col>
          <Card className={homeClasses.container}>
            <>
              <Tabs
                value={valueTabs}
                onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
                  if (newValue !== 0 && !companyId) {
                    handleChangeTabPhoto(newValue);
                  } else {
                    setValueTabs(newValue);
                  }
                }}
                variant='scrollable'
                indicatorColor='primary'
                textColor='primary'
                aria-label='tabs'
              >
                <Tab
                  label={intl.formatMessage({ id: 'MENU.COMPANY' })}
                  {...a11yProps(0)}
                />
                {(me?.is_admin || isManager) && (
                  <Tab
                    label={intl.formatMessage({ id: 'COMPANY.EDIT.TAB_STORES' })}
                    {...a11yProps(1)}
                  />
                )}
                {me?.is_admin && (
                  <Tab
                    label={intl.formatMessage({ id: 'COMPANY.EDIT.TAB_MANAGERS' })}
                    {...a11yProps(2)}
                  />
                )}
                <Tab
                  label={intl.formatMessage({ id: 'COMPANY.TAB.LOGO' })}
                  {...a11yProps(3)}
                />
              </Tabs>
              {valueTabs === 0 && (
                <form className={homeClasses.form} onSubmit={handleSubmit} autoComplete='off'>
                  {FIELDS.map(item => (
                    <TextField
                      key={item.field}
                      type='text'
                      label={item.title}
                      margin='normal'
                      name={item.field}
                      value={values[item.field]}
                      variant='outlined'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched[item.field] && errors[item.field]}
                      error={Boolean(touched[item.field] && errors[item.field])}
                    />
                  ))}

                  <div style={{ marginTop: 10 }}>
                    <InputLabel shrink>
                      {intl.formatMessage({ id: 'PRODUCT.TABLE.DESCRIPTION' })}
                    </InputLabel>
                    <CkEditorCustom
                      html={company?.description || ''}
                      setEditorStateCallback={setEditorState}
                    />
                  </div>

                  <div style={{ marginTop: 10 }}>
                    <InputLabel shrink>
                      {intl.formatMessage({ id: 'PRODUCT.TABLE.PAYMENT' })}
                    </InputLabel>
                    <CkEditorCustom
                      html={company?.payment_details || ''}
                      setEditorStateCallback={setEditorStatePayments}
                    />
                  </div>

                  <div className={classes.actions}>
                    <Button
                      onClick={() => navigate(-1)}
                      className={classes.buttons}
                      variant='outlined'
                      color='primary'
                    >
                      {intl.formatMessage({ id: 'COMMON.BUTTON.BACK' })}
                    </Button>

                    <ButtonWithLoader disabled={editLoading} loading={editLoading}>
                      {companyId
                        ? intl.formatMessage({ id: 'USER.BUTTON.EDIT' })
                        : intl.formatMessage({ id: 'USER.BUTTON.ADD' })}
                    </ButtonWithLoader>

                    {companyId && me?.is_admin && (
                      <Button
                        onClick={() => setOpen(true)}
                        className={classes.btnDel}
                        variant='outlined'
                        color='secondary'
                      >
                        {intl.formatMessage({ id: 'COMMON.BUTTON.DELETE' })}
                      </Button>
                    )}

                    {companyId && me && me?.is_admin && (
                      <Button
                        onClick={() => handleUnConnectUserDialog(me.id)}
                        className={classes.delButton}
                        variant='outlined'
                        color='secondary'
                      >
                        {intl.formatMessage({ id: 'COMPANY.BUTTON.UNMOUNT_FROM_COMPANY' })}
                      </Button>
                    )}
                  </div>
                </form>
              )}

              <>
                {(me?.is_admin || isManager) && (
                  <TabPanel value={valueTabs} index={1}>
                    <div className={classes.table}>
                      <div className='actions'>
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() =>
                            navigate(`/stores/create/${companyId}/${company?.name}`)
                          }
                        >
                          {intl.formatMessage({ id: 'STORE.BUTTON.ADD' })}
                        </Button>
                      </div>

                      <div>
                        <StoresTable
                          stores={stores || []}
                          loading={loadingStores}
                          paginationData={{
                            page: pageStores,
                            perPage: perPageStores,
                            total: totalStores,
                          }}
                          onEdit={storeId => navigate(`/stores/edit/${storeId}`)}
                          onDelete={storeId => handleDeleteDialog(storeId)}
                          fetch={({ page, perPage }) =>
                            companyId && fetchStores({ page, perPage, companyId: +companyId })
                          }
                        />
                      </div>
                    </div>
                  </TabPanel>
                )}
                {me?.is_admin && (
                  <TabPanel value={valueTabs} index={2}>
                    <div className={classes.table}>
                      <div className={classes.rootTable}>
                        <div className='actions'>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={handleOpenUsersDialog}
                          >
                            {intl.formatMessage({ id: 'MANAGER.BUTTON.ADD' })}
                          </Button>
                        </div>

                        <UsersTable
                          users={company?.managers || []}
                          loading={loading}
                          paginationData={{ page: 1, perPage: 10, total: 10 }}
                          onEdit={userId =>
                            navigate(`/users/edit/${UserListType.MANAGER}/${userId}`)
                          }
                          onConnectOff={userId => handleUnConnectUserDialog(userId)}
                        />
                      </div>
                    </div>
                  </TabPanel>
                )}
              </>
              <TabPanel value={valueTabs} index={logoTabValue}>
                <UploadImages
                  // @ts-ignore
                  images={
                    company?.logo
                      ? [
                          {
                            id: 1,
                            path:
                              typeof company.logo === 'string'
                                ? company.logo
                                : company.logo.path,
                            main: true,
                            name: 'avatar',
                            extension: '.jpg',
                            mime_type:
                              typeof company.logo === 'string'
                                ? company.logo
                                : company.logo.path,
                            small: '',
                            path_blob: '',
                          },
                        ]
                      : undefined
                  }
                  uploadImages={uploadLogo}
                  withGallery
                  withCrop
                  cropAspect={1}
                  deleteImage={() => deleteLogo(companyId)}
                />
              </TabPanel>
            </>
          </Card>
        </Col>
      </Row>

      <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={openUsersDialog}
        onClose={() => setOpenUsersDialog(false)}
        title={intl.formatMessage({ id: 'COMPANY.EDIT.MOUNT_USER_TITLE' })}
        loading={loadingUsers}
        content={
          <UsersTable
            loading={loadingUsers}
            paginationData={{ page: pageUsers, perPage: perPageUsers, total: totalUsers }}
            onEdit={userId => navigate(`/users/edit/${UserListType.MANAGER}/${userId}`)}
            onConnect={userId => handleConnectUser(userId, company || undefined)}
            onConnectOff={userId => handleUnConnectUserDialog(userId)}
            connectedUsers={company?.managers.map(item => item.id)}
            users={users}
            fetch={({ page, perPage }) =>
              fetchUsers({ per_page: perPage, page, roles: 'ROLE_VENDOR', filter })
            }
          />
        }
        actions={[
          {
            title: intl.formatMessage({ id: 'COMMON.BUTTON.CLOSE' }),
            onClick: () => setOpenUsersDialog(false),
          },
        ]}
      />

      <Modal
        open={open}
        onClose={delCompanyLoading ? () => {} : () => setOpen(false)}
        title={intl.formatMessage({ id: 'COMPANY.DELETE.TITLE' })}
        loading={delCompanyLoading}
        content={
          delCompanyLoading ? undefined : intl.formatMessage({ id: 'COMPANY.DELETE.TEXT' })
        }
        actions={
          delCompanyLoading
            ? undefined
            : [
                {
                  title: intl.formatMessage({ id: 'COMMON.BUTTON.CANCEL' }),
                  onClick: () => setOpen(false),
                },
                {
                  title: intl.formatMessage({ id: 'COMMON.BUTTON.DELETE' }),
                  onClick: () => companyId && delCompany(+companyId),
                },
              ]
        }
      />

      <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={openDialogCompanies}
        onClose={editLoading ? () => {} : () => setOpenDialogCompanies(false)}
        title={intl.formatMessage({ id: 'COMPANY.EDIT.MOUNT_TITLE' })}
        loading={editLoading || loadingCompanies}
        content={
          <CompaniesTable
            me={me as IUser}
            page={pageCompanies}
            perPage={perPageCompanies}
            total={totalCompanies}
            onConnectUser={companyProp => handleConnectUser(me?.id, companyProp)}
            companies={companies}
          />
        }
        actions={[
          {
            title: intl.formatMessage({ id: 'COMMON.BUTTON.CLOSE' }),
            onClick: () => setOpenDialogCompanies(false),
          },
        ]}
      />
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    loadingMe: state.profile.loading,

    company: state.companies.company,
    loading: state.companies.byIdLoading,
    editSuccess: state.companies.editSuccess,
    editLoading: state.companies.editLoading,
    editError: state.companies.editError,
    byIdSuccess: state.companies.byIdSuccess,
    delCompanyLoading: state.companies.delCompanyLoading,
    delCompanySuccess: state.companies.delCompanySuccess,
    delCompanyError: state.companies.delCompanyError,

    delStoreSuccess: state.stores.delSuccess,
    delStoreError: state.stores.delError,
    delStoreLoding: state.stores.delLoading,
    editStoreSucces: state.stores.editSuccess,
    editStoreError: state.stores.editError,

    companies: state.companies.companies,
    loadingCompanies: state.companies.searchLoading,
    pageCompanies: state.companies.page,
    perPageCompanies: state.companies.per_page,
    totalCompanies: state.companies.total,
    byIdError: state.companies.byIdError,

    users: state.users.users,
    loadingUsers: state.users.loading,
    perPageUsers: state.users.per_page,
    pageUsers: state.users.page,
    totalUsers: state.users.total,
    filter: state.users.filter,
    addSuccess: state.companies.addSuccess,
    addCompanyId: state.companies.addCompanyId,
    stores: state.stores.stores,
    loadingStores: state.stores.loading,
    pageStores: state.stores.page,
    perPageStores: state.stores.per_page,
    totalStores: state.stores.total,
  }),
  {
    fetchMe: profileActions.fetchRequest,

    fetch: companiesActions.fetchByIdRequest,
    clearEdit: companiesActions.clearEdit,
    clearDelCompany: companiesActions.clearDelCompany,
    add: companiesActions.addRequest,
    edit: companiesActions.editRequest,
    delCompany: companiesActions.delCompanyRequest,
    clearFetch: companiesActions.clearFetch,
    searchCompany: companiesActions.searchRequest,

    delStore: storeActions.delRequest,
    clearDelStore: storeActions.clearDel,

    fetchUsers: usersActions.fetchRequest,
    clearPagination: companiesActions.clearPagination,
    clearAddCompanyId: companiesActions.clearAddCompanyId,
    fetchStores: storeActions.fetchRequest,
    clearStores: storeActions.clearStores,
    clearFetchById: companiesActions.clearFetchById,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CompanyEditPage);
