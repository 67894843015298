import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ProfileEditPage from './profile/ProfileEditPage';
import InvoicePage from './invoice';

export default function SettingsProfilePage() {
  return (
    <Routes>
      <Route path='profile' element={<ProfileEditPage />} />
      <Route path='invoice' element={<InvoicePage />} />
    </Routes>
  );
}
