import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useIntl } from 'react-intl';
import { CircularProgress, TextField } from '@material-ui/core';

type TProps = {
  options: any[];
  value: any;
  setValue: (item: any) => void;
  getOptionLabel: (item: any) => string;
  isLoading: boolean;
  label?: string;
  disabled?: boolean;
  type?: React.InputHTMLAttributes<unknown>['type'];
  offMargin?: boolean;
};

const AutocompleteCustom: React.FC<TProps> = ({
  options,
  value,
  setValue,
  isLoading,
  label,
  disabled,
  getOptionLabel,
  offMargin,
}) => {
  const intl = useIntl();

  return (
    <Autocomplete
      options={options}
      autoHighlight
      disabled={disabled}
      style={{ marginTop: offMargin ? 0 : 16 }}
      value={value}
      loading={isLoading}
      getOptionLabel={getOptionLabel}
      onChange={(_event: any, newValue) => {
        newValue && setValue(newValue);
      }}
      // groupBy={i => intl.formatMessage({id: i.favorite ? 'FAVORITES' : 'OTHER'})}
      noOptionsText={intl.formatMessage({ id: 'CATEGORIES.TABLE.EMPTY' })}
      loadingText={intl.formatMessage({ id: 'COMMON.LOADING' })}
      disableClearable
      renderInput={params => (
        <TextField
          {...params}
          type='text'
          margin='normal'
          name='location'
          variant='outlined'
          label={label}
          style={{ margin: 0 }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <CircularProgress color='inherit' size={20} />}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteCustom;
