import React from 'react';
import { Card, Dialog, Typography, Button } from '@material-ui/core';

import { ITariff } from '../../../../../../interfaces/tariff';
import { useStylesDialogTariffs } from '../../../hooks/useStyles';
import { useFormatMessage } from '../../../../../../hooks';

interface IProps {
  handlePay: (item: ITariff, days: number) => void;
  showDialog: ITariff | null;
  hideModal: () => void;
  isVendor?: boolean;
}

const DialogTariff: React.FC<IProps> = ({ handlePay, showDialog, hideModal, isVendor }) => {
  const classes = useStylesDialogTariffs();
  const fm = useFormatMessage();

  if (!showDialog) return <></>;

  return (
    <Dialog
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      open={!!showDialog}
      onClose={hideModal}
    >
      <div className={classes.wrapperContent}>
        <div className={classes.wrapperCards}>
          <Card className={classes.card}>
            <div className={classes.wrapperText}>
              <Typography className={classes.textStyle} variant='h6'>
                {fm(showDialog.id === 7 ? 'TARIFF.BASE' : 'TARIFF.PRIME')}
              </Typography>
              <Typography className={classes.textStyle} variant='h6'>
                {fm('TARIFF.COST.THIRTY.DAYS')}
              </Typography>
              <Typography className={classes.textStyle} variant='h6'>
                {showDialog.price30days} czk
              </Typography>
            </div>
            <hr />
            <div className={classes.wrapperBtn}>
              <Button className={classes.btnPick} onClick={() => handlePay(showDialog, 30)}>
                {fm(isVendor ? 'PAY' : 'TARIFFS.CHOOSE')}
              </Button>
            </div>
          </Card>
          <Card className={classes.card}>
            <div className={classes.wrapperText}>
              <Typography className={classes.textStyle} variant='h6'>
                {fm(showDialog.id === 7 ? 'TARIFF.BASE' : 'TARIFF.PRIME')}
              </Typography>
              <Typography className={classes.textStyle} variant='h6'>
                {fm('TARIFF.COST.HUNDREDTWENTY.DAYS')}
              </Typography>
              <Typography className={classes.textStyle} variant='h6'>
                {showDialog.price120days} czk
              </Typography>
            </div>
            <hr />
            <div className={classes.wrapperBtn}>
              <Button className={classes.btnPick} onClick={() => handlePay(showDialog, 120)}>
                {fm(isVendor ? 'PAY' : 'TARIFFS.CHOOSE')}
              </Button>
            </div>
          </Card>
          <Card className={classes.card}>
            <div className={classes.wrapperText}>
              <Typography className={classes.textStyle} variant='h6'>
                {fm(showDialog.id === 7 ? 'TARIFF.BASE' : 'TARIFF.PRIME')}
              </Typography>
              <Typography className={classes.textStyle} variant='h6'>
                {fm('TARIFF.COST.YEAR')}
              </Typography>
              <Typography className={classes.textStyle} variant='h6'>
                {showDialog.price365days} czk
              </Typography>
            </div>
            <hr />
            <div className={classes.wrapperBtn}>
              <Button className={classes.btnPick} onClick={() => handlePay(showDialog, 365)}>
                {fm(isVendor ? 'PAY' : 'TARIFFS.CHOOSE')}
              </Button>
            </div>
          </Card>
        </div>
        <div className={classes.wrapperBtnPay}>
          <Button className={classes.btnPay} onClick={hideModal}>
            {fm('TARIFF.CANCEL')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DialogTariff;
