import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useFormatMessage } from '../../../../hooks';
import { IInvoice } from '../../../../interfaces/projects';
import { getInvoices } from '../../../../crud/projects.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useSearchInvoices = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const fm = useFormatMessage();

  const fetchSearchInvoices = useCallback(async (id: number) => {
    setLoading(true);
    getInvoices(1, 100, undefined, id)
      .then(res => {
        setInvoices(res.data.data);
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { fetchSearchInvoices, loading, invoices };
};
