import { useFormik } from 'formik';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

export const useFormikCheckInSystem = (
  initEmail: string,
  // initPhone: string,
  // makeRequest: any,
  // tab: number,
  // // countryData: any
  makeLoginEmail: (email: string) => void
) => {
  const intl = useIntl();
  // const validPhoneLength = useMemo(() => {
  //   if (!countryData) return null;
  //   return countLettersInString(countryData.mask, '*');
  // }, [countryData]);

  const validationSchema = useMemo(() => {
    // let shape = null;
    // if (tab === 0) {
    //   shape = {
    //     email: Yup.string().required(
    //       intl.formatMessage({ id: 'PROFILE.VALIDATION.REQUIRED_FIELD' })
    //     ),
    //   };
    // } else {
    //   shape = {
    //     phone: Yup.string()
    //       .test('check if phone length valid', intl.formatMessage({ id: 'NOT_VALID_PHONE' }), value => {
    //         if (!value) return false;
    //         if (value && validPhoneLength && value.length !== validPhoneLength) {
    //           return false;
    //         }
    //         return true;
    //       })
    //       .required(intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })),
    //   };
    // }
    return Yup.object().shape({
      email: Yup.string()
        .required(intl.formatMessage({ id: 'PROFILE.VALIDATION.REQUIRED_FIELD' }))
        .email(intl.formatMessage({ id: 'USER.EDIT.EMAIL.INCORRECT' }))
        .nullable(),
      acceptTerms: Yup.boolean().oneOf([true], 'Must Accept Terms and Conditions'),
    });
  }, [intl]);

  const formik = useFormik({
    enableReinitialize: true,
    // validateOnChange: false,
    // validateOnBlur: false,
    initialValues: {
      email: initEmail,
      acceptTerms: true,
      // phone: initPhone,
    },
    validationSchema,
    onSubmit: submitValues => {
      makeLoginEmail(submitValues.email);
      // makeRequest({ ...submitValues, phone: countryData.code + submitValues.phone }, tab);
    },
  });

  // clear phone value when country changed
  // useEffect(() => {
  //   formik.setFieldValue('phone', '');
  // }, [countryData]);

  // перевод errors.email при переключении языка
  useEffect(() => {
    if (formik.values.email === '') formik.setFieldValue('email', '');
  }, [intl]);

  return formik;
};
