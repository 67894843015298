import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useFormatMessage } from '../../../../../hooks';
import { editInvoice } from '../../../../../crud/projects.crud';
import { getResponseMessage } from '../../../../../utils/utils';

export const useEditInvoice = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const fm = useFormatMessage();

  const fetchEditInvoice = useCallback(async (data: FormData) => {
    setLoading(true);
    editInvoice(data)
      .then(() => {
        setSuccess(true);
        enqueueSnackbar(`${fm('PAYMENT.SUCCESS.SAVED')}`, { variant: 'success' });
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
        setTimeout(() => {
          setSuccess(false);
        }, 100);
      });
  }, []);

  return { fetchEditInvoice, loading, success };
};
