import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useFormatMessage } from '../../../../../hooks';
import { getInvoice } from '../../../../../crud/projects.crud';
import { getResponseMessage } from '../../../../../utils/utils';
import { IInvoicePayment } from '../../../../../interfaces/projects';

export const useGetInvoice = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState<IInvoicePayment | null>(null);
  const fm = useFormatMessage();

  const fetchGetInvoice = useCallback(async () => {
    setLoading(true);
    getInvoice()
      .then(res => {
        setInvoice(res.data.data);
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { fetchGetInvoice, loading, invoice };
};
