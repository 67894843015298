import { createStyles, makeStyles } from '@material-ui/core';

export const useStylesTableProfile = makeStyles(theme => ({
  table: {
    flex: 1,
    backgroundColor: '#fff',
    overflowX: 'auto',
  },
  blackCell: {
    backgroundColor: 'rgba(150, 150, 150, 0.4)',
  },
  grayCell: {
    backgroundColor: 'rgba(150, 150, 150, 0.2)',
  },
  titleCell: {
    color: '#fff',
    width: '300px',
  },
  freeCell: {
    backgroundColor: '#666666',
    color: '#fff',
  },
  proCell: {
    backgroundColor: theme.palette.success.dark,
    color: '#fff',
  },
  tariffCell: {
    width: '200px',
  },
  button: {
    width: '100%',
  },
  freeButtonCell: {
    width: '200px',
    backgroundColor: 'rgba(150, 150, 150, 0.2)',
  },
  freelancerProCell: {
    width: '200px',
    backgroundColor: theme.palette.secondary.main,
  },
  providerProCell: {
    width: '200px',
    backgroundColor: theme.palette.primary.main,
  },

  tableCell: {
    backgroundColor: '#F2F3F8FF',
    color: 'black',
    // fontSize: 14,
    // fontWeight: 'bold',
    width: '200px',
  },

  tableFirstCell: {
    backgroundColor: '#F2F3F8FF',
    color: '#737577FF',
    fontSize: 12,
    // fontWeight: 'bold',
    width: '100px',
  },

  wrapperContent: {
    margin: 40,
  },

  fontText: {
    fontWeight: 'bold',
  },

  wrapperTable: {
    marginTop: 40,
    marginBottom: 40,
  },

  cardContent: {
    overflowX: 'auto',
    padding: 0,
  },

  btnPay: {
    backgroundColor: 'white',
    color: '#5D78FFFF',
    width: '100%',
    '&:hover': {
      backgroundColor: 'white',
    },
  },

  btnPayFree: {
    backgroundColor: '#5D78FFFF',
    color: 'white',
    width: '100%',
    '&:hover': {
      backgroundColor: '#5D78FFFF',
    },
  },
}));

export const useStylesBlock = makeStyles(theme => ({
  blackCell: {
    backgroundColor: 'rgba(150, 150, 150, 0.4)',
  },
  titleCell: {
    width: '100px',
    textAlign: 'center',
    backgroundColor: '#F2F3F8FF',
    color: 'black',
  },
  tariffCell: {
    width: '200px',
  },
  freeCell: {
    width: '200px',
    backgroundColor: '#F7F5F0',
  },
  freelancerProCell: {
    width: '200px',
    backgroundColor: theme.palette.secondary.main,
    color: '#fff',
  },
  providerProCell: {
    width: '200px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  tableCell: {
    backgroundColor: 'white',
    color: 'black',
  },
}));

export const useStylesEditProfile = makeStyles(() => ({
  wrapperForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export const useStylesDialogTariffs = makeStyles(() => ({
  wrapperContent: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
  wrapperCards: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  card: {
    minHeight: 180,
    margin: 8,
  },
  wrapperText: {
    paddingTop: 10,
    paddingLeft: 14,
    paddingRight: 14,
  },
  textStyle: {
    marginTop: 8,
  },
  wrapperBtn: {
    paddingLeft: 14,
    paddingRight: 14,
  },

  btnPick: {
    backgroundColor: '#5872F2FF',
    color: 'white',
    width: '100%',
    '&:hover': {
      backgroundColor: '#5872F2FF',
    },
  },
  wrapperBtnPay: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: 10,
  },
  btnPay: {
    backgroundColor: 'none',
    color: '#5872F2FF',
  },
}));

export const useStylesProfileForm = makeStyles(theme =>
  createStyles({
    actions: {
      marginTop: theme.spacing(3),
    },
    lang: {
      display: 'flex',
      alignItems: 'center',
    },
    langImage: {
      width: '30px',
      height: '30px',
      borderRadius: '5px',
      overflow: 'hidden',
    },
    langTitle: {
      marginLeft: '5px',
    },
  })
);
