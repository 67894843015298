import axios from 'axios';
import { IProjectSearchParams } from '../interfaces/projects';

export const getProjects = (page: number, per_page: number, user_id?: number) =>
  axios.get('/api/companies', {
    params: {
      page,
      per_page,
      user_id,
    },
  });

export const getProjectsSearch = (params: IProjectSearchParams) =>
  axios.get('/api/companies/search', {
    params,
  });

export const getProject = (id: number) => axios.get(`/api/company/${id}`);

export const addProject = (data: any) => axios.post('/api/company', data);

export const editProject = (id: number, data: any) => axios.put(`/api/company/${id}`, data);

export const deleteProject = (id: number) => axios.delete(`/api/company/${id}`);

export const getUsersProject = (page: number, per_page: number) =>
  axios.get('/api/users', { params: { page, per_page, roles: 'ROLE_VENDOR' } });

export const createInvoice = (company_id: number, tariff_id: number, days: number) =>
  axios.post(`/api/invoice`, {}, { params: { company_id, tariff_id, days } });

export const getInvoices = (
  page: number,
  per_page: number,
  status?: 'not_paid' | 'paid' | 'archived' | 'all',
  id?: number
) =>
  axios.get(`/api/invoices`, {
    params: { page, per_page, status: status === 'all' ? undefined : status, id },
  });

export const payInvoice = (id: number) => axios.put(`/api/invoice_paid/${id}`);

export const getInvoice = () => axios.get('/api/invoice/settings');

export const editInvoice = (data: FormData) =>
  axios.post('/api/invoice/settings', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
