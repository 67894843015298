import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import {
  Card,
  TextField,
  Button,
  MenuItem,
  // IconButton,
  DialogProps,
  Tabs,
  Tab,
  InputAdornment,
} from '@material-ui/core';
import { useIntl } from 'react-intl';

// import DeleteIcon from '@material-ui/icons/Delete';
// import SearchIcon from '@material-ui/icons/Search';
import ButtonWithLoader from '../../../../components/other/Buttons/ButtonWithLoader';
import Preloader from '../../../../components/other/Preloader/Preloader';
import { Modal } from '../../../../components/other/Modals';
import CompaniesTable from '../../../../components/tableComponents/Table/CompaniesTable';
import { IAppState } from '../../../../store/rootDuck';
import { actions as categoriesActions } from '../../../../store/ducks/categories.duck';
import { actions as usersActions } from '../../../../store/ducks/users.duck';
import { actions as companiesActions } from '../../../../store/ducks/companies.duck';
import { actions as profileActions } from '../../../../store/ducks/profile.duck';
import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';
import { usersBreadCrumbs } from './utils/createBreadCrumbs';
import { IUser, UserRoles } from '../../../../interfaces/user';
import homeStyles from '../../../../constants/homeStyles';
import { ICompany } from '../../../../interfaces/companies';
import { useStylesEditPage } from './hooks/useStyleEditPage';
import {
  useDefineUserRole,
  useFormatMessage,
  useShowErrors,
  useShowSuccesses,
} from '../../../../hooks';
// import OrdersList from '../../orders/components/OrdersList';
// import { UserDiscountsStats } from './components/UserDiscountsStats';
import { useFormikUserEdit } from './hooks/useFormikUserEdit';
// import { useUserAttributes } from './hooks/getUserAttributes';
// import { useSelectStyle } from '../../../../hooks/useSelectStyle';
// import { UploadImages } from '../../../../components/ui/UploadImages';
// import { handleUploadAvatar } from './hooks/handleUploadAvatar';
// import MultiSelect from '../../../../components/ui/MultiSelect';
import { useAxiosGetCallback } from '../../../../hooks/useAxiosGet';
import { useHandleCountrySelect } from '../../../auth/hooks/useHandleCountrySelect';
// import SelectField from '../../../../components/ui/SelectField';
// import { useDeletePhotoUser } from './hooks/useDeleteUserPhoto';
import { UserListType } from '../constants';
// import BonusesUser from './components/BonusesUser';
// import { useAddBonusesUser } from './hooks/useAddBonusesUser';
import { getRole } from './utils';
// import { createChildrenList } from '../../../../utils/createParentList';
import { IMaskPhoneInput } from '../../../auth/components/CheckInSystem';
import CountrySelect from '../../../../components/ui/CountrySelect';
// import PushUser from './components/PushUser';
// import { useAddDeviceUser } from './hooks/useAddDeviceUser';
// import { usePushUser } from './hooks/usePushUser';

const UsersEditPage: React.FC<TPropsFromRedux> = ({
  me,
  user,
  fetchMe,
  fetch,
  clearFetchUser,
  loading,
  clearEdit,
  add,
  edit,
  delUser,
  clearDelUser,
  delUserSuccess,
  delUserError,
  delUserLoading,
  editSuccess,
  editLoading,
  editError,
  editCompany,
  editCompanySuccess,
  editCompanyError,
  editCompanyLoading,
  // searchCompany,
  fetchCompanies,
  loadingSearchCompanies,
  companies,
  pageCompanies,
  perPageCompanies,
  totalCompanies,
  loadingCompanies,
  clearEditCompany,
  clearFetchCompanies,
  clearSearchCompany,
  byIdError,
  companiesErr,
  companiesSearchErr,
  clearPagination,
  addSuccess,
  addUserId,
  clearAddUserId,
  // company,
  fetchCompany,
  // categories,
  fetchCategories,
}) => {
  const { userId, type } = useParams();
  const id = userId;
  const [open, setOpen] = useState(false);
  const [openDialogCompanies, setOpenDialogCompanies] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const fm = useFormatMessage();
  const role = getRole(type as UserListType);
  // const selectStyle = useSelectStyle();
  // const isRoleManager = useDefineUserRole(me, 'ROLE_MANAGER');
  const isVendorStaff = useDefineUserRole(user, 'ROLE_VENDOR_STAFF');
  const userRoleAdmin = useDefineUserRole(user, 'ROLE_ADMIN');
  const userRoleVendor = useDefineUserRole(user, 'ROLE_VENDOR');
  // const visibleBonuses = useMemo(() => {
  //   if (isVendorStaff || role === 'ROLE_VENDOR_STAFF') {
  //     if (me?.is_admin && tabValue === 3 && id) return 'flex';
  //     if (isRoleManager && tabValue === 1 && id) return 'flex';
  //   }
  //   if (me?.is_admin && tabValue === 4 && id) return 'flex';
  //   if (isRoleManager && tabValue === 2 && id) return 'flex';
  //   return 'none';
  // }, [me, tabValue, isRoleManager, isVendorStaff, role]);
  // const visibleImages = useMemo(() => {
  //   if (isVendorStaff || role === 'ROLE_VENDOR_STAFF')
  //     return tabValue === 2 ? 'block' : 'none';
  //   return tabValue === 3 ? 'block' : 'none';
  // }, [tabValue, isVendorStaff, role]);
  const classes = useStylesEditPage();
  const homeClasses = homeStyles();
  const navigate = useNavigate();
  const intl = useIntl();
  const {
    data: countries,
    loading: countriesLoading,
    makeRequest,
  } = useAxiosGetCallback<any>();
  const { countryId, setCountryId, countryData } = useHandleCountrySelect(countries, 76);
  // const { deleteUserAvatar, successAvatarDelete } = useDeletePhotoUser();
  // const [addBonuses, loadingAdd, successAdd] = useAddBonusesUser();
  // const { addDevice, loadingAddDevice, isAdd } = useAddDeviceUser();
  // const { push, loadingPush } = usePushUser();

  // const childCategories = useMemo(() => {
  //   return createChildrenList(categories, '\u00B7 ');
  // }, [categories]);

  useLayoutEffect(() => {
    if (me?.is_admin && user?.company?.id) {
      fetchCompany(user?.company?.id);
    }
  }, [user, me]);

  useEffect(() => {
    makeRequest('/api/phone_codes');
  }, []);
  setLayoutSubheader({
    title: id ? fm('USER.EDIT.TITLE') : fm('USER.ADD.TITLE'),
    breadcrumb: usersBreadCrumbs(intl, type || '', clearPagination),
  });
  setLayoutFooter({ show: true });

  // const { userAttr, userAttrLoading } = useUserAttributes();
  // const [uploadAvatar, loadingAvatar, errAvatar] = handleUploadAvatar(user?.id || 0);

  useEffect(() => {
    fetchMe();
  }, []);

  useEffect(() => {
    (isVendorStaff || role === 'ROLE_VENDOR_STAFF') && fetchCategories();
  }, [isVendorStaff]);

  // useLayoutEffect(() => {
  //   if (successAdd && id) fetch(Number(id));
  // }, [successAdd]);

  useEffect(() => {
    if (id) fetch(Number(id));
  }, [id]);

  useEffect(() => {
    if (user?.country?.id) {
      setCountryId(user.country.id);
    }
  }, [user]);

  // formik
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormikUserEdit(id, user, edit, add, countryData, role);

  // const deleteCompany = useCallback(() => {
  //   if (!company) return;
  //   if (!user?.company) return;
  //   const ids = company.managers.map(item => item.id).filter(id => id !== user.id);
  //   editCompany({ id: Number(user?.company?.id), data: { managers_ids: ids } });
  // }, [userId, user?.company, company]);

  const connectCompany = useCallback((userId2: number, company: ICompany) => {
    editCompany({
      id: company.id,
      data: { managers_ids: [...company.managers.map(i => i.id), userId2] },
    });
  }, []);

  // const openSearchCompaniesDialog = useCallback(() => {
  //   if (values.companyInn === '') return null;
  //   searchCompany({ page: 1, perPage: 20, inn: values.companyInn });
  //   setOpenDialogCompanies(true);
  // }, [values]);

  // const openCompaniesDialog = useCallback(() => {
  //   fetchCompanies({ perPage: 20, page: pageCompanies });
  //   setOpenDialogCompanies(true);
  // }, [values]);

  // after success
  useEffect(() => {
    if (addSuccess && addUserId) {
      if (tabValue === 1 && values.role) {
        const userType =
          // eslint-disable-next-line no-nested-ternary
          values.role === 'ROLE_VENDOR'
            ? UserListType.VENDOR
            : // eslint-disable-next-line no-nested-ternary
            values.role === 'ROLE_BUYER'
            ? UserListType.BUYER
            : values.role === 'ROLE_MANAGER'
            ? UserListType.MANAGER
            : null;
        userType && navigate(`/users/edit/${userType}/${addUserId}`, { replace: true });
      } else {
        navigate(-1);
      }
    } else if ((delUserSuccess || editSuccess) && tabValue === 0) {
      navigate(-1);
    }
  }, [delUserSuccess, editSuccess, navigate, addSuccess, addUserId]);

  useEffect(() => {
    if (addUserId && id) {
      setTabValue(3);
      clearAddUserId();
    }
  }, [addUserId, id]);

  useEffect(() => {
    editError && tabValue === 1 && setTabValue(0);
  }, [editError]);

  const handleChangeTabPhoto = useCallback(
    (newValue: number) => {
      handleSubmit();
      if (
        // values.login &&
        values.email &&
        values.password &&
        values.password2 &&
        values.password === values.password2 &&
        values.role
      ) {
        setTabValue(newValue);
      }
    },
    [handleSubmit, values, setTabValue]
  );

  useEffect(() => {
    if (editCompanySuccess) {
      setOpenDialogCompanies(false);
      fetch(Number(id));
    }
    // if (successAvatarDelete) {
    //   fetch(Number(id));
    // }
  }, [editCompanySuccess, editCompanyError, id]);

  // notifications
  useShowErrors([
    byIdError,
    delUserError,
    editError,
    companiesErr,
    companiesSearchErr,
    editCompanyError,
    // errAvatar,
  ]);
  useShowSuccesses([{ when: editSuccess, msg: fm('TITLE.SAVED') }]);

  useEffect(() => {
    if (byIdError) {
      navigate(-1);
      clearFetchUser();
    }
  }, [byIdError]);

  // clean up
  useEffect(() => {
    return () => {
      clearFetchUser();
      clearDelUser();
      clearEdit();
      clearFetchCompanies();
      clearSearchCompany();
      clearEditCompany();
    };
  }, []);

  if (me && !me?.is_admin) {
    navigate('/error/error-v1', { replace: true });
  }

  if (loading) return <Preloader />;

  // if (loading || userAttrLoading || loadingAvatar) return <Preloader />;

  return (
    <>
      <Row>
        <Col>
          <Card className={homeClasses.container}>
            <Tabs
              value={tabValue}
              onChange={(_: React.ChangeEvent<{}>, newValue: number) => {
                if (newValue === 1 && !id) {
                  handleChangeTabPhoto(newValue);
                } else {
                  setTabValue(newValue);
                }
              }}
              indicatorColor='primary'
              textColor='primary'
            >
              <Tab label={fm('PRODUCT.TABS.COMMON')} />
              {/* {me?.is_admin && id && <Tab label={fm('MENU.ORDERS')} />}
              {me?.is_admin && !(isVendorStaff || role === 'ROLE_VENDOR_STAFF') && id && (
                <Tab label={fm('discounts')} />
              )}
              <Tab label={fm('SETTING.PROFILE.PHOTO')} />
              {(me?.is_admin || isRoleManager) && id && (
                <Tab label={intl.formatMessage({ id: 'SETTING.PROFILE.BONUSES' })} />
              )}
              {me?.is_admin && id && <Tab label={fm('MENU.PUSH')} />} */}
            </Tabs>
            <div style={{ display: tabValue === 0 ? 'contents' : 'none' }}>
              <form className={homeClasses.form} onSubmit={handleSubmit} autoComplete='off'>
                {/* <TextField
                  type='text'
                  label={fm('USER.EDIT.LOGIN')}
                  margin='normal'
                  name='login'
                  value={values.login}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.login && errors.login}
                  error={Boolean(touched.login && errors.login)}
                /> */}

                <TextField
                  type='text'
                  label={fm('USER.EDIT.EMAIL')}
                  margin='normal'
                  name='email'
                  value={values.email}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.email && errors.email}
                  error={Boolean(touched.email && errors.email)}
                />

                <div className={classes.dates}>
                  {/* <SelectField
                    label={intl.formatMessage({ id: 'USER.INPUT.COUNTRY_CODE' })}
                    data={countries}
                    selectedValue={countryId}
                    setSelectedValue={setCountryId}
                    variant='outlined'
                    renderItemText={item =>
                      intl.locale === 'ru' ? item.ru_name : item.en_name
                    }
                  /> */}

                  <CountrySelect
                    label={intl.formatMessage({ id: 'USER.INPUT.COUNTRY_CODE' })}
                    countries={countries}
                    countryId={countryId}
                    setCountryId={setCountryId}
                    countriesLoading={countriesLoading}
                    showCode
                    variant='outlined'
                  />

                  {countryData && (
                    <IMaskPhoneInput
                      mask={countryData.mask.replace(/[()]/g, ' ')}
                      onBlur={handleBlur}
                      value={values.phoneNumbers}
                      alwaysShowMask
                      onChange={(e: any) => {
                        setFieldValue(
                          'phoneNumbers',
                          e.target.value ? e.target.value.replace(/[^0-9]/g, '') : ''
                        );
                      }}
                      type='tel'
                      label={intl.formatMessage({ id: 'AUTH.INPUT.PHONE' })}
                      style={{ marginLeft: 5 }}
                      margin='normal'
                      className='kt-width-full'
                      name='phoneNumbers'
                      helperText={touched.phoneNumbers && errors.phoneNumbers}
                      error={Boolean(touched.phoneNumbers && errors.phoneNumbers)}
                      variant='outlined'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            {' '}
                            +{countryData.code}{' '}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </div>

                {/* {userAttr && (
                  <>
                    <MultiSelect
                      label={fm('USER.EDIT.LANGUAGES')}
                      options={userAttr.languages.map(item => ({ value: item, label: item }))}
                      selectedValues={values.languages}
                      setSelectedValues={values => {
                        setFieldValue('languages', values);
                      }}
                    />

                    <TextField
                      select
                      margin='normal'
                      label={fm('USER.EDIT.COUNTRY')}
                      value={values.country}
                      onChange={handleChange}
                      name='country'
                      variant='outlined'
                      helperText={touched.country && errors.country}
                      error={Boolean(touched.country && errors.country)}
                    >
                      {userAttr.country.map(country => (
                        <MenuItem value={country}>{country}</MenuItem>
                      ))}
                    </TextField>
                  </>
                )} */}

                {/* <TextField */}
                {/*  autoComplete='off' */}
                {/*  type='password' */}
                {/*  label={fm('USER.EDIT.PASSWORD')} */}
                {/*  margin='normal' */}
                {/*  name='password' */}
                {/*  value={values.password} */}
                {/*  variant='outlined' */}
                {/*  onBlur={handleBlur} */}
                {/*  onChange={handleChange} */}
                {/*  helperText={touched.password && errors.password} */}
                {/*  error={Boolean(touched.password && errors.password)} */}
                {/* /> */}

                {/* <TextField */}
                {/*  autoComplete='off' */}
                {/*  type='password' */}
                {/*  margin='normal' */}
                {/*  label={fm('USER.EDIT.PASSWORD.REPEAT')} */}
                {/*  name='password2' */}
                {/*  value={values.password2} */}
                {/*  variant='outlined' */}
                {/*  onBlur={handleBlur} */}
                {/*  onChange={handleChange} */}
                {/*  helperText={touched.password2 && errors.password2} */}
                {/*  error={Boolean(touched.password2 && errors.password2)} */}
                {/* /> */}

                <TextField
                  select
                  margin='normal'
                  label={fm('USER.EDIT.ROLE')}
                  value={values.role}
                  onChange={handleChange}
                  name='role'
                  variant='outlined'
                  helperText={touched.role && errors.role}
                  error={Boolean(touched.role && errors.role)}
                  disabled={Boolean(id) || !!role}
                >
                  <MenuItem value={UserRoles.ROLE_VENDOR}>{fm('USER.ROLES.VENDOR')}</MenuItem>
                  <MenuItem value={UserRoles.ROLE_ADMIN}>{fm('USER.ROLES.ADMIN')}</MenuItem>
                  <MenuItem value={UserRoles.ROLE_MANAGER}>
                    {fm('USER.ROLES.MANAGER')}
                  </MenuItem>
                  <MenuItem value={UserRoles.ROLE_BUYER}>{fm('USER.ROLES.BUYER')}</MenuItem>
                  <MenuItem value={UserRoles.ROLE_VENDOR_STAFF}>
                    {fm('USER.ROLES.VENDOR.STAFF')}
                  </MenuItem>
                  <MenuItem value={UserRoles.ROLE_CONTENT_MANAGER}>
                    {fm('USER.ROLES.CONTENT.MANAGER')}
                  </MenuItem>
                </TextField>

                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'USER.EDIT.LAST_NAME' })}
                  margin='normal'
                  name='last_name'
                  value={values.last_name || ''}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.last_name && errors.last_name}
                  error={Boolean(touched.last_name && errors.last_name)}
                />

                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'USER.EDIT.FIRST_NAME' })}
                  margin='normal'
                  name='first_name'
                  value={values.first_name || ''}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.first_name && errors.first_name}
                  error={Boolean(touched.first_name && errors.first_name)}
                />

                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'USER.EDIT.MIDDLE_NAME' })}
                  margin='normal'
                  name='middle_name'
                  value={values.middle_name || ''}
                  variant='outlined'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  helperText={touched.middle_name && errors.middle_name}
                  error={Boolean(touched.middle_name && errors.middle_name)}
                />
                {(userRoleVendor || values.role === 'ROLE_VENDOR') && (
                  <>
                    <TextField
                      type='text'
                      label={fm('COMPANY.FORM.LEGAL_PERSON')}
                      margin='normal'
                      name='companyName'
                      value={values.companyName}
                      variant='outlined'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.companyName && errors.companyName}
                      error={Boolean(touched.companyName && errors.companyName)}
                    />
                    <TextField
                      type='text'
                      label={fm('ICO')}
                      margin='normal'
                      name='ico'
                      value={values.ico}
                      variant='outlined'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.ico && errors.ico}
                      error={Boolean(touched.ico && errors.ico)}
                    />
                    <TextField
                      type='text'
                      label={fm('TIN')}
                      margin='normal'
                      name='tin'
                      value={values.tin}
                      variant='outlined'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.tin && errors.tin}
                      error={Boolean(touched.tin && errors.tin)}
                    />
                    <TextField
                      type='text'
                      label={fm('VAT')}
                      margin='normal'
                      name='vat'
                      value={values.vat}
                      variant='outlined'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.vat && errors.vat}
                      error={Boolean(touched.vat && errors.vat)}
                    />
                    <TextField
                      type='text'
                      label={fm('ACCOUNT.NUMBER')}
                      margin='normal'
                      name='accountNumber'
                      value={values.accountNumber}
                      variant='outlined'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.accountNumber && errors.accountNumber}
                      error={Boolean(touched.accountNumber && errors.accountNumber)}
                    />
                    <TextField
                      type='text'
                      label={fm('PAYMENT.DETAILS')}
                      margin='normal'
                      name='paymentDetails'
                      value={values.paymentDetails}
                      variant='outlined'
                      onBlur={handleBlur}
                      onChange={handleChange}
                      helperText={touched.paymentDetails && errors.paymentDetails}
                      error={Boolean(touched.paymentDetails && errors.paymentDetails)}
                    />
                  </>
                )}
                {/* {(isVendorStaff || role === 'ROLE_VENDOR_STAFF') && childCategories && (
                  <MultiSelect
                    label={fm('CATEGORIES.TITLE')}
                    options={childCategories.map(item => ({
                      value: item.id?.toString() || '0',
                      label: item.name,
                    }))}
                    selectedValues={values.categories}
                    setSelectedValues={values => {
                      setFieldValue('categories', values);
                    }}
                  />
                )} */}

                {/* {id && (
                  <div className={classes.companyField}>
                    <TextField
                      label={fm('COMPANY.FORM.COMPANY')}
                      margin='normal'
                      name={values.company?.name ? 'company' : 'companyInn'}
                      placeholder={fm('COMPANY.SEARCH.BY_INN')}
                      value={values.company?.name || values.companyInn}
                      onChange={handleChange}
                      variant='outlined'
                      disabled={!!values.company?.name}
                      InputProps={{
                        endAdornment: values.company?.name ? (
                          <IconButton onClick={deleteCompany}>
                            <DeleteIcon fontSize='small' />
                          </IconButton>
                        ) : (
                          <IconButton onClick={openSearchCompaniesDialog}>
                            <SearchIcon color='primary' fontSize='small' />
                          </IconButton>
                        ),
                      }}
                    />
                    {!values.company?.name && me?.is_admin && (
                      <Button
                        className={classes.allCompaniesBtn}
                        variant='outlined'
                        color='primary'
                        onClick={openCompaniesDialog}
                      >
                        {fm('COMPANY.BUTTON.ALL')}
                      </Button>
                    )}
                  </div>
                )} */}

                <div className={classes.actions}>
                  <Button
                    onClick={() => navigate(-1)}
                    className={classes.buttons}
                    variant='outlined'
                    color='primary'
                  >
                    {fm('COMMON.BUTTON.BACK')}
                  </Button>

                  <ButtonWithLoader
                    disabled={editLoading}
                    loading={editLoading || editCompanyLoading}
                  >
                    {id ? fm('USER.BUTTON.EDIT') : fm('USER.BUTTON.ADD')}
                  </ButtonWithLoader>

                  {id && !userRoleAdmin && me?.is_admin && (
                    <Button
                      onClick={() => {
                        setOpen(true);
                      }}
                      className={classes.btnDel}
                      variant='outlined'
                      color='secondary'
                    >
                      {fm('COMMON.BUTTON.DELETE')}
                    </Button>
                  )}
                </div>
              </form>
            </div>

            {/* <div
              style={{
                display: tabValue === 1 ? 'block' : 'none',
                width: '100%',
                padding: '30px 80px',
              }}
            >
              {id && <OrdersList type='full' userId={Number(id)} smallSendMsgBtn />}
            </div>

            {!(isVendorStaff || role === 'ROLE_VENDOR_STAFF') && (
              <div
                style={{
                  display: tabValue === 2 ? 'block' : 'none',
                  width: '100%',
                  padding: 24,
                }}
              >
                {user && <UserDiscountsStats data={user.loyalty_level} />}
              </div>
            )} */}

            {/* <div
              style={{
                display: visibleImages,
                width: '100%',
              }}
            >
              <UploadImages
                images={
                  user?.avatar
                    ? [
                        {
                          id: 1,
                          path:
                            typeof user.avatar === 'string' ? user.avatar : user.avatar.path,
                          main: true,
                          name: 'avatar',
                          extension: '.jpg',
                          mime_type:
                            typeof user.avatar === 'string' ? user.avatar : user.avatar.path,
                          small: '',
                          path_blob: '',
                          banner: false,
                        },
                      ]
                    : undefined
                }
                uploadImages={uploadAvatar}
                withGallery
                withCrop
                cropAspect={1}
                deleteImage={() => id && deleteUserAvatar(id)}
              />
            </div> */}
            {/* <div
              style={{
                display: visibleBonuses,
                width: '100%',
                justifyContent: 'center',
              }}
            >
              {user && (
                <BonusesUser user={user} editLoading={loadingAdd} addBonuses={addBonuses} />
              )}
            </div> */}
            {/* <div
              style={{
                // display: "flex",
                display: tabValue === 5 ? 'contents' : 'none',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              {user && (
                <PushUser
                  user={user}
                  editLoading={loadingAddDevice}
                  loadingPush={loadingPush}
                  addDevice={addDevice}
                  push={push}
                  isAdd={isAdd}
                />
              )}
            </div> */}
          </Card>
        </Col>
      </Row>
      <Modal
        DialogProps={{ maxWidth: 'md' } as DialogProps}
        open={openDialogCompanies}
        onClose={editCompanyLoading ? () => {} : () => setOpenDialogCompanies(false)}
        title={fm('COMPANY.EDIT.MOUNT_TITLE')}
        loading={loadingCompanies || loadingSearchCompanies || editCompanyLoading}
        content={
          <CompaniesTable
            me={user as IUser}
            page={pageCompanies}
            perPage={perPageCompanies}
            total={totalCompanies}
            onConnectUser={company => {
              connectCompany(user?.id || 0, company);
            }}
            companies={companies}
            fetch={({ page, perPage }) => fetchCompanies({ page, perPage })}
          />
        }
        actions={[
          { title: fm('COMMON.BUTTON.CLOSE'), onClick: () => setOpenDialogCompanies(false) },
        ]}
      />

      <Modal
        open={open}
        onClose={delUserLoading ? () => {} : () => setOpen(false)}
        title={fm('USER.DELETE.TITLE')}
        loading={delUserLoading}
        content={delUserLoading ? undefined : fm('USER.DELETE.TEXT')}
        actions={
          delUserLoading
            ? undefined
            : [
                {
                  title: fm('COMMON.BUTTON.CANCEL'),
                  onClick: () => setOpen(false),
                },
                {
                  title: fm('COMMON.BUTTON.DELETE'),
                  onClick: () => id && delUser(+id),
                },
              ]
        }
      />
    </>
  );
};

const connector = connect(
  (state: IAppState) => ({
    me: state.profile.me,
    user: state.users.user,
    loading: state.users.byIdLoading,
    editSuccess: state.users.editSuccess,
    editLoading: state.users.editLoading,
    editError: state.users.editError,
    byIdSuccess: state.users.byIdSuccess,
    byIdError: state.users.byIdError,
    delUserLoading: state.users.delUserLoading,
    delUserSuccess: state.users.delUserSuccess,
    delUserError: state.users.delUserError,

    editCompanySuccess: state.companies.editSuccess,
    editCompanyLoading: state.companies.editLoading,
    editCompanyError: state.companies.editError,
    pageCompanies: state.companies.page,
    perPageCompanies: state.companies.per_page,
    totalCompanies: state.companies.total,
    companies: state.companies.companies,
    companiesErr: state.companies.error,
    loadingSearchCompanies: state.companies.searchLoading,
    loadingCompanies: state.companies.loading,
    companiesSearchErr: state.companies.searchError,
    addSuccess: state.users.addSuccess,
    addUserId: state.users.addUserId,
    company: state.companies.company,

    categories: state.categories.categories,
  }),
  {
    fetch: usersActions.fetchByIdRequest,
    clearFetchUser: usersActions.clearFetchById,
    clearEdit: usersActions.clearEdit,
    clearDelUser: usersActions.clearDelUser,
    add: usersActions.addRequest,
    edit: usersActions.editRequest,

    delUser: usersActions.delUserRequest,

    fetchMe: profileActions.fetchRequest,

    clearCompanies: companiesActions.clearCompanies,
    editCompany: companiesActions.editRequest,
    clearEditCompany: companiesActions.clearEdit,

    fetchCompanies: companiesActions.fetchRequest,
    clearFetchCompanies: companiesActions.clearFetch,

    searchCompany: companiesActions.searchRequest,
    clearSearchCompany: companiesActions.clearSearch,
    clearPagination: usersActions.clearPagination,
    clearAddUserId: usersActions.clearAddUserId,
    fetchCompany: companiesActions.fetchByIdRequest,

    fetchCategories: categoriesActions.fetchFullRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(UsersEditPage);
