import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: any) =>
  createStyles({
    container: {
      padding: 40,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'initial',
      },
    },
    mainImg: {
      height: 'auto',
      maxWidth: 550,
      marginBottom: 15,
    },
    galleryImgContainer: {
      position: 'relative',
      height: 200,
      margin: 3,
    },
    galleryImgTitleBar: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 100,
      paddingBottom: 50,
      background:
        'linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.7) 4%, rgba(0,0,0,0) 100%)',
    },
    card: {
      position: 'relative',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'initial',
      },
    },
    img: {
      maxWidth: 550,
      maxHeight: 500,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 'initial',
      },
    },
  })
);
