/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useEffect } from 'react';
import { Navigate, Route, Routes as RoutesDom } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
// import { useLastLocation } from 'react-router-last-location';
import { makeStyles, createStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import ErrorsPage from '../pages/home/error/ErrorsPage';
import LogoutPage from '../pages/auth/Logout';
import { AuthPage } from '../pages/auth/AuthPage';
import { LayoutContextProvider } from '../../_base';
import Layout from '../../_base/layout/Layout';
import { IAppState } from '../store/rootDuck';
import { withRouter } from './withRouter';
import CompanyView from '../pages/home/companies/CompanyView';
// import BlogPage from '../pages/home/blog';
// import ProductView from '../pages/home/products/ProductView';

// import CategoriesPage from '../pages/home/categories';
import UsersPage from '../pages/home/users';
// import PromocodesPage from '../pages/home/promocodes';
import CompaniesPage from '../pages/home/companies';
import UserDocPage from '../pages/home/userDocs';
import SettingsPage from '../pages/home/settings';
// import ProductsPage from '../pages/home/products';
// import DashboardPage from '../pages/home/dashboard';
// import OrdersPage from '../pages/home/orders';
import StorePage from '../pages/home/store';
import ProjectsPage from '../pages/home/projects';
// import CartPage from '../pages/home/cart';
import InvoicesPage from '../pages/home/invoices';
import TariffsPage from '../pages/home/tariffs';
// import CollectionsPage from '../pages/home/collections';
// import TransactionsPage from '../pages/home/transactions';
// import RefferalsPage from '../pages/home/referrals';
// import ChatsPages from '../pages/home/chats';
// import TendersPage from '../pages/home/tenders';
// import DeliveryPage from '../pages/home/delivery';
// import SupplyPage from '../pages/home/supply';
// import ShiftsPage from '../pages/home/shifts';
// import GroupsPage from '../pages/home/groups';
// import StatisticsPage from '../pages/home/statistics';
// import SearchPage from '../pages/home/productSearch';
// import BannersPage from '../pages/home/banners';
// import MainPage from '../pages/home/main';
// import RequestPage from '../pages/home/requests';
import { editLocale } from '../crud/profile.crud';
// import Loyalties from '../pages/home/loyalty';

export type ProtectedRouteProps = {
  isAuthorized: boolean;
  outlet: JSX.Element;
};

export default function ProtectedRoute({ isAuthorized, outlet }: ProtectedRouteProps) {
  if (isAuthorized) {
    return outlet;
  }
  return <Navigate to='/error/error-v1' />;
}

// export const PrivateRoute = ({ isAuthorized }: any) => {
//   return isAuthorized ? <Outlet /> : <Navigate to='/error/error-v1' />;
// };

const useStyles = makeStyles(theme =>
  createStyles({
    success: {
      backgroundColor: '#0abb87 !important',
    },
    error: {
      backgroundColor: theme.palette.error.main,
    },
  })
);

export const Routes = withRouter(props => {
  const classes = useStyles();
  // const lastLocation = useLastLocation();

  // routerHelpers.saveLastLocation(lastLocation);

  const { isAuthorized, configMenu, me, locale } = useSelector(
    ({ auth, profile, i18n, builder: { menuConfig } }: IAppState) => ({
      configMenu: menuConfig,
      isAuthorized: auth.user != null,
      me: profile.me,
      // @ts-ignore
      locale: i18n.lang,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (isAuthorized && me) {
      if (me?.locale !== locale) {
        editLocale({ locale });
      }
    }
  }, [me, isAuthorized]);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
      }}
    >
      {/* Create `LayoutContext` from current `history` and `menuConfig`. */}
      <LayoutContextProvider history={props} menuConfig={configMenu}>
        <RoutesDom>
          <Route path='auth/*' element={<AuthPage />} />
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout/*' element={<LogoutPage />} />
          <Route path='/' element={<Layout />}>
            {isAuthorized ? (
              <Route path='/' element={<Navigate to='projects/list' replace />} />
            ) : (
              <Route path='/' element={<Navigate to='/auth/main' replace />} />
            )}
            <Route path='settings/*' element={<SettingsPage />} />
            {/* <Route
              path='banners/*'
              element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<BannersPage />} />}
            />
            <Route path='categories/*' element={<CategoriesPage />} /> */}
            <Route
              path='documents/*'
              element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<UserDocPage />} />}
            />
            <Route
              path='users/*'
              element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<UsersPage />} />}
            />
            <Route
              path='companies/*'
              element={
                <ProtectedRoute isAuthorized={isAuthorized} outlet={<CompaniesPage />} />
              }
            />
            {/* <Route
              path='dashboard/*'
              element={
                <ProtectedRoute isAuthorized={isAuthorized} outlet={<DashboardPage />} />
              }
            />
            <Route
              path='orders/*'
              element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<OrdersPage />} />}
            /> */}
            <Route
              path='viewcompany/:id'
              element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<CompanyView />} />}
            />
            {/* <Route
              path='groups/*'
              element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<GroupsPage />} />}
            /> */}
            <Route
              path='tariffs/*'
              element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<TariffsPage />} />}
            />
            {/* <Route
              path='collections/*'
              element={
                <ProtectedRoute isAuthorized={isAuthorized} outlet={<CollectionsPage />} />
              }
            />
            <Route
              path='promocodes/*'
              element={
                <ProtectedRoute isAuthorized={isAuthorized} outlet={<PromocodesPage />} />
              }
            />
            <Route
              path='refferals/*'
              element={
                <ProtectedRoute isAuthorized={isAuthorized} outlet={<RefferalsPage />} />
              }
            />
            <Route
              path='transactions/*'
              element={
                <ProtectedRoute isAuthorized={isAuthorized} outlet={<TransactionsPage />} />
              }
            /> */}
            {/* <Route path='products/*' element={<ProductsPage />} /> */}
            {/* <Route path='cart/*' element={<CartPage />} /> */}
            {/* <Route path='blog/*' element={<BlogPage />} /> */}
            {/* <Route path='viewproduct/:id' element={<ProductView />} /> */}
            {/* <Route
              path='product_search/*'
              element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<SearchPage />} />}
            />
            <Route
              path='statistics/*'
              element={
                <ProtectedRoute isAuthorized={isAuthorized} outlet={<StatisticsPage />} />
              }
            /> */}

            <Route
              path='stores/*'
              element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<StorePage />} />}
            />

            <Route
              path='projects/*'
              element={
                <ProtectedRoute isAuthorized={isAuthorized} outlet={<ProjectsPage />} />
              }
            />

            <Route
              path='invoices/*'
              element={
                <ProtectedRoute isAuthorized={isAuthorized} outlet={<InvoicesPage />} />
              }
            />

            {/* <Route
              path='chats/*'
              element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<ChatsPages />} />}
            />
            <Route
              path='tenders/*'
              element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<TendersPage />} />}
            />
            <Route
              path='delivery/*'
              element={
                <ProtectedRoute isAuthorized={isAuthorized} outlet={<DeliveryPage />} />
              }
            />
            <Route
              path='supply/*'
              element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<SupplyPage />} />}
            />
            <Route
              path='shifts/*'
              element={<ProtectedRoute isAuthorized={isAuthorized} outlet={<ShiftsPage />} />}
            /> */}
            {/* <Route path='requests/*' element={<RequestPage />} /> */}
            {/* <Route path='main/*' element={<MainPage />} /> */}
            {/* <Route path='loyalties/*' element={<Loyalties />} /> */}

            <Route path='*' element={<Navigate to='/error/error-v1' />} />
          </Route>
        </RoutesDom>
      </LayoutContextProvider>
    </SnackbarProvider>
  );
});
