import React, { Suspense, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';

import { LayoutSplashScreen } from '../../../_base';
import getMenuConfig from '../../router/MenuConfig';
import * as builder from '../../../_base/ducks/builder';
import { actions as i18nActions } from '../../../_base/ducks/i18n';
import { actions as cartActions } from '../../store/ducks/cart.duck';
import { actions as profileActions } from '../../store/ducks/profile.duck';
import { IAppState } from '../../store/rootDuck';
import { UserRoles } from '../../interfaces/user';
import Preloader from '../../components/other/Preloader/Preloader';

const HomePage: React.FC<TPropsFromRedux> = ({
  userRoles,
  isAuthorized,
  setMenuConfig,
  getOrCreateCart,
  getOrCreateCartLoading,
  getLocalGuestCart,
  isAdmin,
  fetchMe,
  me,
}) => {
  const [menuConfig] = useState(getMenuConfig(userRoles, isAuthorized));
  // const locale = useSelector(({ i18n }: any) => i18n.lang);
  const navigate = useNavigate();
  // const intl = useIntl();

  // for user profile in header
  useEffect(() => {
    fetchMe();
  }, []);

  // useEffect(() => {
  //   if (isAuthorized && me) {
  //     if (me?.locale !== locale) {
  //       editLocale({ locale });
  //     }
  //   }
  // }, [me, isAuthorized]);

  // useEffect(() => {
  //   if (me?.locale && me?.locale !== intl.locale) {
  //     setLanguage(me.locale);
  //   }
  // }, [me]);

  useEffect(() => {
    setMenuConfig(menuConfig);
  }, [setMenuConfig, menuConfig]);

  // Проверка на существование корзины у пользователя
  useEffect(() => {
    const localCart = localStorage.getItem('guestCart');
    if (userRoles && userRoles.includes(UserRoles.ROLE_BUYER) && localCart) {
      // const localLocation = localStorage.getItem('location');
      // const parseLocalLocation = localLocation && JSON.parse(localLocation);
      // if (parseLocalLocation) {
      //   localStorage.removeItem('location');
      // }
      navigate('/cart', { replace: true });
    }

    if (userRoles && !userRoles.includes(UserRoles.ROLE_VENDOR) && !isAdmin) {
      getOrCreateCart();
    }
  }, [getOrCreateCart, isAdmin, userRoles]);

  useEffect(() => {
    !isAuthorized && getLocalGuestCart();
  }, [isAuthorized, getLocalGuestCart]);

  if (getOrCreateCartLoading) return <Preloader />;

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Outlet />
    </Suspense>
  );
};

const connector = connect(
  (state: IAppState) => ({
    userRoles: state.auth.user?.roles,
    isAuthorized: state.auth.user != null,
    getOrCreateCartLoading: state.cart.getOrCreateCartLoading,
    isAdmin: state.auth.user?.is_admin,
    me: state.profile.me,
  }),
  {
    fetchMe: profileActions.fetchRequest,
    editMe: profileActions.editRequest,
    setMenuConfig: builder.actions.setMenuConfig,
    getOrCreateCart: cartActions.getOrCreateRequest,
    getLocalGuestCart: cartActions.getLocalGuestCart,
    setLanguage: i18nActions.setLanguage,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(HomePage);
