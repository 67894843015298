import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useFormatMessage } from '../../../../hooks';
import { IProject } from '../../../../interfaces/projects';
import { getProjects } from '../../../../crud/projects.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useGetProjects = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [total, setTotal] = useState(0);
  const [projects, setProjects] = useState<IProject[]>([]);
  const fm = useFormatMessage();

  const fetchProjects = useCallback(
    async (page: number, perPage: number, user_id?: number) => {
      setLoading(true);
      getProjects(page, perPage, user_id)
        .then(res => {
          setTotal(res.data.total);
          setPage(res.data.page);
          setPerPage(res.data.per_page);
          setProjects(res.data.data);
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  return { fetchProjects, loading, page, perPage, total, projects };
};
