import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';

export const useFormikEnterConfirmCodeEmail = (
  email: string,
  makeLoginEmailCode: (email: string, code: string) => void
) => {
  const intl = useIntl();

  return useFormik({
    initialValues: { code: '' },
    validationSchema: Yup.object().shape({
      code: Yup.string().required(
        intl.formatMessage({ id: 'AUTH.VALIDATION.REQUIRED_FIELD' })
      ),
    }),
    onSubmit: submitValues => {
      makeLoginEmailCode(email, submitValues.code.replace(/\s+/g, ''));
    },
  });
};
