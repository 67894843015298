import React, { useState, useEffect } from 'react';
import { Autocomplete as MaterialAutocomplete } from '@material-ui/lab';
import { TextField, CircularProgress } from '@material-ui/core';

// const LIMIT_INTERVAL = 2000;
// const FIRST_FETCH_SYMBOL = 3;

type SearchType = 'email' | 'fio' | 'name';

interface IProps {
  id?: string;
  options: any[];
  inputValue: any;
  label: string;
  inputClassName?: any;
  // editable: boolean;
  loading: boolean;
  handleBlur?: (e: any) => {} | void;
  searchStaff: (data: any) => void;
  clearUsers: () => void;
  getOptionLabel: (option: any) => string;
  setSelectedValue: (location: any) => {} | void;
  searchType: SearchType;
}

const AutocompleteStaff: React.FC<IProps> = ({
  id = '',
  options,
  inputValue,
  label,
  inputClassName,
  handleBlur,
  // editable,
  loading,
  searchStaff,
  clearUsers,
  setSelectedValue,
  getOptionLabel,
  searchType = 'fio',
}) => {
  // const [editableLocation, setEditableLocation] = useState(editable);
  const [value, setValue] = useState<string>('');
  // const [fetchedLocation, setFetchedLocation] = useState('');

  // useEffect(() => {
  //   clearUsers();
  // }, [clearUsers]);

  useEffect(() => {
    // if (!location || location.length === FIRST_FETCH_SYMBOL) {
    // eslint-disable-next-line default-case
    switch (searchType) {
      case 'fio':
        if (value && value.length > 1) {
          searchStaff({ fio: value });
          // setFetchedLocation(fio);
        }
        break;
      case 'email':
        if (value && value.length > 1) {
          searchStaff({ email: value });
          // setFetchedLocation(fio);
        }
        break;
      case 'name':
        if (value && value.length > 1) {
          searchStaff({ name: value });
          // setFetchedLocation(fio);
        }
        break;
    }
  }, [searchStaff, value]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (location !== fetchedLocation) {
  //       searchStaff({fio:location});
  //       setFetchedLocation(location);
  //     }
  //   }, LIMIT_INTERVAL);

  //   return () => interval && clearInterval(interval);
  // }, [location, searchStaff, searchStaff]);

  // useEffect(() => {
  //   setLocation(defaultValue.text);
  // }, [setLocation, defaultValue.text]);

  // useEffect(() => {
  //   if (inputValue.fio === '') setEditableLocation(true);
  //   if (inputValue.fio !== '') setEditableLocation(false);
  // }, [inputValue, setEditableLocation]);

  return (
    <MaterialAutocomplete
      id={`autocomplete${id}`}
      // noOptionsText={intl.formatMessage({ id: 'STORE.INPUT.LOCATION_PLACEHOLDER' })}
      noOptionsText={label}
      options={options}
      loading={loading}
      getOptionLabel={getOptionLabel}
      // getOptionLabel={option => option.fio || `${option.last_name || ''} ${option.first_name || ''} ${option.middle_name || ''}`}
      onChange={(e: any, val: any) => {
        if (val) {
          setSelectedValue(val);
          // setEditableLocation(false);
        } else {
          setValue('');
          clearUsers();
        }
      }}
      filterOptions={o => o}
      value={inputValue}
      onInputChange={(_e: any, _val: any, reason: any) => {
        if (reason === 'clear') setSelectedValue({ fio: '' });
      }}
      renderInput={(params: any) => {
        return (
          <TextField
            {...params}
            type='text'
            margin='normal'
            name='location'
            variant='outlined'
            label={label}
            className={inputClassName}
            onBlur={handleBlur}
            onChange={e => {
              setValue(e.target.value);
            }}
            style={{ margin: 0 }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading && <CircularProgress color='inherit' size={20} />}

                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
    />
  );
};

export default AutocompleteStaff;
