import axios from 'axios';
import { ILoyaltyData } from '../interfaces/loyalties';

export const createLoyaltyLevel = (data: ILoyaltyData) => (
    axios.post(`/api/shop/loyalty_level`, data)
);

export const editLoyaltyLevel = (id: number, data: ILoyaltyData) => (
    axios.put(`/api/shop/loyalty_level/${id}`,data)
);

export const getLoyalties = () => (
    axios.get(`/api/shop/loyalty_levels`)
);

export const getLoyaltyById = (id: number) => (
    axios.get(`/api/shop/loyalty_level/${id}`)
)

export const deleteLoyalty = (id: number) => (
    axios.delete(`/api/shop/loyalty_level/${id}`)
);
