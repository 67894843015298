import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getResponseMessage } from '../../../../utils/utils';
import { useFormatMessage } from '../../../../hooks';
import { IUser } from '../../../../interfaces/user';
import { getUsersProject } from '../../../../crud/projects.crud';

export const useGetUsers = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState<IUser[]>([]);
  const dispatch = useDispatch();
  const fm = useFormatMessage();

  const fetchUsers = useCallback(
    async (page: number, perPage: number) => {
      setLoading(true);
      getUsersProject(page, perPage)
        .then(res => {
          setTotal(res.data.total);
          setPage(res.data.page);
          setPerPage(res.data.per_page);
          setUsers(res.data.data);
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [dispatch]
  );

  return { fetchUsers, loading, page, perPage, total, users };
};
