import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import objectPath from 'object-path';
import { makeStyles } from '@material-ui/core';

import Topbar from './Topbar';
import HMenu from './HMenu/HMenu';
import AnimateLoading from '../../partials/layout/AnimateLoading';
import * as builder from '../../ducks/builder';
import { IAppState } from '../../../app/store/rootDuck';

const useStyles = makeStyles(() => ({
  subheaderFull: {
    left: '0 !important',
  },
}));

const Header = () => {
  const headerCommonRef: any = useRef();
  const classes = useStyles();

  const {
    me
  } = useSelector((store: IAppState) => ({
    me: store.profile.me,
  }));

  const {
    isAuthorized
  } = useSelector((store: IAppState) => ({
    isAuthorized: store.auth.user != null,
  }));

  const {
    menuHeaderDisplay,
    headerAttributes,
    headerClasses
  } = useSelector((store: IAppState) => ({
    menuHeaderDisplay: objectPath.get(store.builder.layoutConfig, 'header.menu.self.display'),
    headerAttributes: builder.selectors.getAttributes(store, { path: 'header' }),
    headerClasses: builder.selectors.getClasses(store, {
      path: 'header',
      toString: true,
    }),
  }));

  useEffect(() => {
    const options: any = {};
    if (headerCommonRef.current?.getAttribute('data-ktheader-minimize') === '1') {
      options['minimize.desktop.on'] = 'kt-header--minimize';
      options['offset.desktop'] = 130;
    }
  }, []);
  return (
    <div
      className={`kt-header kt-grid__item ${headerClasses} ${classes.subheaderFull}`}
      id='kt_header'
      ref={headerCommonRef}
      {...headerAttributes}
    >
      <AnimateLoading />
      {/* <!-- begin: Header Menu --> */}
      {menuHeaderDisplay && <HMenu />}
      {/* <!-- end: Header Menu --> */}

      {/* <!-- begin:: Header Topbar --> */}
      {/* <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden --> */}
      {!menuHeaderDisplay && <div />}
      <Topbar showBurger={isAuthorized} me={me} isAuthorized={isAuthorized} />
      {/* <!-- end:: Header Topbar --> */}
    </div>
  );
};

export default Header
