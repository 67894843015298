import React, { CSSProperties } from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

interface IProps {
  children: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit' | undefined;
  onPress?: any;
  variant?: 'text' | 'outlined' | 'contained';
  style?: CSSProperties;
  theme?: ButtonTheme;
  margin?: boolean;
  marginRight?: boolean;
  marginLeft?: boolean;
  startIcon?: React.ReactNode;
}
export enum ButtonTheme {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  OUTLINED = 'outlined',
}
const useStyles = makeStyles((theme: any) => ({
  isLoading: {
    paddingRight: '3.5rem!important',
  },
  btn: {
    '&.MuiButton-root': {
      borderRadius: '0 !important',
      height: 50,
      minWidth: 135,
    },
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  outlined: {
    '&.MuiButton-root': {
      backgroundColor: '#ffffff',
      color:'#470D63FF'
    },
  },
  secondary: {
    '&.MuiButton-root': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  btnMarginRight: {
    marginRight: 25,
  },
  btnMarginLeft: {
    marginLeft: 25,
  },
  btnMargin: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 20,
    },
  },
}));

const ButtonWithLoader: React.FC<IProps> = ({
  children,
  type = 'submit',
  disabled = false,
  loading = false,
  onPress = () => {},
  margin,
  marginRight = true,
  marginLeft = false,
  startIcon,
  variant='contained',
  theme = ButtonTheme.PRIMARY,
  ...props
}) => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      type={type}
      variant={variant}
      color='primary'
      disabled={disabled}
      onClick={onPress}
      startIcon={startIcon}
      className={clsx({
        'btn btn-primary btn-elevate kt-login__btn-primary': true,
        'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading,
        [classes.isLoading]: loading,
        [classes[theme]]: true,
        [classes.btnMargin]: margin,
        [classes.btnMarginRight]: marginRight,
        [classes.btnMarginLeft]: marginLeft,
      })}
    >
      {children}
    </Button>
  );
};

export default ButtonWithLoader;
