import axios from 'axios';
// import {
//   editTariffDiscountsUrl,
//   editTariffPeriodUrl,
//   editTariffPriceUrl,
//   getTariffsUrl,
// } from '../constants';

// export const getTariffs = () => axios.get(getTariffsUrl);

// export const editPeriodTariff = (
//   id: number,
//   data: string | number | null,
//   type: 'period' | 'price' | 'discounts',
//   field?: string
// ) => {
//   if (type === 'discounts') {
//     // eslint-disable-next-line no-extra-boolean-cast
//     return axios.put(editTariffDiscountsUrl(id), {discountsPerDay: Boolean(data) ? data : null});
//   }
//   if (type === 'price' && field) {
//     const body: any = {}
//     body[field] = data
//     // eslint-disable-next-line no-extra-boolean-cast
//     return axios.put(editTariffPriceUrl(id), body);
//   }
//   // eslint-disable-next-line no-extra-boolean-cast
//   return axios.put(editTariffPeriodUrl(id), { days: Boolean(data) ? data : undefined });
// };

export const buyTariff = (price: any) => axios.get(`/api/tinkoff/pay?amount=${price}`);

export const editUserTariff = (id: number, days: number) =>
  axios.post(`/api/user_tariff/${id}?days=${days}`);

export const editCompanyTariff = (id: number, days: number, tariff_id: number) =>
  axios.post(
    `/api/company_tariff/${id}`,
    {},
    {
      params: {
        days,
        tariff_id,
      },
    }
  );
