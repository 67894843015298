import React, { useCallback, useState } from 'react';
import { CheckInSystem } from './CheckInSystem';
import { EnterPassword } from './EnterPassword';
import { EnterConfirmCode } from './EnterConfirmCode';
import { ForgotPassword } from './ForgotPassword';
import { Registration } from './Registration';
import { EmailSent } from './EmailSent';
import { EnterConfirmCodeEmail } from './EnterConfirmCodeEmail';

interface IProps {
  hideGuestBtn?: boolean;
  initPhone?: string;
  initEmail?: string;
}

export type TAuthTabs =
  | 'CheckInSystem'
  | 'EnterPassword'
  | 'ForgotPassword'
  | 'EnterConfirmCode'
  | 'Registration'
  | 'EmailSent'
  | 'EnterConfirmCodeEmail';

export const AuthTabs: React.FC<IProps> = ({ hideGuestBtn, initPhone, initEmail }) => {
  const [tab, setTab] = useState<TAuthTabs>('CheckInSystem');
  const [tabProps, setTabProps] = useState<any>({});

  const authTabSwitcher = useCallback(() => {
    switch (tab) {
      case 'CheckInSystem':
        return (
          <CheckInSystem
            initEmail={initEmail}
            initPhone={initPhone}
            hideGuestBtn={hideGuestBtn}
            changeAuthTab={changeAuthTab}
            {...tabProps}
          />
        );
      case 'EnterPassword':
        return <EnterPassword changeAuthTab={changeAuthTab} {...tabProps} />;
      case 'EnterConfirmCode':
        return <EnterConfirmCode changeAuthTab={changeAuthTab} {...tabProps} />;
      case 'ForgotPassword':
        return <ForgotPassword changeAuthTab={changeAuthTab} {...tabProps} />;
      case 'Registration':
        return (
          <Registration initPhone={initPhone} changeAuthTab={changeAuthTab} {...tabProps} />
        );
      case 'EmailSent':
        return <EmailSent changeAuthTab={changeAuthTab} {...tabProps} />;
      case 'EnterConfirmCodeEmail':
        return <EnterConfirmCodeEmail changeAuthTab={changeAuthTab} {...tabProps} />;
      default:
        return <></>;
    }
  }, [tab]);

  const changeAuthTab = useCallback((newTab: TAuthTabs, props: any = {}) => {
    setTab(newTab);
    setTabProps(props);
  }, []);

  return authTabSwitcher();
};
