import * as utils from '../../_base/utils/utils';

const localStorageLastLocationKey = 'demo1-lastLocation';

function acceptLocation(lastLocation: any) {
  if (
    lastLocation &&
    lastLocation.pathname &&
    lastLocation.pathname !== '/' &&
    lastLocation.pathname.indexOf('auth') === -1 &&
    lastLocation.pathname !== '/logout'
  ) {
    return true;
  }

  return false;
}

export function saveLastLocation(lastLocation: any) {
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  const useLocations = localStorateLocations ? JSON.parse(localStorateLocations) : [];

  if (acceptLocation(lastLocation)) {
    useLocations.push(lastLocation.pathname);
    utils.setStorage(localStorageLastLocationKey, JSON.stringify(useLocations), 120);
  }
}

export function forgotLastLocation() {
  utils.removeStorage(localStorageLastLocationKey);
}

export function getLastLocation() {
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  if (!localStorateLocations) {
    return '/';
  }

  const userLocations = JSON.parse(localStorateLocations);
  const result = userLocations.length > 0 ? userLocations.pop() : '/';
  return result;
}
