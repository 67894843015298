import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useFormatMessage } from '../../../../hooks';
import { createInvoice } from '../../../../crud/projects.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { IInvoice } from '../../../../interfaces/projects';

export const useCreateInvoice = (successInvoice?: (item: IInvoice) => void) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const fm = useFormatMessage();

  const fetchInvoiceProject = useCallback(
    async (company_id: number, tariff_id: number, days: number) => {
      setLoading(true);
      createInvoice(company_id, tariff_id, days)
        .then(res => {
          successInvoice && successInvoice(res.data.data);
          enqueueSnackbar(fm('INVOICE.CREATED'), { variant: 'success' });
          setSuccess(true);
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [successInvoice]
  );

  return { fetchInvoiceProject, loading, success };
};
