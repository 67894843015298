import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useFormatMessage } from '../../../../hooks';
import {IProject, IProjectSearchParams} from '../../../../interfaces/projects';
import {getProjects, getProjectsSearch} from '../../../../crud/projects.crud';
import { getResponseMessage } from '../../../../utils/utils';

export const useSearchProjects = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState<IProject[] | null>(null);
  const fm = useFormatMessage();

  const fetchSearchProjects = useCallback(
    async (params: IProjectSearchParams) => {
      setLoading(true);
      getProjectsSearch(params)
        .then(res => {
          setProjects(res.data.data);
        })
        .catch(e => {
          enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  return { fetchSearchProjects, loading, projects, setProjects };
};
