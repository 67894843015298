import React from 'react';
import { Route, Routes } from 'react-router-dom';
import InvoicesPage from './InvoicesPage';

export default function Invoices() {
  return (
    <Routes>
      <Route path='list' element={<InvoicesPage />} />
    </Routes>
  );
}
