import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useFormatMessage } from '../../../../hooks';
import { getProject } from '../../../../crud/projects.crud';
import { getResponseMessage } from '../../../../utils/utils';
import { IProject } from '../../../../interfaces/projects';

export const useGetProject = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState<IProject | null>(null);
  const fm = useFormatMessage();

  const fetchGetProject = useCallback(async (id: number) => {
    setLoading(true);
    getProject(id)
      .then(res => {
        setProject(res.data.data);
      })
      .catch(e => {
        enqueueSnackbar(`${fm('ERROR')}: ${getResponseMessage(e)}`, { variant: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { fetchGetProject, loading, project };
};
