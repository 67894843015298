import React, { useEffect, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { InputAdornment, MenuItem, TextField } from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

import ButtonWithLoader from '../../../../components/other/Buttons/ButtonWithLoader';
import { IAppState } from '../../../../store/rootDuck';
import { actions as profileActions } from '../../../../store/ducks/profile.duck';
import { actions as i18nActions } from '../../../../../_base/ducks/i18n';
import useCrudSnackbar from '../../../../hooks/useCrudSnackbar';
import { IUser, IUserEditProps, TUserAttributes } from '../../../../interfaces/user';
import homeStyles from '../../../../constants/homeStyles';
import { toAbsoluteUrl } from '../../../../../_base';
import { cleanPhone } from '../../../../utils/utils';
import { useHandleCountrySelect } from '../../../auth/hooks/useHandleCountrySelect';
import { useAxiosGetCallback } from '../../../../hooks/useAxiosGet';
// import SelectField from '../../../../components/ui/SelectField';
import { useStylesProfileForm } from '../hooks/useStyles';
import { IMaskPhoneInput } from '../../../auth/components/CheckInSystem';
import CountrySelect from '../../../../components/ui/CountrySelect';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';

interface IProps {
  me: IUser;
  userAttr: any;
}

const languages = [
  {
    lang: 'ru',
    name: 'Русский',
    flag: toAbsoluteUrl('/media/flags/248-russia.svg'),
  },
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/260-united-kingdom.svg'),
  },
  {
    lang: 'cz',
    name: 'Czech',
    flag: toAbsoluteUrl('/media/flags/149-czech-republic.svg'),
  },
];

const ProfileForm: React.FC<TPropsFromRedux & IProps> = ({
  me,
  clearEdit,
  edit,
  editSuccess,
  editLoading,
  editError,
  setLanguage,
}) => {
  const classes = useStylesProfileForm();
  const homeClasses = homeStyles();
  const intl = useIntl();
  const fm = useFormatMessage();
  const userRoleVendor = useDefineUserRole(me, 'ROLE_VENDOR');
  const {
    data: countries,
    loading: countriesLoading,
    makeRequest,
  } = useAxiosGetCallback<any>();
  const { countryId, setCountryId, countryData } = useHandleCountrySelect(countries, 76);
  // const disabledPhone = useMemo(() => {
  //   if (me.is_admin) {
  //     return false;
  //   }
  //   if (!me.is_admin && !me.phone_numbers) {
  //     return false;
  //   }
  //   return true;
  // }, [me]);

  useEffect(() => {
    makeRequest('/api/phone_codes');
  }, []);  

  // useEffect(() => {
  //   if (me?.country?.id) {
  //     setCountryId(me.country.id);
  //   }
  // }, [me]);
  

  const getUserAttrCountry = (attributes: TUserAttributes) => {
    const countries = attributes.filter(el => el.type === 'country');
    return countries[0]?.value || '';
  };

  const getUserAttrLanguages = (attributes: TUserAttributes) => {
    return attributes.filter(el => el.type === 'languages').map(el => el.value);
  };

  useCrudSnackbar({
    success: editSuccess,
    error: editError,
    clear: clearEdit,
    successMessage: intl.formatMessage({ id: 'PROFILE.EDIT.SUCCESS' }),
    errorMessage: `${intl.formatMessage({ id: 'ERROR' })}: ${editError}`,
    afterSuccessOrError: () => {
      clearEdit();
    },
  });

  return (
    <Formik
      initialValues={{
        login: me ? me.login : '',
        email: me ? me.email : '',
        password: '',
        password2: '',
        role: me?.roles.length > 0 ? me.roles[0] : '',
        locale: me ? me.locale : 'en',
        country: me?.attributes ? getUserAttrCountry(me.attributes) : '',
        languages: me?.attributes ? getUserAttrLanguages(me.attributes) : [],
        phoneNumbers: me?.phone_numbers
          ? cleanPhone(me.phone_numbers, `+${countryData?.code || '420'}`)
          : '',
        first_name: me.first_name || '',
        last_name: me.last_name || '',
        middle_name: me.middle_name || '',
        companyName: me?.company_name || '',
        ico: me?.ico || '',
        tin: me?.tin || '',
        vat: me?.vat || '',
        accountNumber: me?.account_number || '',
        paymentDetails: me?.payment_details || '',
      }}
      validationSchema={Yup.object().shape({
        // login: Yup.string()
        //   .required(intl.formatMessage({ id: 'PROFILE.VALIDATION.REQUIRED_LOGIN' }))
        //   .nullable(),
        email: Yup.string()
          .required(intl.formatMessage({ id: 'PROFILE.VALIDATION.REQUIRED_EMAIL' }))
          .email(intl.formatMessage({ id: 'PROFILE.VALIDATION.EMAIL' }))
          .nullable(),
        password2: Yup.string().oneOf(
          [Yup.ref('password'), ''],
          intl.formatMessage({ id: 'PROFILE.VALIDATION.PASSWORD_COMPARE' })
        ),
        role: Yup.string().required(
          intl.formatMessage({ id: 'PROFILE.VALIDATION.REQUIRED_ROLE' })
        ),
      })}
      onSubmit={values => {
        const newValues = userRoleVendor
          ? {
              companyName: values.companyName,
              ico: values.ico,
              tin: values.tin,
              vat: values.vat,
              accountNumber: values.accountNumber,
              paymentDetails: values.paymentDetails,
            }
          : {};
        const body: IUserEditProps = {
          // login: values.login,
          email: values.email,
          role: values.role,
          conuntryId: countryData.id,
          phoneNumbers: values.phoneNumbers,
          country: intl.locale === 'ru' ? countryData.ru_name : countryData.en_name,
          first_name: values.first_name,
          last_name: values.last_name,
          middle_name: values.middle_name,
          countryCode: countryData.code || '420',
          ...newValues,
          attributes: [
            {
              type: 'languages',
              // @ts-ignore
              value: values.languages,
            },
            {
              type: 'country',
              value: values.country,
            },
          ],
        };

        if (values.password) {
          body.password = values.password;
        }

        if (values.locale) {
          setLanguage(values.locale);
          body.locale = values.locale;
        }

        edit(body);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <form className={homeClasses.form} onSubmit={handleSubmit}>
          {/* <TextField
            type='text'
            label={intl.formatMessage({ id: 'USER.EDIT.LOGIN' })}
            margin='normal'
            name='login'
            value={values.login || ''}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.login && errors.login}
            error={Boolean(touched.login && errors.login)}
          /> */}

          <TextField
            type='text'
            label={intl.formatMessage({ id: 'USER.EDIT.EMAIL' })}
            margin='normal'
            name='email'
            value={values.email || ''}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.email && errors.email}
            error={Boolean(touched.email && errors.email)}
          />

          {/* <SelectField
            label={intl.formatMessage({ id: 'USER.INPUT.COUNTRY_CODE' })}
            data={countries}
            selectedValue={countryId}
            setSelectedValue={setCountryId}
            variant='outlined'
            renderItemText={item => (intl.locale === 'ru' ? item.ru_name : item.en_name)}
            disabled={disabledPhone}
          /> */}

          <CountrySelect
            label={intl.formatMessage({ id: 'USER.INPUT.COUNTRY_CODE' })}
            countries={countries}
            countryId={countryId}
            setCountryId={setCountryId}
            countriesLoading={countriesLoading}
            showCode
            variant='outlined'
          />

          {countryData && (
            <IMaskPhoneInput
              mask={countryData.mask}
              onBlur={handleBlur}
              value={values.phoneNumbers}
              alwaysShowMask
              // disabled={disabledPhone}
              onChange={(e: any) => {
                setFieldValue(
                  'phoneNumbers',
                  e.target.value ? e.target.value.replace(/[^0-9]/g, '') : ''
                );
              }}
              type='tel'
              label={intl.formatMessage({ id: 'AUTH.INPUT.PHONE' })}
              margin='normal'
              className='kt-width-full'
              name='phoneNumbers'
              helperText={touched.phoneNumbers && errors.phoneNumbers}
              error={Boolean(touched.phoneNumbers && errors.phoneNumbers)}
              variant='outlined'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'> +{countryData.code} </InputAdornment>
                ),
              }}
            />
          )}

          {/* <TextField */}
          {/*  autoComplete='off' */}
          {/*  type='password' */}
          {/*  label={intl.formatMessage({ id: 'USER.EDIT.PASSWORD' })} */}
          {/*  margin='normal' */}
          {/*  name='password' */}
          {/*  value={values.password || ''} */}
          {/*  variant='outlined' */}
          {/*  onBlur={handleBlur} */}
          {/*  onChange={handleChange} */}
          {/*  helperText={touched.password && errors.password} */}
          {/*  error={Boolean(touched.password && errors.password)} */}
          {/* /> */}

          {/* <TextField */}
          {/*  autoComplete='off' */}
          {/*  type='password' */}
          {/*  margin='normal' */}
          {/*  label={intl.formatMessage({ id: 'USER.EDIT.PASSWORD.REPEAT' })} */}
          {/*  name='password2' */}
          {/*  value={values.password2 || ''} */}
          {/*  variant='outlined' */}
          {/*  onBlur={handleBlur} */}
          {/*  onChange={handleChange} */}
          {/*  helperText={touched.password2 && errors.password2} */}
          {/*  error={Boolean(touched.password2 && errors.password2)} */}
          {/* /> */}

          <TextField
            select
            margin='normal'
            label={intl.formatMessage({ id: 'USER.EDIT.ROLE' })}
            value={values.role || ''}
            onChange={handleChange}
            name='role'
            variant='outlined'
            helperText={touched.role && errors.role}
            error={Boolean(touched.role && errors.role)}
            disabled
          >
            <MenuItem value=''>{intl.formatMessage({ id: 'COMMON.NOT_SELECTED' })}</MenuItem>
            <MenuItem value='ROLE_ADMIN'>
              {intl.formatMessage({ id: 'USER.ROLES.ADMIN' })}
            </MenuItem>
            <MenuItem value='ROLE_VENDOR'>
              {intl.formatMessage({ id: 'USER.ROLES.VENDOR' })}
            </MenuItem>
            <MenuItem value='ROLE_MANAGER'>
              {intl.formatMessage({ id: 'USER.ROLES.MANAGER' })}
            </MenuItem>
            <MenuItem value='ROLE_BUYER'>
              {intl.formatMessage({ id: 'USER.ROLES.BUYER' })}
            </MenuItem>
            <MenuItem value='ROLE_VENDOR_STAFF'>
              {intl.formatMessage({ id: 'USER.ROLES.VENDOR.STAFF' })}
            </MenuItem>
          </TextField>

          <TextField
            type='text'
            label={intl.formatMessage({ id: 'USER.EDIT.LAST_NAME' })}
            margin='normal'
            name='last_name'
            value={values.last_name || ''}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.last_name && errors.last_name}
            error={Boolean(touched.last_name && errors.last_name)}
          />

          <TextField
            type='text'
            label={intl.formatMessage({ id: 'USER.EDIT.FIRST_NAME' })}
            margin='normal'
            name='first_name'
            value={values.first_name || ''}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.first_name && errors.first_name}
            error={Boolean(touched.first_name && errors.first_name)}
          />

          <TextField
            type='text'
            label={intl.formatMessage({ id: 'USER.EDIT.MIDDLE_NAME' })}
            margin='normal'
            name='middle_name'
            value={values.middle_name || ''}
            variant='outlined'
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.middle_name && errors.middle_name}
            error={Boolean(touched.middle_name && errors.middle_name)}
          />

          {userRoleVendor && (
            <>
              <TextField
                type='text'
                label={fm('COMPANY.FORM.LEGAL_PERSON')}
                margin='normal'
                name='companyName'
                value={values.companyName}
                variant='outlined'
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.companyName && errors.companyName}
                error={Boolean(touched.companyName && errors.companyName)}
              />
              <TextField
                type='text'
                label={fm('ICO')}
                margin='normal'
                name='ico'
                value={values.ico}
                variant='outlined'
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.ico && errors.ico}
                error={Boolean(touched.ico && errors.ico)}
              />
              <TextField
                type='text'
                label={fm('TIN')}
                margin='normal'
                name='tin'
                value={values.tin}
                variant='outlined'
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.tin && errors.tin}
                error={Boolean(touched.tin && errors.tin)}
              />
              <TextField
                type='text'
                label={fm('VAT')}
                margin='normal'
                name='vat'
                value={values.vat}
                variant='outlined'
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.vat && errors.vat}
                error={Boolean(touched.vat && errors.vat)}
              />
              <TextField
                type='text'
                label={fm('ACCOUNT.NUMBER')}
                margin='normal'
                name='accountNumber'
                value={values.accountNumber}
                variant='outlined'
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.accountNumber && errors.accountNumber}
                error={Boolean(touched.accountNumber && errors.accountNumber)}
              />
              <TextField
                type='text'
                label={fm('PAYMENT.DETAILS')}
                margin='normal'
                name='paymentDetails'
                value={values.paymentDetails}
                variant='outlined'
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched.paymentDetails && errors.paymentDetails}
                error={Boolean(touched.paymentDetails && errors.paymentDetails)}
              />
            </>
          )}

          <TextField
            select
            margin='normal'
            label={intl.formatMessage({ id: 'AUTH.INPUT.LANG' })}
            value={values.locale || ''}
            onChange={handleChange}
            name='locale'
            variant='outlined'
          >
            {languages.map(lang => (
              <MenuItem key={lang.lang} value={lang.lang}>
                <div className={classes.lang}>
                  <span className={classes.langImage}>
                    <img src={lang.flag} alt={lang.name} />
                  </span>
                  <span className={classes.langTitle}>{lang.name}</span>
                </div>
              </MenuItem>
            ))}
          </TextField>

          <div className={classes.actions}>
            <ButtonWithLoader disabled={editLoading} loading={editLoading}>
              {intl.formatMessage({ id: 'COMMON.BUTTON.SAVE' })}
            </ButtonWithLoader>
          </div>
        </form>
      )}
    </Formik>
  );
};

const connector = connect(
  (state: IAppState) => ({
    editSuccess: state.profile.editSuccess,
    editLoading: state.profile.editLoading,
    editError: state.profile.editError,
  }),
  {
    clearEdit: profileActions.clearEdit,
    edit: profileActions.editRequest,
    setLanguage: i18nActions.setLanguage,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProfileForm);
