import axios from 'axios';
import {
  CREATE_CATEGORY_URL,
  ROOT_CATEGORIES_URL,
  FULL_CATEGORIES_URL,
  getCategoriesWithChildrenUrl,
  getCategoriesWithParentUrl,
  getCategoryUrl,
  getCategoriesPositionsUrl,
} from '../constants';
import { ICategoryItem } from '../pages/home/categories/interfaces';
import { IPaginationProps } from '../interfaces/server';

export function getRootCategories({
  page = 1,
  per_page = 20,
  parentId,
  text,
}: IPaginationProps & { parentId?: number; text?: string }) {
  if (parentId && !text) {
    return axios.get(
      `${getCategoriesWithChildrenUrl(parentId)}?page=${page}&per_page=${per_page}`
    );
  }
  if (text) {
    return axios.get('/api/shop/categories/search', { params: { text } });
  }
  return axios.get(`${ROOT_CATEGORIES_URL}?page=${page}&per_page=${per_page}`);
}

// export function getFullCategories() {
//   return axios.get(FULL_CATEGORIES_URL);
// }
export function getFullCategories({
  with_products,
  company_id,
}: {
  with_products?: boolean;
  company_id?: number;
}) {
  if (with_products) {
    return axios.get(`${FULL_CATEGORIES_URL}?with_products=1&company_id=${company_id}`);
  }
  return axios.get(FULL_CATEGORIES_URL);
}

export function getCategoriesWithParent({ id = 0 }) {
  return axios.get(`${getCategoriesWithParentUrl(id)}`);
}

export function getCategoryById(id: number) {
  return axios.get(`${getCategoryUrl(id)}`);
}

export function createCategory(data: ICategoryItem) {
  return axios.post(CREATE_CATEGORY_URL, data);
}

export function editCategory(id: number, data: ICategoryItem) {
  return axios.put(`${getCategoryUrl(id)}`, data);
}

export function deleteCategory(id: number | undefined) {
  return axios.delete(`${getCategoryUrl(id)}`);
}

export function setCategoriesPositions(positions: string) {
  return axios.post(getCategoriesPositionsUrl(positions));
}

export function addCategoryAvatar(id: number, photo: FormData) {
  return axios.post(`/api/shop/category/${id}/avatar`, photo);
}
