import { IntlShape } from 'react-intl';

import { IUser, UserRoles, UserRoleType } from '../../../../../interfaces/user';
import {
  UserListType,
  BUYER_ROLE,
  MANAGER_ROLE,
  VENDOR_ROLE,
  VENDOR_STAFF_ROLE,
  CONTENT_MANAGER_ROLE,
  ADMIN_ROLE,
} from '../../constants';

export const getRoleName = (roles: [UserRoleType], intl?: IntlShape): string => {
  if (roles.includes(UserRoles.ROLE_ADMIN)) {
    return intl ? intl.formatMessage({ id: 'USER.ROLES.ADMIN' }) : UserRoles.ROLE_ADMIN;
  }
  if (roles.includes(UserRoles.ROLE_MANAGER)) {
    return intl ? intl.formatMessage({ id: 'USER.ROLES.MANAGER' }) : UserRoles.ROLE_MANAGER;
  }
  if (roles.includes(UserRoles.ROLE_VENDOR)) {
    return intl ? intl.formatMessage({ id: 'USER.ROLES.VENDOR' }) : UserRoles.ROLE_VENDOR;
  }
  if (roles.includes(UserRoles.ROLE_VENDOR_STAFF)) {
    return intl
      ? intl.formatMessage({ id: 'USER.ROLES.VENDOR.STAFF' })
      : UserRoles.ROLE_VENDOR_STAFF;
  }
  return intl ? intl.formatMessage({ id: 'USER.ROLES.BUYER' }) : UserRoles.ROLE_USER;
};

export const getRole = (type: string) => {
  switch (type) {
    case UserListType.BUYER:
      return BUYER_ROLE;
    case UserListType.MANAGER:
      return `${MANAGER_ROLE},${CONTENT_MANAGER_ROLE},${ADMIN_ROLE}` as 'ROLE_MANAGER';
    case UserListType.VENDOR:
      return VENDOR_ROLE;
    case UserListType.STAFF:
      return VENDOR_STAFF_ROLE;
    case UserListType.CONTENT_MANAGER:
      return CONTENT_MANAGER_ROLE;
    case UserListType.ADMIN:
      return ADMIN_ROLE;
    default:
      return BUYER_ROLE;
  }
};

export const getRoleBreadcrumbName = (type: string) => {
  switch (type) {
    case UserListType.BUYER:
      return 'SUBMENU.CUSTOMERS.LIST';
    case UserListType.MANAGER:
      return 'SUBMENU.TEAM';
    case UserListType.VENDOR:
      return 'MENU.USERS.LIST';
    case UserListType.STAFF:
      return 'SUBMENU.VENDORS.STAFF';
    case UserListType.CONTENT_MANAGER:
      return 'SUBMENU.CONTENT_MANAGER';
    case UserListType.ADMIN:
      return 'LIST.ADMINS';
    default:
      return 'SUBMENU.CUSTOMERS.LIST';
  }
};

export const getFio = (user: IUser, returnEmail?: boolean) => {
  let ret = '';
  if (user.last_name) {
    ret += `${user.last_name}`;
  }
  if (user.first_name) {
    ret += ` ${user.first_name}`;
  }
  if (user.middle_name) {
    ret += ` ${user.middle_name}`;
  }
  if (ret === '') {
    if (returnEmail) {
      return user.email ? `  ${user.email}` : '';
    }
    return '-';
  }
  return ret;
};
