export function googleDataToEntities(data: any) {
  try {
    return data.results.map((i: any) => ({
      address: i.formatted_address,
      lng: i.geometry.location.lng,
      lat: i.geometry.location.lat,
    }));
  } catch (e) {
    return [];
  }
}
