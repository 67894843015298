import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { TextField, Button, Grid } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

import { ButtonWithLoader } from '../../components/other/Buttons';

import { actions as authActions } from '../../store/ducks/auth.duck';
import { IAppState } from '../../store/rootDuck';

const NewPassword: React.FC<TPropsFromRedux> = ({
  clearNewPassword,
  newPasswordRequest,
  newPasswordLoading,
  newPasswordSuccess,
  newPasswordErrors,
}) => {
  const { code } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  useEffect(() => {
    if (newPasswordSuccess || newPasswordErrors) {
      enqueueSnackbar(
        newPasswordSuccess
          ? intl.formatMessage({ id: 'AUTH.PASSWORD.SUCCESS' })
          : `${intl.formatMessage({ id: 'ERROR' })} ${newPasswordErrors}`,
        { variant: newPasswordSuccess ? 'success' : 'error' }
      );
    }
    if (newPasswordSuccess) {
      navigate('/auth');
    }
    return () => {
      clearNewPassword();
    };
  }, [
    newPasswordSuccess,
    newPasswordErrors,
    enqueueSnackbar,
    clearNewPassword,
    navigate,
    intl,
  ]);

  return (
    <div className='kt-login__body'>
      <div className='kt-login__form'>
        <div className='kt-login__title'>
          <h3>{intl.formatMessage({ id: 'AUTH.PASSWORD.NEW_TITLE' })}</h3>
        </div>

        <Formik
          initialValues={{ password: '', password2: '' }}
          validationSchema={Yup.object().shape({
            password: Yup.string().required(intl.formatMessage({ id: 'AUTH.PASSWORD.INPUT' })),
            password2: Yup.string()
              .required(intl.formatMessage({ id: 'AUTH.PASSWORD.REPEAT' }))
              .oneOf(
                [Yup.ref('password'), ''],
                intl.formatMessage({ id: 'AUTH.PASSWORD.EQUAL' })
              ),
          })}
          onSubmit={values => {
            newPasswordRequest({
              password: values.password,
              password2: values.password2,
              code: code || '',
            });
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit} className='kt-form'>
              <div role='alert' className='alert alert-info'>
                <div className='alert-text'>
                  {intl.formatMessage({ id: 'AUTH.PASSWORD.INPUT_TITLE' })}
                </div>
              </div>

              <div className='form-group'>
                <TextField
                  autoComplete='off'
                  type='password'
                  margin='normal'
                  label={intl.formatMessage({ id: 'AUTH.FORM.PASSWORD' })}
                  className='kt-width-full'
                  name='password'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
              </div>

              <div className='form-group'>
                <TextField
                  autoComplete='off'
                  type='password'
                  margin='normal'
                  label={intl.formatMessage({ id: 'AUTH.FORM.PASSWORD_REPEAT' })}
                  className='kt-width-full'
                  name='password2'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password2}
                  helperText={touched.password2 && errors.password2}
                  error={Boolean(touched.password2 && errors.password2)}
                />
              </div>

              <div className='kt-login__actions'>
                <Grid container direction='row' justify='space-between' alignItems='stretch'>
                  <Button onClick={() => navigate('/auth')} variant='outlined' color='primary'>
                    {intl.formatMessage({ id: 'COMMON.BUTTON.BACK' })}
                  </Button>

                  <ButtonWithLoader disabled={newPasswordLoading} loading={newPasswordLoading}>
                    {intl.formatMessage({ id: 'AUTH.PASSWORD.BUTTON' })}
                  </ButtonWithLoader>
                </Grid>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

const connector = connect(
  (state: IAppState) => ({
    newPasswordLoading: state.auth.newPasswordLoading,
    newPasswordSuccess: state.auth.newPasswordSuccess,
    newPasswordErrors: state.auth.newPasswordErrors,
  }),
  {
    clearNewPassword: authActions.clearNewPassword,
    newPasswordRequest: authActions.newPasswordRequest,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(NewPassword);
